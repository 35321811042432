import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { createStyles, withStyles } from '@material-ui/core'
import { muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import * as api from '../../../infrastructure/api'
import guid, { Guid } from '../../../infrastructure/guid'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'
import { hasFeature } from '../../../infrastructure/feature'
import { ColumnDescriptor, DatePicker, NumberField, Select, TableItem } from '../../common/customComponents'
import { MasterDataItem, MasterDataShell, createExcelLines } from './masterDataShell'
import { ExcelGeneratorContainer } from '../../../infrastructure/excelExport'

type SecurityStock = {
    id: Guid
    productGroupId: string | null
    productId: string | null
    company: string
    start: Date
    end: Date
    volume: number
}

type Product = {
    id: string,
    code: string,
}

type ProductGroup = {
    id: string | null,
    name: string,
    productIds: string[]
}

type Company = {
    id: string
    code: string
    name: string
}

let emptySecurityStock = () => {
    return {
        id: guid.empty,
        productGroupId: null,
        productId: null,
        company: null,
        start: null,
        end: null,
        volume: null
    }
}

type SecurityStockFilters = {
    productGroupName: string | null,
    productCode: string | null,
    companyCode: string | null,
    start: string | null,
    end: string | null,
    volume: string | null
}

let noFilters: SecurityStockFilters = {
    productGroupName: null,
    productCode: null,
    companyCode: null,
    start: null,
    end: null,
    volume: null
}


let toTableItem = (securityStock: SecurityStock): TableItem<MasterDataItem<SecurityStock>> => {
    return {
        id: securityStock.id,
        productGroupId: securityStock.productGroupId,
        productId: securityStock.productId,
        company: securityStock.company,
        start: securityStock.start,
        end: securityStock.end,
        volume: securityStock.volume,
        isModified: false
    }
}

let applyFilters = (securityStocks: SecurityStock[], filters: SecurityStockFilters, productGroups: ProductGroup[], products: Product[]): SecurityStock[] => {
    if (filters.productGroupName)
        securityStocks = securityStocks.filter(x => productGroups.find(pg => pg.id === x.productGroupId)?.name?.toLowerCase().contains(filters.productGroupName!.toLowerCase()))

    if (filters.companyCode)
        securityStocks = securityStocks.filter(x => x.company.toLowerCase().contains(filters.companyCode!.toLowerCase()))

    if (filters.productCode)
        securityStocks = securityStocks.filter(x => products.find(p => p.id === x.productId)?.code?.toLowerCase().contains(filters.productCode!.toLowerCase()))

    if (filters.volume)
        securityStocks = securityStocks.filter(x => x.volume?.toString()?.contains(filters.volume!))

    if (filters.start)
        securityStocks = securityStocks.filter(x => moment(x.start).format('MM/DD/YYYY').contains(filters.start!))

    if (filters.end)
        securityStocks = securityStocks.filter(x => moment(x.end).format('MM/DD/YYYY').contains(filters.end!))

    return securityStocks
}

function SecurityStockMasterData({ classes }: MuiProps) {
    let [productGroups, setProductGroups] = useState<ProductGroup[]>([])
    let [products, setProducts] = useState<Product[]>([])
    let [companys, setCompanys] = useState<Company[]>([])
    let [securityStocks, setSecurityStock] = useState<SecurityStock[]>([])
    let [filters, setFilters] = useState<SecurityStockFilters>(noFilters)
    let excelGenerator = ExcelGeneratorContainer.useContainer()

    let load = async () => {
        let companys = await api.get<Company[]>('masterdata/securitystock/company')
        let securityStocks = await api.get<SecurityStock[]>('masterdata/securitystock')

        if (hasFeature('SecurityStockByProductGroup')) {
            let productGroups = await api.get<ProductGroup[]>('masterdata/securitystock/productgroup')
            setProductGroups(productGroups)
        } else {
            let products = await api.get<Product[]>('masterdata/securitystock/product')
            setProducts(products)
        }

        setCompanys(companys)
        setSecurityStock(securityStocks)
    }

    useEffect(() => {
        let effect = async () => {
            await load()
        }
        effect()
    }, [])

    let getItems = () => applyFilters(securityStocks, filters, productGroups, products).map(toTableItem)

    let onExportExcel = async () => {
        excelGenerator.generate({
            filename: 'SecurityStock.xlsx',
            sheets: [{ name: 'SecurityStock', lines: createExcelLines(getItems(), columns) }]
        })
    }

    let onSave = async (item: SecurityStock) => {
        await api.post('masterdata/securitystock', item)
        await load()
        return true
    }

    let onDelete = async (ids: string[]) => {
        await api.del('masterdata/securitystock', { ids: ids })
        await load()
        return true
    }

    let columns: ColumnDescriptor<TableItem<MasterDataItem<SecurityStock>>>[] = [
        hasFeature('SecurityStockByProductGroup')
            ? {
                name: t('admin.masterdata.securityStock.productGroupName'), value: x => productGroups.find(pg => pg.id === x.productGroupId)?.name ?? '',
                columnFilter: { value: filters.productGroupName ?? '', onChange: (productGroupName: string) => setFilters({ ...filters, productGroupName }) }
            } : {
                name: t('admin.masterdata.securityStock.productCode'), value: x => products.find(p => p.id === x.productId)?.code ?? '',
                columnFilter: { value: filters.productCode ?? '', onChange: (productCode: string) => setFilters({ ...filters, productCode }) }
            },
        {
            name: t('admin.masterdata.securityStock.companyCode'), value: x => companys.find(c => c.code === x.company)?.name ?? '',
            columnFilter: { value: filters.companyCode ?? '', onChange: (companyCode: string) => setFilters({ ...filters, companyCode }) }
        },
        {
            name: t('admin.masterdata.securityStock.startDate'), value: x => moment(x.start).format('MM/DD/YYYY'),
            columnFilter: { value: filters.start ?? '', onChange: (start: string) => setFilters({ ...filters, start }) }
        },
        {
            name: t('admin.masterdata.securityStock.endDate'), value: x => moment(x.end).format('MM/DD/YYYY'),
            columnFilter: { value: filters.end ?? '', onChange: (end: string) => setFilters({ ...filters, end }) }
        },
        {
            name: t('admin.masterdata.securityStock.volume'), value: x => x.volume.toString(),
            columnFilter: { value: filters.volume ?? '', onChange: (volume: string) => setFilters({ ...filters, volume }) }
        }
    ]

    let isManager = hasClaim(Claims.MasterdataSecurityStockLevel)

    return (
        <div className={classes.container}>
            <MasterDataShell
                headerLabel={t('admin.masterdata.securityStock.securityStocks')}
                itemLabel={t('admin.masterdata.securityStock.securityStock')}
                isManager={isManager}
                onExportExcel={onExportExcel}
                onNew={emptySecurityStock}
                onDelete={onDelete}
                onSave={onSave}
                items={getItems()}
                columns={columns}>{
                    (selectedItem, setSelectedItem) => (
                        <>
                            {hasFeature('SecurityStockByProductGroup') ?
                                <Select label={t('admin.masterdata.securityStock.productGroups')}
                                    disabled={!isManager}
                                    value={selectedItem.productGroupId}
                                    choices={productGroups.map(x => ({ value: x.id, text: x.name }))}
                                    onChange={val => { if (val) setSelectedItem({ ...selectedItem, productGroupId: val }) }} /> :
                                <Select label={t('admin.masterdata.securityStock.product')}
                                    disabled={!isManager}
                                    value={selectedItem.productId}
                                    choices={products.map(x => ({ value: x.id, text: x.code }))}
                                    onChange={val => { if (val) setSelectedItem({ ...selectedItem, productId: val }) }} />
                            }

                            <Select label={t('admin.masterdata.securityStock.company')}
                                disabled={!isManager}
                                value={selectedItem.company}
                                choices={companys.map(x => ({ value: x.code, text: x.name }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, company: val }) }} />

                            <DatePicker date={selectedItem.start}
                                label={t('admin.masterdata.securityStock.startDate')} disabled={!isManager}
                                setDate={newDate => setSelectedItem({ ...selectedItem, start: newDate })} />

                            <DatePicker date={selectedItem.end} label={t('admin.masterdata.securityStock.endDate')}
                                disabled={!isManager}
                                setDate={newDate => setSelectedItem({ ...selectedItem, end: newDate })} />

                            <NumberField label={t('admin.masterdata.securityStock.volume')}
                                disabled={!isManager}
                                text={selectedItem.volume}
                                onChange={x => setSelectedItem({ ...selectedItem, volume: x })} />
                        </>
                    )}
            </MasterDataShell>
        </div>)
}

let styles = (theme) =>
    createStyles({
        container: { height: '100%' },
    })

export default withStyles(styles, muiOptions)(SecurityStockMasterData)