import React from 'react'
import { createStyles, FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText, OutlinedInput, InputAdornment } from '@material-ui/core'
import { createMuiTheme } from '@material-ui/core/styles'
import { muiOptions, defaultColors, MuiProps } from '../../../../infrastructure/materialUiThemeProvider'
import { ThemeProvider, withStyles } from '@material-ui/styles'
import { t } from '../../../../infrastructure/i18nextHelper'
import { MultipleSelect as _MultipleSelect } from './multiSelect'
import { GroupedMultiSelect as _GroupedMultiSelect } from './groupedSelect'
import { BaseFieldProps } from '../../fieldProps'
import { fieldStatuses } from '../../styles'
import { formatChoices } from './utils'

export let customSelectTheme = createMuiTheme({
    palette: {},
    overrides: {
        //@ts-ignore
        MuiPickerToolbar: {
            toolbar: {
                backgroundColor: defaultColors.darkBlue.main.color,
            },
        },
        MuiInputLabel: {
            outlined: {
                backgroundColor: 'white',
                paddingLeft: '5px',
                paddingRight: '5px'
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                "& .Mui-disabled": {
                    backgroundColor: 'white',
                }
            }
        },
        root: {
            width: '75%',
        },
    }
})

export type SelectStyleProps = {
    input?
    form?
    select?
}

type SelectProps = {
    choices: { value: any, text: string }[] | string[]
    label?: string
    value: any
    isClearable?: boolean
    clearableLabel?: string
    onErrorMessage?: string
    classesOverride?: SelectStyleProps
    auto?: boolean
    hideArrow?: boolean
    hideUnderline?: boolean
    disableNewStyle?: boolean
    hideOnSingleChoice?: boolean
    startAdornment?: JSX.Element
    endAdornment?: JSX.Element
    onChange?: (value: string) => void
} & BaseFieldProps

function _Select(props: SelectProps & MuiProps) {
    let { choices, value, classes } = props

    let strValue = value?.toString()
    let choicesItems = formatChoices(choices)
    let selectedItem = value != null && choicesItems.find(x => x.value === strValue) ? strValue : ''

    let isReadOnly = props.disabled || props.choices.length <= 1
    let classNames = parseClassNames({ ...props })

    React.useEffect(forceUniqueChoice, [choicesItems])

    function forceUniqueChoice() {
        if (choicesItems.length === 1 && (value == null || value === '') && choicesItems[0].value != null && props.onChange)
            props.onChange(choicesItems[0].value)
    }

    let variant = !props.disableNewStyle ? { variant: 'filled' } as any : {}

    return (
        <ThemeProvider theme={customSelectTheme}>
            <FormControl {...variant}
                className={`${classNames.form}`} error={props.status === 'alert' || !!props.onError}>
                {props.label ? <InputLabel shrink >{props.label}</InputLabel> : null}
                <MuiSelect
                    id={props.id}
                    value={selectedItem}
                    className={classNames.select}
                    label={props.label ? props.label : undefined}
                    disableUnderline={!!props.hideUnderline || isReadOnly}
                    MenuProps={{ className: classes.noDrag }}
                    disabled={isReadOnly || choicesItems.length <= 1}
                    startAdornment={props.startAdornment
                        ? <InputAdornment position="start" className={classes.noHide}>{props.startAdornment}</InputAdornment>
                        : undefined}
                    endAdornment={props.endAdornment
                        ? <InputAdornment position="end" className={classes.noHide}>{props.endAdornment}</InputAdornment>
                        : undefined}
                    onChange={e => { if (props.onChange) props.onChange(e.target.value as string) }}>
                    {props.isClearable ?
                        <MenuItem className={classes.menuItem} key={'empty'} value={undefined}>
                            <em>{props.clearableLabel ? props.clearableLabel : t('components.allFiltersLine')}</em>
                        </MenuItem> : null}
                    {choicesItems.map((x, i) =>
                        <MenuItem className={classes.menuItem} key={i} value={x.value}>{x.text}</MenuItem>
                    )}
                </MuiSelect>
                {props.status === 'alert' || props.onError
                    ? <FormHelperText>{props.onErrorMessage || t('components.error')}</FormHelperText>
                    : props.auto
                        ? <FormHelperText>{t('components.autoSet')}</FormHelperText>
                        : null}
            </FormControl>
        </ThemeProvider >
    )
}

export function parseClassNames(props: CustomSelectStyleProps & BaseFieldProps & { choices: any[] }): ClassNames {
    let readonly = props.disabled || props.choices.length <= 1 ? props.classes.hideArrow + ' ' + props.classes.readOnlySelect : ''
    let hideArrow = props.choices.length <= 1 || props.hideArrow ? ' ' + props.classes.hideArrow : ''

    let form = props.classesOverride?.form ?? props.classes.defaultForm ?? ''
    let hidden = props.hideOnSingleChoice && props.choices.length <= 1 ? props.classes.hidden : ''
    let highlighted = props.status === 'info' ? props.classes.fieldStatusInfo
        : props.status === 'warning' ? props.classes.fieldStatusWarning : ''

    return {
        form: `${form} ${hidden} ${highlighted}`,
        select: `${props.classesOverride?.select ?? props.classes.defaultSelect ?? ''} ${readonly} ${hideArrow} ${props.classes.base}`,
        input: props.classesOverride?.input ?? props.classes.defaultInput ?? '',
    }
}

export type ClassNames = {
    form: string
    select: string
    input: string
}

export type CustomSelectStyleProps = {
    classesOverride?: SelectStyleProps
    disabled?: boolean
    hideArrow?: boolean
    hideUnderline?: boolean
    hideOnSingleChoice?: boolean
    classes?: any
}

let styles = _ =>
    createStyles({
        base: {
            minWidth: '8.3em',
            "& .MuiFilledInput-root": {
                background: 'rgba(0, 0, 0, 0.05)'
            }
        },
        defaultForm: {
            width: '15.3em',
            margin: '0em 0.5em'
        },
        noDrag: {
            '-webkit-app-region': 'no-drag',
        },
        hideArrow: {
            '& svg': {
                visibility: 'hidden'
            },
        },
        noHide: {
            '& svg': {
                visibility: 'initial'
            },
        },
        selectGroup: {
            fontWeight: 'bold',
            color: defaultColors.darkBlue.light.color
        },
        selected: {
            backgroundColor: '#EBEBEB !important'
        },
        menuItem: {
            height: '3em'
        },
        hidden: {
            display: 'none'
        },
        readOnlySelect: {
            '& div': {
                color: 'black'
            },
        },
        chipsContainer: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        ...fieldStatuses
    })

export let Select = withStyles(styles, muiOptions)(_Select)
export let MultipleSelect = withStyles(styles, muiOptions)(_MultipleSelect)
export let GroupedMultiSelect = withStyles(styles, muiOptions)(_GroupedMultiSelect)