import { useState, useEffect, useRef, useImperativeHandle, RefObject } from 'react'
import { createContainer } from 'unstated-next'
import * as api from '../../../infrastructure/api'
import { hasScopeOnClaim, hasDealClaim } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'
import guid, { Guid } from '../../../infrastructure/guid'
import {
    Vessel, VesselUser, Counterparty, Jetty, VesselProduct, Quotation,
    CompanyName, ProductCodes, Site, Stock, PaymentTerm,
    SitePurchaseMovement, ComplementaryVesselFields, PricingNeed, PurchaseMovement
} from '../vesselModels'
import { CostingInformation, RegulatedPricePeriod } from '../vesselCostingInformationModels'
import { hasFeature } from '../../../infrastructure/feature'
import { snackbars } from '../../../infrastructure/snackbars'
import { Deal } from '../../deals/dealModels'
import { t } from '../../../infrastructure/i18nextHelper'

type OpenCloseDialogRef = { open: (id?: Guid, productId?: Guid) => void, close: () => void }
let dialogRef: RefObject<OpenCloseDialogRef> | null = null

export let vesselDialog = {
    open: (id?, productId?) => dialogRef?.current?.open(id, productId),
    close: () => dialogRef?.current?.close(),
}

function useVesselEdit(initialState) {
    let dialog = VesselEditDialogContainer.useContainer()
    let [state, setState] = useState<Vessel>(initialState)
    let [canEdit, setCanEdit] = useState<boolean>(false)
    let [vesselUsers, setVesselUsers] = useState<(VesselUser | null)[]>([])
    let [counterpartys, setCounterpartys] = useState<Counterparty[]>([])
    let [sites, setSites] = useState<Site[]>([])
    let [companys, setCompanys] = useState<CompanyName>({})
    let [paymentTerms, setPaymentTerms] = useState<PaymentTerm[]>([])
    let [products, setProducts] = useState<ProductCodes>({})
    let [jettys, setJettys] = useState<Jetty[]>([])
    let [quotations, setQuotations] = useState<Quotation[]>([])
    let [purchaseMovementsByVesselProductId, setPurchaseMovementsByVesselProductId] = useState<{ [productId: string]: PurchaseMovement[] }>({})
    let [costingInformationByVesselProductId, setCostingInformationByVesselProductId] = useState<{ [productId: string]: CostingInformation }>({})
    let [vesselFieldsCatalog, setVesselFieldsCatalog] = useState<string[]>([])
    let [movementStock, setMovementStock] = useState<Stock[]>([])
    let [activeProductId, setActiveProductId] = useState<Guid | null>(null)
    let [pricingNeeds, setPricingNeeds] = useState<{ [sitePurchaseMovementId: string]: number | null }>({})
    let [fixedPriceByVesselProductId, setFixedPriceByVesselProductId] = useState<{ [purchaseMovementId: string]: number | null }>({})
    let [regulatedPricePeriods, setRegulatedPricePeriods] = useState<RegulatedPricePeriod[]>([])
    let [transporters, setTransporters] = useState<{ [company: string]: Counterparty[] }>({})
    let [openAssignDealPopup, setOpenAssignDealPopup] = useState<boolean>(false)
    let [assignableDealsFromMovement, setAssignableDealsFromMovement] = useState<Deal[] | null>(null)
    let [dealIdToAssign, setDealIdToAssign] = useState<Guid | null>(null)
    let [isConfirmDealOpen, setIsConfirmDealOpen] = useState<boolean>(false)
    let [sitePurchaseMovementWithAssignedDeal, setSitePurchaseMovementWithAssignedDeal] = useState<SitePurchaseMovementWithAssignedDealInfo | null>(null)

    type SitePurchaseMovementWithAssignedDealInfo = {
        purchaseMovementId: Guid
        sitePurchaseMovementId: Guid
        productId: Guid
    }

    useEffect(() => {
        if (state?.jettyCode && state?.completionOfDischargeDate)
            loadRegulatedPricePeriods(state.jettyCode, state.completionOfDischargeDate)
    }, [state?.completionOfDischargeDate])

    useEffect(() => {
        if (state?.jettyCode && !jettys.find(x => x.code == state.jettyCode)) {
            setJettys([{ code: state.jettyCode, defaultSiteCode: '', country: '' }])
        }
    }, [state?.id])

    useEffect(() => {
        updateSites(state?.jettyCode)
        updateCounterpartys(state?.jettyCode)

        if (!state?.jettyCode) return

        loadUsers(state.jettyCode)
        loadVesselFieldCatalog(state.jettyCode)
        loadProducts(state.jettyCode)
    }, [state?.jettyCode])

    let updateSites = async (jettyCode: string | null) => {
        if (!jettyCode) {
            setSites([])
            setMovementStock([])
        }
        else {
            let sites = api.get<Site[]>(`vessel/site?jettyCode=${jettyCode}`)
            let stock = api.get<Stock[]>(`vessel/jetty/${jettyCode}/stock`)
            setSites(await sites)
            setMovementStock(await stock)
        }
    }

    let updatePurchaseMovementSites = async (sites: Site[], stocks: Stock[]) => {
        if (sites.length === 0 || stocks.length === 0) return;

        let updatePurchaseMovementSiteIfPossible = (oldSiteCode: string, productId: string | undefined, dutyStatus: string): string => {
            if (!productId) return oldSiteCode

            let checkStockExist = (siteCode: string): boolean => {
                return stocks.some(stock => stock.site == siteCode && stock.productId === productId && stock.dutyStatus == dutyStatus)
            }

            if (!checkStockExist(oldSiteCode)) {
                let defaultSiteCode = jettys.filter(jetty => jetty.code === state.jettyCode).first().defaultSiteCode
                if (checkStockExist(defaultSiteCode)) {
                    return defaultSiteCode
                }

                let siteWithStock = sites.find(site => checkStockExist(site.code))
                if (!!siteWithStock) {
                    return siteWithStock.code
                }
            }
            return oldSiteCode
        }

        let getProductIdFromVesselProductId = (vesselProductId: string): string | undefined => {
            return state.products.first(vesselProduct => vesselProduct.id === vesselProductId)?.productId
        }

        let newPurchaseMovementsByVesselProductId: { [vesselProductId: string]: PurchaseMovement[] } =
            Object.entries(purchaseMovementsByVesselProductId)
                .reduce((acc, [vesselProductId, purchaseMvts]) => {
                    purchaseMvts.forEach(purchaseMvt => purchaseMvt.sites
                        .forEach(site => site.siteCode =
                            updatePurchaseMovementSiteIfPossible(site.siteCode, getProductIdFromVesselProductId(vesselProductId), purchaseMvt.dutyStatus)))
                    acc[vesselProductId] = purchaseMvts
                    return acc
                }, {}
                )

        setPurchaseMovementsByVesselProductId(newPurchaseMovementsByVesselProductId)
    }

    let updateCounterpartys = async (jettyCode: string | null) => {
        if (!jettyCode) setCounterpartys([])
        else {
            let counterpartys = await api.get<Counterparty[]>(`vessel/counterparty/${jettyCode}`)
            setCounterpartys(counterpartys.filter(x => !x.isInternal))
        }
    }

    let changeProduct = (product: VesselProduct) => {
        if (state === null) return
        state.products[state.products.map(x => x.id).indexOf(product.id)] = { ...product }
        setState({ ...state, products: [...state.products] })
    }

    let setPurchaseMovement = (vesselProductId: Guid, purchaseMovement: PurchaseMovement) =>
        setPurchaseMovements(vesselProductId, [purchaseMovement])

    let setPurchaseMovements = (vesselProductId: Guid, purchaseMovements: PurchaseMovement[]) =>
        setPurchaseMovementsProducts([[vesselProductId, purchaseMovements]])

    let setPurchaseMovementsProducts = (purchaseMovementsProducts: [Guid, PurchaseMovement[]][]) => {
        let newPurchaseMovementsByVesselProductId = purchaseMovementsByVesselProductId
        for (let purchaseMovementsProduct of purchaseMovementsProducts) {
            let productId = purchaseMovementsProduct[0]
            let purchaseMovements = purchaseMovementsProduct[1]
            let existing = newPurchaseMovementsByVesselProductId[productId] ?? []
            let newArray = existing.filter(x => !purchaseMovements.find(y => y.id === x.id)).concat(purchaseMovements)
            newPurchaseMovementsByVesselProductId = { ...newPurchaseMovementsByVesselProductId, [productId]: newArray }
        }
        setPurchaseMovementsByVesselProductId(newPurchaseMovementsByVesselProductId)
    }

    let updateFixedPrice = (vesselProductId: Guid, newFixedPrice: number | null) => {
        let productPurchaseMovement: PurchaseMovement[] = purchaseMovementsByVesselProductId[vesselProductId]

        for (let purchaseMovement of productPurchaseMovement) {
            if (purchaseMovement.type != "FixedPrice") continue

            for (let site of purchaseMovement.sites) {
                site.fixedPrice = newFixedPrice
            }
        }
        setFixedPriceByVesselProductId({ ...fixedPriceByVesselProductId, [vesselProductId]: newFixedPrice })
        setPurchaseMovements(vesselProductId, [...productPurchaseMovement])
    }

    let applyFixedPrice = (vesselProductId: Guid, site: SitePurchaseMovement): SitePurchaseMovement => {
        return { ...site, fixedPrice: fixedPriceByVesselProductId[vesselProductId] }
    }

    let initFixedPrice = (vesselProducts: VesselProduct[]) => {
        let fixedPrices = {}
        for (let vesselProduct of vesselProducts) {
            let fixedPrice = vesselProduct.purchaseMovements.find(x => x.type === "FixedPrice")?.sites[0].fixedPrice ?? null
            fixedPrices[vesselProduct.id] = fixedPrice
        }
        setFixedPriceByVesselProductId(fixedPrices)
    }

    let removePurchaseMovement = (vesselProductId: Guid, purchaseMovementId: Guid) => {
        let existing = purchaseMovementsByVesselProductId
        if (!existing) return
        existing[vesselProductId] = [...existing[vesselProductId].filter(x => x.id != purchaseMovementId)]
        setPurchaseMovementsByVesselProductId(existing)
    }

    let removeSitePurchaseMovement = (sitePurchaseMovementId: Guid, purchaseMovementId: Guid, vesselProductId: Guid) => {
        let existing = purchaseMovementsByVesselProductId
        if (!existing || !existing[vesselProductId]) return
        let purchaseMovement = existing[vesselProductId].find(x => x.id == purchaseMovementId)
        if (!purchaseMovement) return

        if (purchaseMovement.sites.length == 1 && purchaseMovement.sites[0].id == sitePurchaseMovementId)
            removePurchaseMovement(vesselProductId, purchaseMovementId)
        else {
            purchaseMovement.sites = purchaseMovement.sites.filter(x => x.id != sitePurchaseMovementId)
            existing[vesselProductId] = existing[vesselProductId].filter(x => x.id != purchaseMovementId)
            existing[vesselProductId].push(purchaseMovement)
            setPurchaseMovementsByVesselProductId(existing)
        }
    }

    let setCostingInformation = (productId: Guid, costingInformation: CostingInformation) => {
        setCostingInformationByVesselProductId({ ...costingInformationByVesselProductId, [productId]: costingInformation })
    }

    let updateCostingInformations = (vesselProducts: VesselProduct[]) => {
        let costingInfos = costingInformationByVesselProductId
        for (let i = 0; i < vesselProducts.length; i++) {
            costingInfos[vesselProducts[i].id] = vesselProducts[i].costingInformation
        }
        setCostingInformationByVesselProductId({ ...costingInfos })
    }

    let setChoices = (
        vesselUsers: (VesselUser | null)[],
        paymentTerm: PaymentTerm[],
        companys: CompanyName) => {
        setVesselUsers([[null], vesselUsers].flat())
        setPaymentTerms(paymentTerm)
        setCompanys(companys)
    }

    let updateVesselProducts = (vesselProducts: VesselProduct[]) => {
        setPurchaseMovementsProducts(vesselProducts.map(p => [p.id, p.purchaseMovements]))
        state?.products.push.apply(state?.products, vesselProducts)
        if (state && state?.products.length > 1)
            state?.products.sort((a, b) => (products[a.productId] > products[b.productId]) ? 1 : -1)
        initFixedPrice(state?.products)
    }

    let isVisible = (company: string, dutyStatus: string) =>
        hasScopeOnClaim(Claims.Companies, company) || hasScopeOnClaim(Claims.VesselPurchaseDelegation, `${company}/${dutyStatus}`)

    let load = async (id: Guid, productId?: Guid) => {
        let vessel = await api.get<Vessel>(`vessel/${id}`)
        setState(vessel)

        vessel.products.forEach(setCanDeleteProduct)
        setUrl(id, productId)
        await loadTransporter(vessel.products.flatMap(x => x.purchaseMovements).map(x => x.companyCode).distinct())
        if (vessel)
            setPurchaseMovementsProducts(vessel.products.map(p => [p.id, p.purchaseMovements]))
        updateCostingInformations(vessel.products)
        setCanEdit(vessel.canEdit)
        loadVesselFieldCatalog(vessel.jettyCode)
        loadProducts(vessel.jettyCode)
        loadPricingNeeds(vessel)
        initFixedPrice(vessel.products)
        await loadRegulatedPricePeriods(vessel.jettyCode, vessel.completionOfDischargeDate)
        await updateSites(vessel?.jettyCode)
        await updateCounterpartys(vessel?.jettyCode)
    }

    let setCanDeleteProduct = (product: VesselProduct) => {
        product.canRemove = !product.purchaseMovements
            .flatMap(x => x.sites.flatMap(x => [x.volume, x.quantity]).flatMap(x => x))
            .some(x => x ?? 0 > 0)
    }

    let setUrl = (id: Guid, productId?: Guid) => {
        if (id) {
            let urlState = '?openVessel=' + id
            if (productId) {
                urlState += '&product=' + productId
                setActiveProductId(productId)
            }
            else if (activeProductId) {
                urlState += '&product=' + activeProductId
            }
            window.history.pushState({}, '', urlState)
        }
    }

    let loadProducts = async (jetty: string | null) => {
        if (!jetty) return
        let products = await api.get<{ id: string, code: string }[]>(`vessel/product/${jetty}`)
        let productCodes = products.indexValueBy(x => x.id, x => x.code)
        setProducts(productCodes)
    }

    let loadPricingNeeds = async (vessel: Vessel) => {
        let allSitePurchaseMovementIds = vessel.products
            .flatMap(x => x.purchaseMovements)
            .flatMap(x => x.sites)
            .map(x => x.id);

        let allVesselPricingNeedPromises = allSitePurchaseMovementIds.map(x => api.get<PricingNeed>(`vessel/pricingNeed/${x}`))

        Promise.all(allVesselPricingNeedPromises)
            .then(values => setPricingNeeds(
                values.reduce((acc, curr) => {
                    acc[curr.sitePurchaseMovementId] = curr.value
                    return acc
                }, {})))
    }

    let loadRegulatedPricePeriods = async (jetty: string | null, completionOfDischargeDate: string | null) => {
        if (!jetty || !completionOfDischargeDate) return
        let regulatedPricePeriods = await api.get<RegulatedPricePeriod[]>(`vessel/regulatedPricePeriods/${jetty}`, { date: completionOfDischargeDate })
        setRegulatedPricePeriods(regulatedPricePeriods)
    }

    let loadVesselFieldCatalog = async (jetty: string | null) => {
        if (!jetty) return
        let fields = await api.get<string[]>(`vessel/fields/${jetty}`)

        let firstLetterToLower = (str: string): string => str.charAt(0).toLocaleLowerCase() + str.slice(1)

        let formatedFields = fields.map(x => {
            let splittedField = x.split('.')
            return splittedField.length === 2 ? firstLetterToLower(splittedField[1]) : firstLetterToLower(splittedField[0])
        })

        setVesselFieldsCatalog(formatedFields)
    }

    let loadUsers = async (jettyCode: string) => {
        let vesselUsers = await api.get<VesselUser[]>('vessel/users', { jetty: jettyCode })
        setVesselUsers([[null], vesselUsers].flat())
    }

    let loadTransporter = async (companys: string[]) => {
        let newTransporters = { ...transporters }

        for (let company of companys) {
            let existing = newTransporters[company]
            if (!existing || !existing.length)
                newTransporters[company] = await api.get<Counterparty[]>(`vessel/${company}/counterparty`)
        }

        setTransporters(newTransporters)
    }

    let isAuthorized = (field: keyof Vessel | keyof VesselProduct | keyof CostingInformation | keyof SitePurchaseMovement | ComplementaryVesselFields) =>
        vesselFieldsCatalog.some(x => x === field)

    let changeJetty = async (jettyCode: string) => {
        setState({ ...state, jettyCode: jettyCode })
        if (hasFeature('JettyChangeSiteImpactsUi')) {
            let sites = api.get<Site[]>(`vessel/site?jettyCode=${jettyCode}`)
            let stock = api.get<Stock[]>(`vessel/jetty/${jettyCode}/stock`)
            await updatePurchaseMovementSites(await sites, await stock)
        }
    }

    let reload = () => state && load(state.id)

    let trySave = async () => {
        let newVessel = state
        if (!newVessel) return;

        Object.values(purchaseMovementsByVesselProductId)
            .forEach(x => x.forEach(y => {
                if (y.sites)
                    y.sites = y.sites.filter(f => !!f.siteCode)
            }))

        newVessel.products.forEach(x => {
            x.purchaseMovements = purchaseMovementsByVesselProductId[x.id]
            x.costingInformation = costingInformationByVesselProductId[x.id]
        })

        newVessel.canEdit = false

        try {
            await api.post('vessel', newVessel)
        } catch (err) {
            if (err.status !== 409) throw err
        }
        reload()
    }

    let open = async (id?: Guid | null, productId?: Guid) => {
        await init()
        setState(initialState)
        dialog.setIsOpen(true)
        id = id ?? guid.createNew() as Guid
        load(id, productId)
    }

    let close = () => {
        dialog.setIsOpen(false)
        setCanEdit(false)
        setState(initialState)
        setVesselFieldsCatalog([])
        window.history.pushState({}, document.title, window.location.pathname)
        setActiveProductId(null)
        setOpenAssignDealPopup(false)
        setDealIdToAssign(null)
        setIsConfirmDealOpen(false)
    }

    let init = async () => {
        setJettys(await api.get<Jetty[]>('vessel/jetty'))
        let companysPromise = api.get<{ code: string, name: string }[]>('vessel/company')
        let paymentTerms = api.get<PaymentTerm[]>('vessel/paymentTerm')
        let users = state?.jettyCode ? await api.get<VesselUser[]>('vessel/users', { jetty: state?.jettyCode }) : []
        let companyCodes = (await companysPromise).indexValueBy(x => x.code, x => x.name)

        setChoices(users, await paymentTerms, companyCodes)
    }

    let deletePurchaseMovement = async (purchaseMovementId: Guid, productId: Guid) => {
        await api.post('vessel/purchaseMovement/delete', { vesselId: state.id, purchaseMovementId: purchaseMovementId })
        removePurchaseMovement(productId, purchaseMovementId)
    }

    let deleteSitePurchaseMovement = async (sitePurchaseMovementId: Guid, purchaseMovementId: Guid, productId: Guid) => {
        let purchaseMovement = purchaseMovementsByVesselProductId[productId].find(x => x.id == purchaseMovementId)
        if (purchaseMovement == null) return;
        if (purchaseMovement.sites.length == 1 && purchaseMovement.sites[0].id == sitePurchaseMovementId)
            await deletePurchaseMovement(purchaseMovementId, productId)
        else {
            await api.post('vessel/purchaseMovement/site/delete', { vesselId: state.id, sitePurchaseMovementId: sitePurchaseMovementId })
            removeSitePurchaseMovement(sitePurchaseMovementId, purchaseMovementId, productId)
        }
    }

    let updateSitePurchaseMovementWithAssignedDeal = async (sitePurchaseMovementId: Guid, purchaseMovementId: Guid, productId: Guid) => {
        let purchaseMovement = purchaseMovementsByVesselProductId[productId].find(x => x.id == purchaseMovementId)
        if (purchaseMovement == null) return
        purchaseMovement.sites.forEach(site => {
            if (site.id === sitePurchaseMovementId) {
                site.dealId = dealIdToAssign
            }
        });
    }

    let removeProduct = (product: VesselProduct) => setState({ ...state, products: state.products.filter(x => x !== product) })

    let openAssignableDeals = async (movementId: string) => {
        let assignableDeals = hasDealClaim()
            ? await api.get<Deal[]>(`stock/movement/${movementId}/deal/assignable`)
            : []

        if (assignableDeals.length === 0)
            snackbars.info(t('stock.label.movement.noAssignableDealFound'))
        else {
            setAssignableDealsFromMovement(assignableDeals)
            setOpenAssignDealPopup(true)
        }
    }

    let assignDealFromMovement = async () => {
        let { sitePurchaseMovementId, purchaseMovementId, productId } = sitePurchaseMovementWithAssignedDeal ?? {}
        await updateSitePurchaseMovementWithAssignedDeal(sitePurchaseMovementId!, purchaseMovementId!, productId!)
        let purchaseMovement = purchaseMovementsByVesselProductId[productId!].find(x => x.id == purchaseMovementId)
        if (purchaseMovement == null) return
        let site = purchaseMovement.sites.find(x => x.id === sitePurchaseMovementId)
        if (!site) return
        let movementId = site.movementId
        await api.post('deal/linkMovement', { dealId: dealIdToAssign, movementId: movementId })
        await trySave().then(_ => snackbars.success(t('httpSuccess.dealAssigned')))
    }

    let updateQuotations = async (productId: string) => {
        let quotationsPromise = api.get<Quotation[]>(`vessel/quotation/${state?.jettyCode}/${productId}`)
        setQuotations(await quotationsPromise);
    }

    dialogRef = useRef<OpenCloseDialogRef>(null)
    useImperativeHandle(dialogRef, () => ({ open, close }))

    return {
        state, canEdit, reload, setState, changeProduct, vesselUsers, counterpartys, sites, jettys, quotations,
        companys, paymentTerms, products, purchaseMovementsByVesselProductId,
        setPurchaseMovement, setPurchaseMovements, setPurchaseMovementsByVesselProductId, updateVesselProducts,
        costingInformationByVesselProductId, setCostingInformation, updateCostingInformations,
        movementStock, deletePurchaseMovement, deleteSitePurchaseMovement, updateFixedPrice, applyFixedPrice,
        fixedPriceByVesselProductId, activeProductId, setActiveProductId, isAuthorized, pricingNeeds,
        isVisible, regulatedPricePeriods, removeProduct, transporters, loadTransporter, changeJetty,
        openAssignableDeals, openAssignDealPopup, assignableDealsFromMovement, setOpenAssignDealPopup,
        dealIdToAssign, setDealIdToAssign, assignDealFromMovement, isConfirmDealOpen, setIsConfirmDealOpen,
        trySave, updateSitePurchaseMovementWithAssignedDeal, sitePurchaseMovementWithAssignedDeal,
        setSitePurchaseMovementWithAssignedDeal, setQuotations, updateQuotations
    }
}

function useVesselEditDialog() {
    let [isOpen, setIsOpen] = useState<boolean>(false)
    return { isOpen, setIsOpen }
}

export let VesselEditContainer = createContainer(useVesselEdit)
export let VesselEditDialogContainer = createContainer(useVesselEditDialog)