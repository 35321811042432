import { DealListItem, Deal, FieldState } from "./dealModels";
import { ExcelCell, ExcelLine } from '../../infrastructure/excelExport'
import { t } from '../../infrastructure/i18nextHelper'
import { Pricing, PricingField } from "../pricings/pricing";

export let exportDealList = (dealPricingItems: DealPricing[], dealItems: DealListItem[], generateExcel: any) => {
    if (!dealPricingItems.length) return

    let headerLine = getHeaderLine(dealPricingItems)
    let generalFields = getGeneralDealFields(dealPricingItems, dealItems)
    let pricingFields = getPricingFields(dealPricingItems)

    let productLine = getProductLine(dealPricingItems, dealItems)
    let generalLines = getGeneralLines(dealPricingItems, generalFields, dealItems)
    let pricingLines = getPricingLines(dealPricingItems, pricingFields)
    let allLines = [headerLine, productLine, ...generalLines, ...pricingLines]

    generateExcel({
        filename: 'Deals.xlsx',
        sheets: [{ name: 'Deals', lines: allLines }]
    })
}

let getHeaderLine = (dealPricingItems: DealPricing[]): ExcelLine => {
    let headerColumns: string[] = []

    let dealsColumns: ExcelCell[] = dealPricingItems.map((x, i) => {

        let columnName = x.deal.referenceNumber!

        if (headerColumns.indexOf(columnName) > -1) {
            let counter = 0
            let tmpColumnName = columnName

            while (headerColumns.indexOf(tmpColumnName) !== -1) {
                counter++
                tmpColumnName = `${columnName} (${counter})`
            }
            columnName = `${columnName} (${counter})`
        }

        headerColumns.push(columnName)

        return {
            type: 'string',
            value: columnName
        }
    })

    return { cells: [{ type: 'string', value: "Labels" }, ...dealsColumns] }
}


const excludedFieldsList = ['costs', 'movements', 'quotationPeriod', 'assignee', 'fixedPrice']

let getGeneralDealFields = (dealPricingItems: DealPricing[], dealItems: DealListItem[]) => {
    var dealItemsFields = dealItems.flatMap(x => Object.keys(x))
    let allDealFields = dealPricingItems.map(x => x.deal).flatMap(x => Object.keys(x))
        .concat(dealItemsFields)
        .filter(x => !excludedFieldsList.includes(x))
    let allDealFieldsWithoutDuplicates = toDistinctArray(allDealFields)
    return excludeNotWantedFields(allDealFieldsWithoutDuplicates)
}

let getPricingFields = (dealPricingItems: DealPricing[]) => {
    let orderedFields = dealPricingItems
        .flatMap(x => x.pricing)
        .flatMap(x => x?.fields)
        .sort(pricingFieldComparer)
    return toDistinctArray(orderedFields.map(x => x ? x.name : ''))
}

let pricingFieldComparer = (a: PricingField, b: PricingField) => {
    if (!a && b) return -1;
    if (a && !b) return 1;
    if (!a && !b) return 0;
    return a.order > b.order ? 1 : (b.order > a.order ? -1 : 0)
}

let getDealTrad = (prefix: string, code: string) => {
    switch (code) {
        case "sapVendorCode": return t('deals.label.sap.vendor')
        case "destinationPlant": return t('deals.label.sap.destinationPlant')
        case "destinationStorageLocation": return t('deals.label.sap.destinationStorageLocation')
        case "totalCosts": return t('deals.label.totalCost')
        case "totalCostsUnit": return `${t('deals.label.totalCost')} unit`
        default: return getTrad(prefix, code)
    }
}

let getTrad = (prefix: string, code: string) => {
    let translationCode = `${prefix}.${code}`
    let translationValue = t(translationCode)
    return translationValue === translationCode ? null : translationValue
}

let excludeNotWantedFields = (allFields: string[]): string[] =>
    allFields.filter(x =>
        x !== 'version' &&
        !x.toLowerCase().includes('id') &&
        !x.toLowerCase().includes('overwritten'))

let getDealValueCell = (key: string, deal: Deal, dealItem: DealListItem): ExcelCell => {
    let value = (dealItem && dealItem[key])
        ? dealItem[key].toString()
        : (deal[key] ? deal[key].toString() : '')
    if (key == 'fields') {
        let fieldsValues = deal[key] as FieldState[];
        value = fieldsValues.map(item => `${item.code}: ${item.price ?? ''}`).join(',')
    }
    return { type: getCellType(key, value), value: value }
}

let getCellType = (key: string, value: string): 'string' | 'number' | 'date' => {
    if (key.toLowerCase().includes('date')) return 'date'
    else if (typeof (value) === 'number') return 'number'
    else return 'string'
}

let getProductLine = (dealPricingItems: DealPricing[], dealItems: DealListItem[]): ExcelLine => {
    return {
        cells: [
            { type: 'string', value: 'Product' },
            ...dealPricingItems.map(deal => {
                let dealItem = dealItems.find(dealItem => dealItem.id === deal.deal.id)
                return { type: 'string', value: dealItem?.productCode ?? '' } as ExcelCell
            })]
    }
}

let getGeneralLines = (dealPricingItems: DealPricing[], allDealFields: string[], dealItems: DealListItem[]): ExcelLine[] =>
    allDealFields.map(field => ({
        cells: [
            { type: 'string', value: getDealTrad('deals.label', field) ?? field },
            ...dealPricingItems.map(deal => getDealValueCell(field, deal.deal, dealItems.find(x => x.id === deal.deal.id)!))
        ]
    }))

let getPricingLines = (dealPricingItems: DealPricing[], allPricingFields: string[]): ExcelLine[] =>
    allPricingFields.map(field => (field === '' ? { cells: [] } : {
        cells: [
            { type: 'string', value: getTrad('pricing.field', field) ?? field },
            ...dealPricingItems.map(dealPricingItem => getPricingCell(dealPricingItem, field))
        ]
    }))

let getPricingCell = (dealPricingItems: DealPricing, field: string): ExcelCell => {
    return { type: 'number', value: dealPricingItems.pricing?.fields.find(x => x.name === field)?.value?.toString() ?? '' }
}

let toDistinctArray = (values: string[]) =>
    [...new Set(values)]

export type DealPricing = {
    deal: Deal
    pricing?: Pricing
}
