import React, { useEffect } from 'react'
import { withStyles, createStyles, Paper } from '@material-ui/core'
import { muiOptions, MuiProps } from '../../infrastructure/materialUiThemeProvider'
import { t } from '../../infrastructure/i18nextHelper'
import * as api from '../../infrastructure/api'
import { DatePicker, MultipleSelect } from '../common/customComponents'
import { VesselContainer } from './vesselStore'
import { Jetty } from './vesselModels'

function _VesselFilters({ classes }: MuiProps) {
    let store = VesselContainer.useContainer()

    let loadFiltersElements = async () => {
        let jetties = await api.get<Jetty[]>('vessel/jetty')
        let allProducts = await Promise.all(jetties.map(x =>
            api.get<{ id: string, code: string, unit: string }[]>(`vessel/product/${x.code}`)))
        let products = allProducts.flatMap(x => x).distinctBy((a, b) => a.id == b.id)
        store.setJetties(jetties)
        store.setProducts(products)
    }

    useEffect(() => { loadFiltersElements() }, [])

    return (
        <Paper className={classes.paperFilter}>
            <DatePicker date={store.filters.start || null} label={t('vessels.table.startDate')} disableNewStyle
                setDate={newDate => store.changeDate(newDate, 'start')} classesOverride={{ datepicker: classes.filterFieldDate }}
                onError={store.filterDatesOnError} />

            <DatePicker date={store.filters.end || null} label={t('vessels.table.endDate')} disableNewStyle
                setDate={newDate => store.changeDate(newDate, 'end')} classesOverride={{ datepicker: classes.filterFieldDate }}
                onError={store.filterDatesOnError} />

            <MultipleSelect label={t('vessels.table.jetty')} classesOverride={{ form: classes.filterField }}
                values={store.filters.jetty} disableNewStyle
                choices={store.jetties.map(x => ({ value: x.code, text: x.code }))}
                onChange={x => store.setFilters({ ...store.filters, jetty: x })} />

            <MultipleSelect label={t('deals.label.product')} classesOverride={{ form: classes.filterField }}
                values={store.filters.productId} disableNewStyle
                choices={store.products.map(x => ({ value: x.id, text: x.code }))}
                onChange={x => store.setFilters({ ...store.filters, productId: x })} />
        </Paper>
    )
}

let styles = theme =>
    createStyles({
        paperFilter: {
            height: 'auto',
            padding: '0.5em',
        },
        filterField: {
            width: '8.3em',
            margin: '0em 0.5em'
        },
        filterFieldDate: {
            width: '10.3em',
            margin: '0em 0.5em'
        }
    })

export let VesselFilters = withStyles(styles, muiOptions)(_VesselFilters)