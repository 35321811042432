import React, { useState, useEffect, useRef, Fragment } from 'react'
import { withStyles, createStyles, Paper, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@material-ui/core'
import { muiOptions, defaultColors, defaultStyles, MuiProps, customStyledComponent } from '../../infrastructure/materialUiThemeProvider'
import { t } from '../../infrastructure/i18nextHelper'
import { Button, DatePicker } from '../common/customComponents'
import { SupplyBalance, SupplyBalancePeriod } from './supplyBalanceModels'
import { NumberField } from '../common/customComponents'
import { SupplyBalanceTableData } from './supplyBalanceModels'
import { SupplyBalanceStore } from './supplyBalanceStore'

let alignRightCellStyle = {
    textAlign: 'right',
    padding: '6px 8px',
}

let groupCellStyle = {
    textAlign: 'right',
    padding: '6px 8px',
    backgroundColor: '#f2f3f4',
    fontWeight: 'bold'
}

let totalCellStyle = {
    backgroundColor: '#fafafa',
    fontWeight: 'bold',
    textAlign: 'right',
    padding: '6px 8px'
}

let AlignedRightCell = customStyledComponent(TableCell)(alignRightCellStyle, muiOptions)
let GroupCell = customStyledComponent(TableCell)(groupCellStyle, muiOptions)
let TotalCell = customStyledComponent(TableCell)(totalCellStyle, muiOptions)

type SupplyBalanceTableProps = {
    supplyBalanceTable: SupplyBalanceTableData
    supplyBalanceTableIndex: number
    disabledStockTarget: boolean
    disabledStockTargetDays: boolean
    setDisabledInput: (period: SupplyBalancePeriod, isStockTarget: boolean) => void
    setSupplyNeed: (period: SupplyBalancePeriod, index: number, balanceLine: SupplyBalance, value: number, isStockTarget: boolean) => void
    onChangeDate: (period: SupplyBalancePeriod, field: 'start' | 'end', value: string | null) => void
    onAddPeriod?: () => void
} & MuiProps

let useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
      isMountRef.current = false;
    }, []);
    return isMountRef.current;
}

function _SupplyBalanceTable({ supplyBalanceTable, disabledStockTarget, disabledStockTargetDays, classes, ...props }: SupplyBalanceTableProps) {
    let store = SupplyBalanceStore.useContainer()
    let isMount = useIsMount();
    let [displayTable, setDisplayTable] = useState<boolean>(false)
    let [groupedBalances, setGroupeBalances] = useState<{ [key: string]: SupplyBalance[] }>({})

    useEffect(() => {
        let start = supplyBalanceTable.period.start
        let end = supplyBalanceTable.period.end
        setDisplayTable(start !== null && end !== null)
    }, [supplyBalanceTable.period.start, supplyBalanceTable.period.end])

    useEffect(() => {
        let balancesGroupedBySite = groupBySite(supplyBalanceTable.supplyBalanceResult ? supplyBalanceTable.supplyBalanceResult.balances : [])
        setGroupeBalances(balancesGroupedBySite)
    }, [supplyBalanceTable.supplyBalanceResult.balances])

    useEffect(() => {
        if(!isMount) {
            convertStockTarget(supplyBalanceTable.period)
        }
    }, [store.kUnite])

    useEffect(() => {
        if(!isMount){
            substractDeadStockFromStockTarget(supplyBalanceTable.period)
        }
    }, [store.filters.usefulStock])

    let groupBySite = (balances: SupplyBalance[]) => {
        let result = balances.reduce<{ [key: string]: SupplyBalance[] }>((bs, b) => ({ ...bs, [b.site]: [...(bs[b.site] || []), b] }), {})
        Object.keys(result).map(key => result[key].sort((a, b) => a.productOrder - b.productOrder))
        return result
    }

    let sortBySite = (v: { [key: string]: SupplyBalance[] }) => {
        let keys = Object.keys(v)
        keys.sort((a, b) => a.localeCompare(b))
        return keys
    }

    let onchangeStockTarget = (value: number, site: string, product: string, isStockTarget: boolean) => {
        let [balanceLine, index] = createBalanceInputValue(site, product)
        if (!balanceLine) return

        props.setSupplyNeed(supplyBalanceTable.period, index, balanceLine, value, isStockTarget)
        props.setDisabledInput(supplyBalanceTable.period, isStockTarget)
    }

    let createBalanceInputValue = (site: string, product: string): [SupplyBalance | null, number] => {
        let index = supplyBalanceTable.supplyBalanceResult?.balances.findIndex(x => x.productCode === product && x.site == site)
        if (typeof index == 'undefined' || index < 0) return [null, -1]
        if (!supplyBalanceTable.supplyBalanceResult?.balances || !supplyBalanceTable.supplyBalanceResult?.balances[index]) return [null, index]
        return [supplyBalanceTable.supplyBalanceResult?.balances[index], index]
    }

    let convertValue = (value: number | null) => {
        if (value === null || isNaN(value)) return ""

        return Math.round(store.kUnite ? value / 1000 : value)
    }

    let convertStockTarget = (period: SupplyBalancePeriod) => {
        let supplyBalance = store.supplyBalanceFromPeriod(period)
        if (!supplyBalance) return

        let balances = supplyBalance.supplyBalanceResult?.balances
        if (!balances) return

        balances.forEach(balance => {
            if (balance.need.stockTarget) {
                let newNeed = balance.need
                newNeed.stockTarget = store.kUnite ? newNeed!.stockTarget! / 1000 : newNeed!.stockTarget! * 1000
            }
        })
        store.changeSupplyBalancesResults(supplyBalance.period, balances)
    }

    let substractDeadStockFromStockTarget = (period: SupplyBalancePeriod) => {
        let supplyBalance = store.supplyBalanceFromPeriod(period)
        if (!supplyBalance) return

        let balances = supplyBalance.supplyBalanceResult?.balances
        if (!balances) return

        balances.forEach(balance => {
            if (balance.need.stockTarget !== null) {
                let newNeed = balance.need
                newNeed.stockTarget = store.filters.usefulStock ? newNeed!.stockTarget! - newNeed.deadStock! : newNeed!.stockTarget! + newNeed.deadStock!
            }
        })
        store.changeSupplyBalancesResults(supplyBalance.period, balances)
    }

    return (
        <>
            <Paper className={classes.tablePaper}>
                <div className={classes.dateFilters}>
                    {props.onAddPeriod && <Button label={t('supplyBalance.table.addPeriod')}
                        onClick={props.onAddPeriod} className={classes.secondaryButton} />}
                    <DatePicker date={supplyBalanceTable.period.start} label={t('supplyBalance.filters.startDate')}
                        setDate={newDate => props.onChangeDate(supplyBalanceTable.period, 'start', newDate,)} disableNewStyle
                        classesOverride={{ datepicker: classes.filterFieldDate }} disabled={props.supplyBalanceTableIndex !== 0 ? true : false} />
                    <DatePicker date={supplyBalanceTable.period.end} label={t('supplyBalance.filters.endDate')}
                        setDate={newDate => props.onChangeDate(supplyBalanceTable.period, 'end', newDate)} disableNewStyle
                        classesOverride={{ datepicker: classes.filterFieldDate }} />
                </div>
                {displayTable &&
                    <Table className={classes.supplyBalanceTableContainer} stickyHeader aria-label='sticky table'>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}></TableCell>
                                <TableCell className={classes.alignRightCell + " " + classes.separator}>{t('supplyBalance.table.demand')}</TableCell>
                                {store.demandDetails && <>
                                    <AlignedRightCell>{t('supplyBalance.table.sales')}</AlignedRightCell>
                                    <AlignedRightCell>{t('supplyBalance.table.transit')}</AlignedRightCell>
                                    <AlignedRightCell>{t('supplyBalance.table.rebranding')}</AlignedRightCell>
                                    <AlignedRightCell>{t('supplyBalance.table.statusChange')}</AlignedRightCell>
                                    <AlignedRightCell>{t('supplyBalance.table.loan')}</AlignedRightCell>
                                    <AlignedRightCell>{t('supplyBalance.table.losses')}</AlignedRightCell> </>}
                                <AlignedRightCell>{t('supplyBalance.table.sellingDays')}</AlignedRightCell>
                                <AlignedRightCell>{t('supplyBalance.table.averageDemand')}</AlignedRightCell>
                                <TableCell className={classes.alignRightCell + " " + classes.separator}>{t('supplyBalance.table.resources')}</TableCell>
                                {store.ressourcesDetails && <>
                                    <AlignedRightCell>{t('supplyBalance.table.' + (store.filters.usefulStock ? 'stockProjOpeningUseful' : 'stockProjOpening'))}</AlignedRightCell>
                                    <AlignedRightCell>{t('supplyBalance.table.purchaseVessel')}</AlignedRightCell>
                                    <AlignedRightCell>{t('supplyBalance.table.purchaseSpot')}</AlignedRightCell>
                                    <AlignedRightCell>{t('supplyBalance.table.transit')}</AlignedRightCell>
                                    <AlignedRightCell>{t('supplyBalance.table.rebranding')}</AlignedRightCell>
                                    <AlignedRightCell>{t('supplyBalance.table.statusChange')}</AlignedRightCell>
                                    <AlignedRightCell>{t('supplyBalance.table.borrow')}</AlignedRightCell>
                                    <AlignedRightCell>{t('supplyBalance.table.gains')}</AlignedRightCell>
                                </>}
                                <AlignedRightCell>{t('supplyBalance.table.' + (store.filters.usefulStock ? 'stockTargetClosingUseful' : 'stockTargetClosing'))}</AlignedRightCell>
                                <AlignedRightCell>{t('supplyBalance.table.stockTargetDays')}</AlignedRightCell>
                                <TableCell className={classes.alignRightCell + " " + classes.separator}>{t('supplyBalance.table.supplyNeeds')}</TableCell>
                                <AlignedRightCell>{t('supplyBalance.table.deadStock')}</AlignedRightCell>
                                <AlignedRightCell>{t('supplyBalance.table.low')}</AlignedRightCell>
                                <AlignedRightCell>{t('supplyBalance.table.high')}</AlignedRightCell>
                                <AlignedRightCell>{t('supplyBalance.table.maxStock')}</AlignedRightCell>
                                <AlignedRightCell>{t('supplyBalance.table.ullageStart')}</AlignedRightCell>
                                <AlignedRightCell>{t('supplyBalance.table.ullageEnd')}</AlignedRightCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                supplyBalanceTable.supplyBalanceResult.balances.filter(item => item.isSubTotal).map((group: SupplyBalance) => {
                                    return (<Fragment key={group.siteGroup}>{
                                        sortBySite(groupedBalances).map((site, j) =>
                                            groupedBalances[site].map((balance, i) =>
                                                (balance.siteGroup === group.siteGroup && !balance.isSubTotal) &&
                                                <TableRow key={group.siteGroup + '_' + j.toString() + "_" + i.toString()}>
                                                    {i == 0
                                                        ? <TableCell rowSpan={groupedBalances[site].length} className={classes.alignCenterCell + ' ' + classes.productSiteCell}>
                                                            <Typography variant="subtitle1" display="block" style={{ fontWeight: 'bold' }}>{site}</Typography>
                                                        </TableCell>
                                                        : null}
                                                    <TableCell className={classes.alignCenterCell + ' ' + classes.productSiteCell}>
                                                        <Typography variant="subtitle1" display="block" style={{ fontWeight: 'bold' }}>{balance.productCode}</Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.demandeCell + " " + classes.separator} >{convertValue(store.demandTotal(balance.demand))}</TableCell>
                                                    {store.demandDetails &&
                                                        <><AlignedRightCell>{convertValue(balance.demand.sales)}</AlignedRightCell>
                                                            <AlignedRightCell>{convertValue(balance.demand.transit)}</AlignedRightCell>
                                                            <AlignedRightCell>{convertValue(balance.demand.rebranding)}</AlignedRightCell>
                                                            <AlignedRightCell>{convertValue(balance.demand.statusChange)}</AlignedRightCell>
                                                            <AlignedRightCell>{convertValue(balance.demand.loan)}</AlignedRightCell>
                                                            <AlignedRightCell>{convertValue(balance.demand.losses)}</AlignedRightCell></>}
                                                    <AlignedRightCell>{balance.need.sellingDays}</AlignedRightCell>
                                                    <AlignedRightCell>{balance.need.averageDemandPerDay?.toFixed()}</AlignedRightCell>
                                                    <TableCell className={classes.resourceCell + " " + classes.separator}>{convertValue(store.resourceTotal(balance.resource))}</TableCell>
                                                    {store.ressourcesDetails &&
                                                        <><AlignedRightCell>{convertValue(balance.resource.stockProjOpening)}</AlignedRightCell>
                                                            <AlignedRightCell>{convertValue(balance.resource.purchaseVessel)}</AlignedRightCell>
                                                            <AlignedRightCell>{convertValue(balance.resource.purchaseSpot)}</AlignedRightCell>
                                                            <AlignedRightCell>{convertValue(balance.resource.transit)}</AlignedRightCell>
                                                            <AlignedRightCell>{convertValue(balance.resource.rebranding)}</AlignedRightCell>
                                                            <AlignedRightCell>{convertValue(balance.resource.statusChange)}</AlignedRightCell>
                                                            <AlignedRightCell>{convertValue(balance.resource.borrow)}</AlignedRightCell>
                                                            <AlignedRightCell>{convertValue(balance.resource.gains)}</AlignedRightCell></>}
                                                    <AlignedRightCell>
                                                        <NumberField
                                                            size='small' allowNegative disableNewStyle disabled={disabledStockTarget}
                                                            onChange={value => { 
                                                                onchangeStockTarget(value, site, balance.productCode, true) 
                                                            }}
                                                            text={balance.need.stockTarget?.toFixed()}
                                                        />
                                                    </AlignedRightCell>
                                                    <AlignedRightCell>
                                                        <NumberField
                                                            size='small' allowNegative disableNewStyle disabled={disabledStockTargetDays}
                                                            onChange={value => { onchangeStockTarget(value, site, balance.productCode, false) }}
                                                            text={balance.need.stockTargetDays?.toFixed()}
                                                        />
                                                    </AlignedRightCell>
                                                    <TableCell className={classes.supplyNeedCell + " " + classes.separator}>{convertValue(balance.need.need)}</TableCell>
                                                    <AlignedRightCell>{convertValue(balance.need.deadStock)}</AlignedRightCell>
                                                    <AlignedRightCell>{convertValue(balance.need.lowVolume)}</AlignedRightCell>
                                                    <AlignedRightCell>{convertValue(balance.need.highVolume)}</AlignedRightCell>
                                                    <AlignedRightCell>{convertValue(balance.need.maxCapacity)}</AlignedRightCell>
                                                    <AlignedRightCell>{convertValue(balance.need.ullageStart)}</AlignedRightCell>
                                                    <AlignedRightCell>{convertValue(balance.need.ullageEnd)}</AlignedRightCell>
                                                </TableRow>
                                            )
                                        )
                                    }
                                        <TableRow key={group.siteGroup}>
                                            <TableCell colSpan={2} className={classes.alignCenterCell + ' ' + classes.productSiteCell + ' ' + classes.subTotalCell}>
                                                <Typography variant="subtitle1" display="block" style={{ fontWeight: 'bold' }}>{" "} {group.siteGroup}</Typography>
                                            </TableCell>
                                            <TableCell className={classes.demandeCell + ' ' + classes.separator + ' ' + classes.subTotalCell}>{convertValue(store.demandTotal(group.demand))}</TableCell>
                                            {store.demandDetails &&
                                                <><GroupCell>{convertValue(group.demand.sales)}</GroupCell>
                                                    <GroupCell>{convertValue(group.demand.transit)}</GroupCell>
                                                    <GroupCell>{convertValue(group.demand.rebranding)}</GroupCell>
                                                    <GroupCell>{convertValue(group.demand.statusChange)}</GroupCell>
                                                    <GroupCell>{convertValue(group.demand.loan)}</GroupCell>
                                                    <GroupCell>{convertValue(group.demand.losses)}</GroupCell></>}
                                            <TableCell className={classes.subTotalCell}></TableCell>
                                            <TableCell className={classes.subTotalCell}></TableCell>
                                            <TableCell className={classes.resourceCell + ' ' + classes.separator + ' ' + classes.subTotalCell}>{convertValue(store.resourceTotal(group.resource))}</TableCell>
                                            {store.ressourcesDetails &&
                                                <><GroupCell>{convertValue(group.resource.stockProjOpening)}</GroupCell>
                                                    <GroupCell>{convertValue(group.resource.purchaseVessel)}</GroupCell>
                                                    <GroupCell>{convertValue(group.resource.purchaseSpot)}</GroupCell>
                                                    <GroupCell>{convertValue(group.resource.transit)}</GroupCell>
                                                    <GroupCell>{convertValue(group.resource.rebranding)}</GroupCell>
                                                    <GroupCell>{convertValue(group.resource.statusChange)}</GroupCell>
                                                    <GroupCell>{convertValue(group.resource.borrow)}</GroupCell>
                                                    <GroupCell>{convertValue(group.resource.gains)}</GroupCell></>}
                                            <TableCell className={classes.alignRightCell + ' ' + classes.subTotalCell}>
                                                {group.need.stockTarget?.toFixed() == '0' ? '' : group.need.stockTarget?.toFixed()}
                                            </TableCell>
                                            <GroupCell></GroupCell>
                                            <TableCell className={classes.alignRightCell + ' ' + classes.separator + ' ' + classes.subTotalCell}>{convertValue(group.need.need)}</TableCell>
                                            <GroupCell colSpan={6}></GroupCell>
                                        </TableRow>
                                    </Fragment>
                                    )
                                })
                            }
                            <TableRow>
                                <TableCell className={classes.totalTitleCell} colSpan={2}>
                                    <Typography variant="subtitle1" display="block" style={{ fontWeight: 'bold' }}>{t('supplyBalance.table.total')}</Typography>
                                </TableCell>
                                <TotalCell>{convertValue(store.demandTotal(supplyBalanceTable.supplyBalanceResult?.total.demand))}</TotalCell>
                                {store.demandDetails &&
                                    <><TotalCell>{convertValue(supplyBalanceTable.supplyBalanceResult?.total.demand.sales)}</TotalCell>
                                        <TotalCell>{convertValue(supplyBalanceTable.supplyBalanceResult?.total.demand.transit)}</TotalCell>
                                        <TotalCell>{convertValue(supplyBalanceTable.supplyBalanceResult?.total.demand.rebranding)}</TotalCell>
                                        <TotalCell>{convertValue(supplyBalanceTable.supplyBalanceResult?.total.demand.statusChange)}</TotalCell>
                                        <TotalCell>{convertValue(supplyBalanceTable.supplyBalanceResult?.total.demand.loan)}</TotalCell>
                                        <TotalCell>{convertValue(supplyBalanceTable.supplyBalanceResult?.total.demand.losses)}</TotalCell></>}
                                <AlignedRightCell></AlignedRightCell>
                                <AlignedRightCell></AlignedRightCell>
                                <TotalCell>{convertValue(store.resourceTotal(supplyBalanceTable.supplyBalanceResult?.total.resource))}</TotalCell>
                                {store.ressourcesDetails &&
                                    <><TotalCell>{convertValue(supplyBalanceTable.supplyBalanceResult?.total.resource.stockProjOpening)}</TotalCell>
                                        <TotalCell>{convertValue(supplyBalanceTable.supplyBalanceResult?.total.resource.purchaseVessel)}</TotalCell>
                                        <TotalCell>{convertValue(supplyBalanceTable.supplyBalanceResult?.total.resource.purchaseSpot)}</TotalCell>
                                        <TotalCell>{convertValue(supplyBalanceTable.supplyBalanceResult?.total.resource.transit)}</TotalCell>
                                        <TotalCell>{convertValue(supplyBalanceTable.supplyBalanceResult?.total.resource.rebranding)}</TotalCell>
                                        <TotalCell>{convertValue(supplyBalanceTable.supplyBalanceResult?.total.resource.statusChange)}</TotalCell>
                                        <TotalCell>{convertValue(supplyBalanceTable.supplyBalanceResult?.total.resource.borrow)}</TotalCell>
                                        <TotalCell>{convertValue(supplyBalanceTable.supplyBalanceResult?.total.resource.gains)}</TotalCell></>}
                                <TotalCell>{convertValue(store.stockTargetSupplyNeedTotal(supplyBalanceTable, 'stockTarget'))}</TotalCell>
                                <TableCell></TableCell>
                                <TotalCell>{convertValue(store.stockTargetSupplyNeedTotal(supplyBalanceTable, 'need'))}</TotalCell>
                                <AlignedRightCell colSpan={6}></AlignedRightCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                }
            </Paper>
        </>
    )
}

let styles = _ =>
    createStyles({
        demandeCell: {
            backgroundColor: defaultColors.focus.main.color,
            textAlign: 'right',
            padding: '6px 8px',
            fontWeight: 'bold',
        },
        resourceCell: {
            backgroundColor: defaultColors.focus.main.color,
            textAlign: 'right',
            padding: '6px 8px',
            fontWeight: 'bold',
        },
        supplyNeedCell: {
            backgroundColor: defaultColors.focus.main.color,
            textAlign: 'right',
            padding: '6px 8px',
            fontWeight: 'bold',
        },
        totalTitleCell: {
            backgroundColor: '#fafafa',
            textAlign: 'center',
            padding: '6px 8px'
        },
        alignRightCell: {
            textAlign: 'right',
            padding: '6px 8px',
        },
        alignCenterCell: {
            textAlign: 'center',
            padding: '6px 8px',
        },
        dateFilters: {
            height: '4em',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: '6px',
            marginBottom: '0.7em',
            '& > *': {
                height: '100%',
                padding: '0em 0.3em',
                display: 'flex',
                alignItems: 'center',
                marginRight: '1em',
                '&:last-child': {
                    marginRight: '0em'
                }
            }
        },
        productSiteCell: { backgroundColor: '#fafafa' },
        filterFieldDate: {
            width: '10.3em',
            margin: '0em 0.5em'
        },
        supplyBalanceTableContainer: {
            marginTop: '0.7em',
        },
        separator: {
            borderLeft: '1px solid #e0e0e0',
            borderRight: '1px solid #e0e0e0'
        },
        secondaryButton: {
            ...defaultStyles.secondaryButton,
            marginTop: '0.5em',
        },
        tablePaper: {
            overflowX: 'scroll',
            marginBottom: '2em'
        },
        subTotalCell: {
            backgroundColor: '#f2f3f4',
            fontWeight: 'bold'
        }
    })

export let SupplyBalanceTable = withStyles(styles, muiOptions)(_SupplyBalanceTable)