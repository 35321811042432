import React, { } from 'react'
import moment from 'moment'
import { Typography, Tooltip, IconButton, InputAdornment } from '@material-ui/core'
import { RestoreOutlined, CreateOutlined, CheckOutlined } from '@material-ui/icons'
import { NumberField } from '../common/customComponents'
import { t } from '../../infrastructure/i18nextHelper'
import { StockProjection, StockSimulation, UntriggeredType } from './stockModels'

export let simulatedProjection = (stock: StockProjection, simulationDates: string[], classes) => {
    let firstDate = simulationDates.sort((a, b) => moment(a).isSameOrAfter(b) ? 1 : -1)[0]
    return moment(stock.date).isSameOrAfter(firstDate)
        ? <span className={classes.simulatedProjected}>{stock.simulatedStock ? Math.round(stock.simulatedStock).toString() : ''}</span>
        : <span>{stock.simulatedStock ? Math.round(stock.simulatedStock).toString() : ''}</span>
}

export let simulatedProjectionDays = (stock: StockProjection, simulationDates: string[], classes) => {
    let firstDate = simulationDates.sort((a, b) => moment(a).isSameOrAfter(b) ? 1 : -1)[0]

    return moment(stock.date).isSameOrAfter(firstDate)
        ? <span className={classes.simulatedProjected}>{stock.simulatedDaysLeft ? Math.round(stock.simulatedDaysLeft).toString() : ''}</span>
        : <span>{stock.simulatedDaysLeft ? Math.round(stock.simulatedDaysLeft).toString() : ''}</span>
}

export type FieldWithSimulationProps = {
    volume?: number | null
    date: string
    simulation?: StockSimulation,
    addSimulation: (simulation: StockSimulation) => void,
    validateSimulation: (simulation?: StockSimulation | null) => void,
    isEditMode: boolean,
    type: string,
}

export let fieldWithSimulation = ({ volume, date, simulation, addSimulation, validateSimulation, isEditMode, type }: FieldWithSimulationProps, classes) => {
    let simulatedVolume = simulation?.value ?? null

    let createSimulatedValue = (newVolume?: number): StockSimulation | null => {
        if (volume == null && newVolume == null) return null
        return {
            date: date,
            value: newVolume != null ? newVolume : volume!,
            movementType: type
        }
    }

    let handleChange = (newValue: number) => {
        let changed = simulation ?? createSimulatedValue(newValue)
        if (changed)
            addSimulation(changed)
    }

    let handleValidation = () => {
        if (!simulation)
            validateSimulation(createSimulatedValue())
        else
            validateSimulation()
    }

    return (
        <div data-tour='stockBoardSimulationField'>
            {isEditMode
                ? <NumberField disableNewStyle
                    label={''}
                    overrideStyle={{ root: classes.simulationField }}
                    onChange={val => handleChange(val)}
                    text={simulatedVolume ?? volume}
                    precision={0}
                    inputProps={{
                        endAdornment:
                            <InputAdornment position={'end'}>
                                <IconButton onClick={() => handleValidation()} size={'small'}>
                                    <CheckOutlined />
                                </IconButton>
                            </InputAdornment>
                    }} />
                : <span>
                    {!!simulatedVolume
                        ? Math.round(simulatedVolume).toString()
                        : !!volume
                            ? Math.round(volume).toString() : ''}
                </span>
            }
        </div>
    )

}

export let fieldSimulated = (stockProjection: StockProjection, simulation: StockSimulation, type: string, classes) => {
    let volume = stockProjection.volumeTypes.find(x => x.type === type)?.volume
    return (
        <Tooltip title={<Typography variant='body1'>{t('stock.label.simulatedVolume', { original: volume ? Math.round(volume) : null })}</Typography>} placement='top'>
            <span className={classes.calibrationField}>{simulation.value ? Math.round(simulation.value).toString() : ''}</span>
        </Tooltip>
    )
}

export let removeSimulationButton = (removeSimulation: () => void) => {
    return (
        <Tooltip title={<Typography variant='body1'>{t('stock.label.removeSimulation')}</Typography>} placement='top'>
            <IconButton onClick={() => removeSimulation()} size={'small'}><RestoreOutlined /></IconButton>
        </Tooltip>
    )
}

export let startEditSimulation = (startEditMode: () => void) => {
    return (
        <Tooltip title={<Typography variant='body1'>{t('stock.label.simulate')}</Typography>} placement='top'>
            <IconButton data-tour='stockBoardSimulationButton' onClick={() => startEditMode()} size={'small'}><CreateOutlined /></IconButton>
        </Tooltip>
    )
}
