import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { createStyles, withStyles, Paper, Typography, Tabs, Tab } from '@material-ui/core'
import { muiOptions, defaultStyles, defaultColors } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import { Claims } from '../../../infrastructure/signIn/models'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { hasFeature } from '../../../infrastructure/feature'
import { Select, MonthPicker, CustomDialog, AttachFileButton, Button, Switch, DatePicker } from '../../common/customComponents'
import { Popup, ButtonPopupParams } from '../../common/components/popup'
import { ForecastContainer } from './forecastEditStore'
import CustomerSegmentTable from './_forecastEditTable'
import { MktSaleForecastValue } from '../models'

function ForecastEdit({ classes, closePopup, isOpen }) {
    let [tabIndex, setTabIndex] = useState(0)
    let store = ForecastContainer.useContainer()

    let onClosePopup = () => {
        store.setDefaultValues()
        closePopup()
    }

    let onSave = async () => {
        await store.save()
        store.setDefaultValues()
        closePopup()
    }

    let setMarketSalesForecastValue = (index: number, newValue: MktSaleForecastValue) => {
        let array = store.mktSaleForecast.values
        if (array !== null) {
            array[index] = newValue
            store.setMktSaleForecast({ ...store.mktSaleForecast, values: array })
        }
    }

    let shouldHideSaveButtonForPast = () => {
        if (store.isCompanyMonthFilled()) {
            let filterDate = moment(store.mktSaleForecast.date as string)
            let currentDate = moment()

            let isPastMonth = filterDate.month() < currentDate.month()
            let isSameMonth = filterDate.month() === currentDate.month()
            let isSameYear = filterDate.year() === currentDate.year()
            let isPastYear = filterDate.year() < currentDate.year()
            let isFutureYear = filterDate.year() > currentDate.year()

            let isPast = isPastYear || (isPastMonth && (isPastYear || isSameYear) && !isFutureYear)
                || (!isSameMonth && !isSameYear && !isFutureYear)
            return !hasFeature('AllowPastMktSalesDemandModification') && isPast
        }
        return true
    }

    useEffect(() => {
        if (isOpen)
            store.loadForecastDatas()
    }, [isOpen])

    useEffect(() => {
        store.setCustomerSegmentGroupedByChannel(store.channelCustomerSegments.indexMultipleValueByProp('channel'))
    }, [store.channelCustomerSegments, store.companies])

    useEffect(() => {
        let reload = async () => {
            if (store.isCompanyMonthFilled()) {
                let channelCustomerSegmentFilteredByCompany = store.channelCustomerSegments
                    .filter(x => x.company === store.mktSaleForecast.company)
                store.setCustomerSegmentGroupedByChannel(channelCustomerSegmentFilteredByCompany.indexMultipleValueByProp('channel'))
                await store.loadMktSales()
                await store.loadProductByCustomerSegment()
            }
        }
        reload()
    }, [store.mktSaleForecast.company, store.startDate, store.endDate, store.isSecondFortnight])

    let hasSavedOts = store.hasSavedOts()
    let mode = hasFeature('MktSalesForecastByPeriod') ? 'period' : hasFeature('MktSalesForecastByFortnight') ? 'fortnight' : 'month'
    let isManager = hasClaim(Claims.SalesManager)

    let buttons: ButtonPopupParams[] = [{
        buttonEffect: () => onSave(),
        buttonText: t('components.updateButton'),
        hideButton: !isManager || shouldHideSaveButtonForPast() || hasFeature('MktSalesForecastShipTo')
    }]

    return (
        <Popup title={t('mktSales.forecast.popupLabel')}
            close={() => onClosePopup()}
            isOpen={isOpen}
            buttons={buttons}>
            <div>
                <Paper className={classes.paperFilter}>
                    <div>
                        <Select label={t('mktSales.forecast.company')}
                            value={store.mktSaleForecast?.company}
                            choices={store.companies.map(x => ({ value: x.code, text: x.name }))} disableNewStyle
                            onChange={val => store.setMktSaleForecast({ ...store.mktSaleForecast, company: val })} />

                        {mode == 'period' && <>
                            <DatePicker date={store.startDate} label={t('mktSales.forecast.startDate')}
                                setDate={newDate => store.setStartDate(newDate)} disableNewStyle />
                            <DatePicker date={store.endDate} label={t('mktSales.forecast.endDate')}
                                setDate={newDate => store.setEndDate(newDate)} disableNewStyle />
                            {store.numberOfCalendarDays && <div className={classes.fortnightPicker}>
                                <Typography >
                                    {t("mktSales.forecast.calendarDays")} {store.numberOfCalendarDays}
                                </Typography>
                            </div>}
                        </>}

                        {(mode == 'month' || mode == 'fortnight') &&
                            <MonthPicker date={store.startDate} label={t('mktSales.forecast.month')}
                                setDate={newDate => setTimeout(() => { store.setStartDate(newDate) }, 1)} disableNewStyle />}

                        {mode == 'fortnight' && <>
                            <div className={classes.fortnightPicker}>
                                <Switch
                                    form
                                    changeCallback={() => store.toggleSecondFortnight()}
                                    isChecked={store.isSecondFortnight}
                                    offText={t('mktSales.forecast.firstFortnight')} onText={t('mktSales.forecast.secondFortnight')} />
                            </div>
                        </>}
                    </div>
                    <div>
                        {hasSavedOts
                            ? <Typography variant='h6' className={classes.snapshotText}>{t('mktSales.forecast.existingSnapshot') +
                                moment(store.mktSaleForecast.snapshotsInfos?.first().createdAt).format('MM/DD/YYYY')}</Typography>
                            : isManager && <Button onClick={() => store.setIsSnapshotCreationDialogOpen(true)}
                                label={t('mktSales.forecast.saveOts')}
                                className={classes.secondaryButton}
                                disabled={!store.isCompanyMonthFilled()} />
                        }
                        <div className={classes.buttonsContainer}>
                            {store.isCompanyMonthFilled() && store.documentKeyDate && isManager
                                ? <AttachFileButton
                                    id='upload-provisonal-doc-button'
                                    title={t('mktSales.label.documentDialogTitle')}
                                    disableDelOrAdd={!isManager}
                                    context={store.mktSaleForecast.company}
                                    keyTemplate='mktsale-{company}-{documentDate}'
                                    keyParameters={{ company: store.mktSaleForecast.company ?? '', documentDate: moment(store.documentKeyDate ?? '').format('YYYY-MM-DD') }} />
                                : null
                            }
                            {hasSavedOts && isManager
                                ? <Button onClick={() => store.setIsSnapshotCancellationDialogOpen(true)}
                                    label={t('mktSales.forecast.cancelOts')}
                                    className={classes.secondaryButton}
                                    disabled={!store.isCompanyMonthFilled()} />
                                : null
                            }
                        </div>
                    </div>
                </Paper>
                {store.isCompanyMonthFilled() && store.isProductsByCustomerSegmentSet() && store.productsByCustomerSegment ?
                    <Paper>
                        <div>
                            <Tabs classes={{ indicator: classes.TabsIndicatorColor }} value={tabIndex} onChange={(_, i) => setTabIndex(i)}>
                                {Object.keys(store.customerSegmentGroupedByChannel).map((x, i) => <Tab key={i} label={x} classes={{ textColorInherit: classes.TabTextColor }} />)}
                            </Tabs>
                            {Object.keys(store.customerSegmentGroupedByChannel).map((x, i) =>
                                <Typography
                                    key={i}
                                    component='div'
                                    role='tabpanel'
                                    hidden={tabIndex !== i}
                                    aria-labelledby={`wrapped-tab-${i}`}>
                                    {store.customerSegmentGroupedByChannel[x].filter(x => store.productsByCustomerSegment[x.customerSegment]).map((y, i) =>
                                        <CustomerSegmentTable key={i}
                                            channelCustomerSegment={y}
                                            mktSaleForecast={store.mktSaleForecast}
                                            products={store.productsByCustomerSegment[y.customerSegment]}
                                            setMarketSalesForecastValue={setMarketSalesForecastValue}
                                            sites={store.sites} setMktSaleForecast={store.setMktSaleForecast} />
                                    )}
                                </Typography>)}

                        </div>
                    </Paper>
                    : null}
                <CustomDialog isOpen={store.isSnapshotCreationDialogOpen}
                    title={t('mktSales.forecast.saveOtsDialogTitle')}
                    contentText={t('mktSales.forecast.saveOtsDialogText')}
                    confirmButtonText={t('mktSales.forecast.saveOts')}
                    onConfirm={store.createOtsSnapshot}
                    onClose={() => store.setIsSnapshotCreationDialogOpen(false)}
                    onCancel={() => store.setIsSnapshotCreationDialogOpen(false)} />

                <CustomDialog isOpen={store.isSnapshotCancellationDialogOpen}
                    title={t('mktSales.forecast.cancelOtsDialogTitle')}
                    contentText={t('mktSales.forecast.cancelOtsDialogText')}
                    confirmButtonText={t('mktSales.forecast.cancelOts')}
                    onConfirm={store.cancelOtsSnapshot}
                    onClose={() => store.setIsSnapshotCancellationDialogOpen(false)}
                    onCancel={() => store.setIsSnapshotCancellationDialogOpen(false)} />
            </div>
        </Popup>
    )
}

let styles = theme =>
    createStyles({
        paperFilter: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            height: 'auto',
            padding: '0.5em',
            margin: '0.5em 0em 0.5em 0em'
        },
        fortnightPicker: {
            display: 'inline-flex',
            paddingTop: '1em'
        },
        snapshotText: { color: defaultColors.darkBlue.main.color },
        buttonsContainer: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
        },
        secondaryButton: { ...defaultStyles.secondaryButton },
        TabTextColor: { color: defaultColors.red.main.color },
        TabsIndicatorColor: { backgroundColor: defaultColors.red.main.color }
    })

export default withStyles(styles, muiOptions)(ForecastEdit)