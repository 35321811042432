import { useState, useEffect, RefObject, useRef, useImperativeHandle } from 'react'
import ReactDOM from 'react-dom'
import { createContainer } from 'unstated-next'
import * as api from '../../../infrastructure/api'
import guid, { Guid } from '../../../infrastructure/guid'
import {
    Deal, Counterparty, DutyStatus, Company, Site, SupplyProduct,
    DealAssignee, DealKind, DuplicateDealCommand, CollapsableSection,
    GetIsIn, DealMovementItem, CreateMovementFromDeal, Period,
    DealFilter, DealForecastMktSales, SapCounterparty,
    DealMovementType, DealPriceIndex, DealPricingPeriod, PriceIndex,
    SapShipTo, MultiplePricingBlockType, DealTypes
} from '../dealModels'
import { snackbars } from '../../../infrastructure/snackbars'
import { t } from '../../../infrastructure/i18nextHelper'
import { useFieldStatus } from '../../common/fieldsStatus'
import { QuantityUnitPrice } from '../../administration/masterData/quantityUnitPrice'
import { UserContextContainer, hasDealClaim } from '../../../infrastructure/signIn/userContext'
import { hasFeature } from '../../../infrastructure/feature'
import { MovementListItem, MeanOfTransportation } from 'src/app/stock/stockModels'
import { GetMovementType, DealPricingType } from '../dealModels'
import moment from 'moment'

function useDealDialog() {
    let [isOpen, setIsOpen] = useState<boolean>(false)
    return { isOpen, setIsOpen }
}

const defaultOpenSections: { [T in CollapsableSection]: boolean } =
    { 'pricingInformation': false, 'sap': false, 'movements': false, 'DealProvisionalPrices': false, 'DealFinalPrices': false }

let getOpenSectionsFromLocalStorage = (): { [T in CollapsableSection]: boolean } => {
    let dealOpenSectionsValue = localStorage.getItem('dealOpenSections')
    if (!!dealOpenSectionsValue) {
        let dealOpenSections = JSON.parse(dealOpenSectionsValue)
        return dealOpenSections
    }
    return defaultOpenSections
}

function useDeal() {
    let dialog = DealDialogContainer.useContainer()
    let [deal, setDeal] = useState<Deal | null>(null)
    let [dealId, setDealId] = useState<Guid | null>(null)
    let [counterpartys, setCounterpartys] = useState<Counterparty[]>([])
    let [allCounterpartys, setAllCounterpartys] = useState<Counterparty[]>([])
    let [priceIndexes, setPriceIndexes] = useState<PriceIndex[]>([])
    let [dutyStatuses, setDutyStatuses] = useState<DutyStatus[]>([])
    let [companys, setCompanys] = useState<Company[]>([])
    let [paymentTerms, setPaymentTerms] = useState<string[]>([])
    let [sites, setSites] = useState<Site[]>([])
    let [products, setProducts] = useState<SupplyProduct[]>([])
    let [associatedVesselId, setAssociatedVesselId] = useState<Guid | null>(null)
    let [assigneeChoices, setAssigneeChoices] = useState<DealAssignee[]>([])
    let [unit, setUnit] = useState<string | undefined>()
    let [currency, setCurrency] = useState<string | undefined>()
    let [dealTypes, setDealTypes] = useState<string[]>([])
    let [quantityUnitPrices, setQuantityUnitPrices] = useState<QuantityUnitPrice[]>([])
    let [openSections, setOpenSections] =
        useState<{ [T in CollapsableSection]: boolean }>(getOpenSectionsFromLocalStorage())

    let [assignableMovements, setAssignableMovements] = useState<MovementListItem[] | null>(null)
    let [assignConfirmDialogOpen, setAssignConfirmDialogOpen] = useState<boolean>(false)
    let [selectedMovements, setSelectedMovements] = useState<MovementListItem[] | null>(null)
    let [unAssignConfirmDialogOpen, setUnAssignConfirmDialogOpen] = useState<boolean>(false)
    let [dealMovement, setDealMovement] = useState<DealMovementItem | null>(null)
    let [meanOfTransportations, setMeanOfTransportations] = useState<MeanOfTransportation[]>([])
    let [sapCounterpartys, setSapCounterpartys] = useState<SapCounterparty[]>([])
    let [shipTo, setShipTo] = useState<SapShipTo[]>([])

    let [filters, setFilters] = useState<DealFilter>(getLocalStorageFilters())

    let fieldStatus = useFieldStatus<Deal>()
    let costStatus = useFieldStatus<{ [P in string]: string }>()
    let user = UserContextContainer.useContainer()

    useEffect(() => {
        if (!deal) return
        fieldStatus.initPropertyTracking(deal)
        costStatus.initPropertyTracking((deal.fields ?? []).map(x => x.code))
    }, [deal?.id])

    useEffect(() => {
        (async () => {
            let unit = await getUnitFromDealProduct(deal?.productId);
            setUnit(unit);
        })()
    }, [deal?.productId]);

    useEffect(() => {
        (async () => {
            if (!!deal?.counterpartyId && !!deal?.company) {
                let shipToPromise = api.get<SapShipTo[]>(`deal/sapShipTo?company=${deal?.company}&&counterparty=${deal?.counterpartyId}`)
                setShipTo(await shipToPromise)
            }
        })()
    }, [deal?.counterpartyId, deal?.company]);

    useEffect(() => {
        if (!deal) return
        let newFinalPricingPeriods = resetPricingPeriodsAfterPricingChange(deal.finalPricing.pricingPeriods, deal.finalPricing.priceIndexes, 'Final')
        let newProvisionalPricingPeriods = resetPricingPeriodsAfterPricingChange(deal.provisionalPricing.pricingPeriods, deal.provisionalPricing.priceIndexes, 'Provisional')
        setDeal({
            ...deal,
            finalPricing: { ...deal.finalPricing, pricingPeriods: newFinalPricingPeriods },
            provisionalPricing: { ...deal.provisionalPricing, pricingPeriods: newProvisionalPricingPeriods }
        })
    }, [deal?.quantityPriceUnit]);

    useEffect(() => {
        (async () => {
            if (!!deal?.company && !!deal?.productId) {
                let priceIndexPromise = api.get<PriceIndex[]>(`deal/priceIndex/${deal?.company}/${deal?.productId}`)
                let priceIndexes = await priceIndexPromise;
                setPriceIndexes(priceIndexes)
            }
        })()
    }, [deal?.company, deal?.productId])

    useEffect(() => {
        (async () => {
            let currency = await getCurrencyFromDealCompany(deal?.company)
            setCurrency(currency)
            setAssigneeChoices([])
            if (deal && deal!.company) {
                let assignees = await api.get<DealAssignee[]>('deal/assignee', { company: deal.company })
                setAssigneeChoices(assignees)
                if (!deal.assignee)
                    setDeal({ ...deal, assignee: assignees[0] })
            }
        })()
    }, [deal?.company]);

    let getAssignableMovements = async () => {
        if (!(hasDealClaim() && canAssignMovement())) return
        let queryString = createMovementQueryObject();
        let movements = await api.get<MovementListItem[]>('stock/movement', queryString)
        let assignableMovements = movements.filter(x => !x.associatedDealId)
        setAssignableMovements(assignableMovements)
    }

    let assignableMovementsDependencies = [
        deal?.site,
        deal?.company,
        deal?.dutyStatus,
        deal?.productId,
        deal?.movementType,
        deal?.counterpartyId,
        deal?.validFrom,
        deal?.validTo
    ]

    useEffect(() => {
        getAssignableMovements()
    }, assignableMovementsDependencies);

    let addOrUpdateDealPriceIndex = (priceIndex: DealPriceIndex, type: MultiplePricingBlockType) => {
        if (!deal) return
        let existingPriceIndexes = type === 'Final' ? deal.finalPricing.priceIndexes : deal.provisionalPricing.priceIndexes
        let existingPricingPeriods = type === 'Final' ? deal.finalPricing.pricingPeriods : deal.provisionalPricing.pricingPeriods
        let newPriceIndexes: DealPriceIndex[] = existingPriceIndexes.some(x => priceIndex.id == x.id)
            ? existingPriceIndexes.map(x => (x.id === priceIndex.id) ? priceIndex : x)
            : existingPriceIndexes.concat(priceIndex)

        let newPricingPeriods = resetPricingPeriodsAfterPricingChange(existingPricingPeriods, newPriceIndexes, type)
        if (type === 'Final')
            setDeal({ ...deal, finalPricing: { ...deal.finalPricing, priceIndexes: newPriceIndexes, pricingPeriods: newPricingPeriods } })
        if (type === 'Provisional')
            setDeal({ ...deal, provisionalPricing: { ...deal.provisionalPricing, priceIndexes: newPriceIndexes, pricingPeriods: newPricingPeriods } })
    }

    let addOrUpdateDealPricingPeriod = (pricingPeriod: DealPricingPeriod, type: MultiplePricingBlockType) => {
        if (!deal) return
        let existingPricingPeriods = type === 'Final' ? deal.finalPricing.pricingPeriods : deal.provisionalPricing.pricingPeriods
        let existingPriceIndexes = type === 'Final' ? deal.finalPricing.priceIndexes : deal.provisionalPricing.priceIndexes
        let newPricingPeriods: DealPricingPeriod[] = ((existingPricingPeriods.some(x => pricingPeriod.id == x.id))
            ? resetPricingPeriodsAfterPricingChange(existingPricingPeriods.map(x => (x.id === pricingPeriod.id) ? pricingPeriod : x), existingPriceIndexes, type, pricingPeriod.id)
            : existingPricingPeriods.concat(pricingPeriod))
        if (type === 'Final')
            setDeal({ ...deal, finalPricing: { ...deal.finalPricing, pricingPeriods: newPricingPeriods } })
        if (type === 'Provisional')
            setDeal({ ...deal, provisionalPricing: { ...deal.provisionalPricing, pricingPeriods: newPricingPeriods } })
    }

    let removeDealPriceIndex = (id: Guid, type: MultiplePricingBlockType) => {
        if (!deal) return
        let existingPriceIndexes = type === 'Final' ? deal.finalPricing.priceIndexes : deal.provisionalPricing.priceIndexes
        let existingPricingPeriods = type === 'Final' ? deal.finalPricing.pricingPeriods : deal.provisionalPricing.pricingPeriods
        let newDealPriceIndexes = existingPriceIndexes.filter(x => x.id !== id)
        let newPricingPeriods = resetPricingPeriodsAfterPricingChange(existingPricingPeriods, newDealPriceIndexes, type)

        if (type === 'Final')
            setDeal({ ...deal, finalPricing: { ...deal.finalPricing, priceIndexes: newDealPriceIndexes, pricingPeriods: newPricingPeriods } })
        if (type === 'Provisional')
            setDeal({ ...deal, provisionalPricing: { ...deal.provisionalPricing, priceIndexes: newDealPriceIndexes, pricingPeriods: newPricingPeriods } })
    }

    let resetPricingPeriodsAfterPricingChange =
        (pricingPeriods: DealPricingPeriod[],
            updatedPriceIndexes: DealPriceIndex[],
            type: MultiplePricingBlockType,
            targetId: Guid | null = null
        ): DealPricingPeriod[] => {
            let updatedPremiumUnit = getPricingPeriodPremiumUnit(type, updatedPriceIndexes)
            return pricingPeriods.map(x => (!!targetId && targetId !== x.id) ? x : ({ ...x, baseUnitPrice: null, premiumUnit: updatedPremiumUnit }))
        }

    let removeDealPricingPeriod = (id: Guid, type: MultiplePricingBlockType) => {
        if (!deal) return
        let existingPricingPeriods = type === 'Final' ? deal.finalPricing.pricingPeriods : deal.provisionalPricing.pricingPeriods
        let newPricingPeriods = existingPricingPeriods.filter(x => x.id !== id)

        if (type === 'Final')
            setDeal({ ...deal, finalPricing: { ...deal.finalPricing, pricingPeriods: newPricingPeriods } })
        if (type === 'Provisional')
            setDeal({ ...deal, provisionalPricing: { ...deal.provisionalPricing, pricingPeriods: newPricingPeriods } })
    }

    let saveOpenSectionsInLocalStorage = () => {
        localStorage.setItem('dealOpenSections', JSON.stringify(openSections))
    }

    let setDealStatus = (deal: Deal) => {
        if (deal.referenceNumber && deal.movements && deal.movements.length != 0)
            deal.kind = DealKind.Linked
        else if (deal.referenceNumber && (!deal.movements || deal.movements.length == 0))
            deal.kind = DealKind.Autonomous
        else if (!deal.referenceNumber && (!deal.movements || deal.movements.length == 0))
            deal.kind = !deal.dealType ? DealKind.Creating : DealKind.Duplicate
    }

    let initializeNewDeal = (deal: Deal, pricingType: DealPricingType) => {
        deal.pricingType = pricingType
        deal.validFrom = ''
        deal.validTo = ''
        deal.country = user.currentCountry
        deal.isManual = true
    }

    let getDealInfos = async (dealId: Guid | null, pricingType?: DealPricingType) => {
        if (!dealId) return null;
        let dealPromise = api.get<Deal>(`deal/${dealId}`)
        let vesselPromise = api.get<{ vesselId: Guid | null }>(`vessel/fromDeal?dealId=${dealId}`)
        let deal = await dealPromise
        setDealStatus(deal)
        if (deal.kind == DealKind.Creating)
            initializeNewDeal(deal, pricingType!)
        let vessel = await vesselPromise
        initializeDealPriceIndexesAndPricingPeriods(deal)
        return { deal, vessel }
    }

    let initializeDealPriceIndexesAndPricingPeriods = (deal: Deal) => {
        if (deal.pricingType === 'Formula' && hasFeature('FormulaPricingV2')) {

            deal.finalPricing.priceIndexes = initializeIfEmptyIndexes(deal.finalPricing.priceIndexes)
            deal.finalPricing.pricingPeriods = initializeIfEmptyPeriods(deal.finalPricing.pricingPeriods, deal.quantityPriceUnit)

            if (hasFeature('ProvisionalPricing')) {
                deal.provisionalPricing.priceIndexes = initializeIfEmptyIndexes(deal.provisionalPricing.priceIndexes)
                deal.provisionalPricing.pricingPeriods = initializeIfEmptyPeriods(deal.provisionalPricing.pricingPeriods, deal.quantityPriceUnit)
            }
        }
    }

    let initializeIfEmptyIndexes = (priceIndexes: DealPriceIndex[]) => {
        return (priceIndexes.length > 0)
            ? priceIndexes
            : [{ id: guid.createNew(), percentage: 1, priceIndexCode: '' }]
    }

    let initializeIfEmptyPeriods = (pricingPeriods: DealPricingPeriod[], quantityPriceUnit: string | null) => {
        return (pricingPeriods.length > 0)
            ? pricingPeriods
            : [{
                id: guid.createNew(),
                percentage: 1,
                start: null,
                end: null,
                premium: 0,
                baseUnitPrice: null,
                premiumUnit: quantityPriceUnit ?? "?"
            }]
    }

    let getUnitFromDealProduct = async (productId: string | null | undefined): Promise<string | undefined> => {
        if (!productId) return undefined
        return products.find(x => x.id === productId)?.unit
    }

    let getCurrencyFromDealCompany = async (companyCode: string | null | undefined): Promise<string | undefined> => {
        if (!companyCode) return undefined
        return companys.find(x => x.code === companyCode)?.companyCurrency
    }

    let getRefs = async () => {
        let companysPromise = api.get<Company[]>('deal/company')
        let counterpartysPromise = api.get<Counterparty[]>('deal/counterparty')
        let allCounterpartysPromise = api.get<Counterparty[]>('deal/searchCounterpartys?includeInternals=true')
        let sitesPromise = api.get<Site[]>('deal/site')
        let productsPromise = api.get<SupplyProduct[]>('deal/product')
        let paymentTermsPromise = api.get<{ code: string }[]>('deal/paymentTerm')
        let dealTypesPromise = api.get<string[]>('deal/type')
        let quantityUnitPricesPromise = api.get<QuantityUnitPrice[]>('deal/quantityCurrencyUnitPrice')
        let meanOftransportationPromise = api.get<MeanOfTransportation[]>('deal/meanOfTransportation')
        let sapCounterpartysPromise = api.get<SapCounterparty[]>('deal/sapCounterParty')

        return {
            companys: await companysPromise,
            dutyStatuses: [...new Set<string>((await companysPromise)
                .reduce((acc, cur) => acc.concat(cur.dutyStatuses), new Array<string>()))],
            counterpartys: await counterpartysPromise,
            sites: await sitesPromise,
            products: await productsPromise,
            paymentTerms: (await paymentTermsPromise).map(x => x.code),
            dealTypes: await dealTypesPromise,
            quantityUnitPrices: await quantityUnitPricesPromise,
            meanOftransportation: await meanOftransportationPromise,
            sapCounterparty: await sapCounterpartysPromise,
            allCounterpartys: await allCounterpartysPromise
        }
    }

    let loadDeal = async (dealId: Guid | null, pricingType?: DealPricingType): Promise<Deal | null> => {
        let dealInfosPromise = getDealInfos(dealId, pricingType)
        let dealInfos = await dealInfosPromise
        if (!dealInfos) return null;
        let refs = shouldInit() ? await getRefs() : null
        let { deal, vessel } = dealInfos

        ReactDOM.unstable_batchedUpdates(() => {
            fieldStatus.clearStatuses()
            if (refs) {
                setCounterpartys(refs.counterpartys)
                setDutyStatuses(refs.dutyStatuses)
                setCompanys(refs.companys)
                setSites(refs.sites)
                setProducts(refs.products)
                setPaymentTerms(refs.paymentTerms)
                setDealTypes(refs.dealTypes)
                setQuantityUnitPrices(refs.quantityUnitPrices)
                setMeanOfTransportations(refs.meanOftransportation)
                setSapCounterpartys(refs.sapCounterparty)
                setAllCounterpartys(refs.allCounterpartys)
            }
            setAssociatedVesselId((vessel).vesselId)
            setDeal(deal)
            let dealComapnys = refs?.companys ?? companys
            if (dealComapnys.length) {
                let company = dealComapnys.find(x => x.code === deal.company)
                setDutyStatuses(company?.dutyStatuses ?? [])
            }
            setDealId(dealId)
        })

        if (dealId) window.history.pushState({}, '', '?openDeal=' + dealId)

        return deal
    }

    let saveDeal = async (): Promise<void> => {
        if (!dealId || !deal) return

        try {
            await api.post('deal', deal)
            if (canForecastDailyPurchases() && deal.movements.length > 0)
                snackbars.success(t('httpSuccess.dealAndPurchasesSaved'))
            else
                snackbars.success(t('httpSuccess.dealSaved'))
        } catch (err) {
            if (err.status !== 409) throw err
        }
        let currentDeal = await api.get<Deal>(`deal/${dealId}`)
        initializeDealPriceIndexesAndPricingPeriods(currentDeal)
        setDealStatus(currentDeal)
        setDeal(currentDeal)
    }

    let duplicateDeal = async (command: DuplicateDealCommand): Promise<void> => {
        try {
            await api.post('deal/duplicate', command)
            snackbars.success(t('httpSuccess.dealDuplicated'))
        } catch (err) {
            if (err.status !== 409) throw err
        }
    }

    let assignMovements = async (movementIds: string[] | undefined): Promise<void> => {
        await api.post(`deal/${dealId}/movement/batchAssign`, { dealId: deal?.id, movementIds })
    }

    let unAssignMovement = async (movementId: string | undefined): Promise<void> => {
        await api.del(`deal/${dealId}/movement/${movementId}`, { dealId: deal?.id, movementId: movementId })
    }

    let updatedDealFilters = () => hasFeature('UpdatedDealFilters')
    let locked = () => !!associatedVesselId && hasFeature('VesselAutoCreateDeal')
    let shouldInit = () => counterpartys.length == 0 || companys.length == 0 || sites.length == 0 || products.length == 0 || priceIndexes.length == 0
    let isAssociatedToAMovement = () => !!deal && !!deal.movements?.length
    let isAutonomous = () => !!deal && deal.kind == DealKind.Autonomous
    let isCreating = () => !!deal && deal.kind == DealKind.Creating
    let isDuplicate = () => !!deal && deal.kind == DealKind.Duplicate
    let canAssignMovement = () => hasFeature('AssignMovementFromDeal')
    let isSaleOnTruckDeal = () => hasFeature('SupplyMktSalesTruckDeal')
        && deal?.meanOfTransportation === "Road"
        && deal?.movementType === DealMovementType.SALE
    let isPaperTransaction = () => !!deal && deal?.dealType === DealTypes.PAPER || deal?.dealType === DealTypes.EXPOADJ

    let sapCounterpartyShipToCode = sapCounterpartys.find(x => x.counterpartyId == deal?.counterpartyId!)?.shipToCode

    let existInSapShipToTable = () => shipTo.length > 0 && shipTo.find(x => x.code === sapCounterpartyShipToCode)?.exists

    let canForecastDailyPurchases = () => hasFeature("BatchDailyMovementCreationFromDeal")
        && deal?.meanOfTransportation === "Road"
        && deal?.movementType === DealMovementType.PURCHASE
    let isInternal = () => !!deal && deal.dealType === "INTERNAL"

    let open = async (id: Guid, pricingType?: DealPricingType) => {
        dialog.setIsOpen(true)
        await loadDeal(id, pricingType)
    }

    let close = () => {
        ReactDOM.unstable_batchedUpdates(() => {
            setCounterpartys([])
            setCompanys([])
            setSites([])
            setPriceIndexes([])
            setProducts([])
            dialog.setIsOpen(false)
            fieldStatus.clearStatuses()
            setDealId(null)
            setAssociatedVesselId(null)
            setDeal(null)
            setQuantityUnitPrices([])
            setSapCounterpartys([])
            setShipTo([])
        })
        window.history.pushState({}, document.title, window.location.pathname)
    }

    let highlightErrors = () => {
        const alertStatus = 'alert'
        fieldStatus.clearStatuses()
        if (!deal?.company)
            fieldStatus.setStatus('company', alertStatus)
        if (!deal?.dutyStatus)
            fieldStatus.setStatus('dutyStatus', alertStatus)
        if (!deal?.referenceNumber && !isInternal())
            fieldStatus.setStatus('referenceNumber', alertStatus)
        if (!deal?.movementType)
            fieldStatus.setStatus('movementType', alertStatus)
        if (!deal?.dealType)
            fieldStatus.setStatus('dealType', alertStatus)
        if (!deal?.counterpartyId)
            fieldStatus.setStatus('counterpartyId', alertStatus)
        if (!deal?.site && !isInternal() && !isPaperTransaction())
            fieldStatus.setStatus('site', alertStatus)
        if (!deal?.productId)
            fieldStatus.setStatus('productId', alertStatus)
        if (!deal?.validFrom)
            fieldStatus.setStatus('validFrom', alertStatus)
        if (!deal?.validTo)
            fieldStatus.setStatus('validTo', alertStatus)
        if (deal?.volume === null && deal?.quantity === null)
            fieldStatus.setStatus(['volume', 'quantity'], alertStatus)
    }

    let updateDealCompany = async (newCompanyCode: string) => {
        if (deal == null) return;
        setDeal({ ...deal, company: newCompanyCode })
        let company = companys.find(x => x.code === newCompanyCode)
        if (company) setDutyStatuses(company.dutyStatuses)
    }

    let toggleSection = (section: CollapsableSection) => {
        let newOpenSection = openSections
        newOpenSection[section] = !openSections[section]
        setOpenSections({ ...newOpenSection })
        saveOpenSectionsInLocalStorage()
    }

    let createMovement = async (command: CreateMovementFromDeal) => {
        try {
            await api.post('deal/movement', command)
            snackbars.success(t('httpSuccess.movementCreated'))
        } catch (err) {
            if (err.status !== 409) throw err
        }
    }

    let forecastMktSales = async (command: DealForecastMktSales) => {
        try {
            await api.post('deal/mktSalesForecast', command)
            if (!!deal)
                await loadDeal(deal?.id ?? null, deal.pricingType)
        } catch (err) {
            if (err.status !== 409) throw err
        }
    }

    let forecastDailyPurchases = async () => {
        await saveDeal()
        try {
            await api.post('deal/createDailyMovement', { dealId: deal?.id })
            snackbars.success(t('deals.label.dailyPurchasesForecasted'))

            let currentDeal = await api.get<Deal>(`deal/${dealId}`)
            initializeDealPriceIndexesAndPricingPeriods(currentDeal)
            setDealStatus(currentDeal)
            setDeal(currentDeal)
        } catch (err) {
            if (err.status !== 409) throw err
        }
    }

    let createMovementQueryObject = () => {
        if (!deal) return
        let isIn = GetIsIn(GetMovementType(deal.movementType))
        let queryObject = {
            site: deal!.site,
            company: deal!.company,
            dutystatus: deal!.dutyStatus,
            productIds: deal!.productId,
            movementType: GetMovementType(deal!.movementType),
            counterpartyId: deal!.counterpartyId,
            start: deal!.validFrom,
            end: deal!.validTo,
            in: isIn
        }
        return queryObject
    }

    let getTranslatedMovementType = (dealMovementType?: string): string => {
        let tBase = 'deals.types.movement.'
        switch (dealMovementType) {
            case DealMovementType.SALE.toString(): return t(tBase + 'sale')
            case DealMovementType.PURCHASE.toString(): return t(tBase + 'purchase')
            case DealMovementType.STATUSCHANGE.toString(): return t(tBase + 'statusChange')
            case DealMovementType.TRANSFER.toString(): return t(tBase + 'transfer')
            case DealMovementType.REBRANDING.toString(): return t(tBase + 'rebranding')
            default: return ''
        }
    }

    dialogRef = useRef<OpenCloseDialogRef>(null)
    useImperativeHandle(dialogRef, () => ({ open, close }))

    let movementsVolume = isInternal()
        ? deal?.movementQuantity
        : deal?.movements?.reduce((acc, cur) => acc + (cur.volume ?? 0), 0)

    let getGlobalPricingPeriod = (type: MultiplePricingBlockType): Period => {
        let period: Period = { from: null, to: null }
        let existingPricingPeriods = type === 'Final' ? deal?.finalPricing.pricingPeriods : deal?.provisionalPricing.pricingPeriods
        if (!deal || existingPricingPeriods?.length === 0) return period
        existingPricingPeriods?.forEach(x => {
            if (!!x.start && (!period.from || moment(period.from) >= moment(x.start)))
                period.from = x.start
            if (!!x.end && (!period.to || moment(period.to) <= moment(x.end)))
                period.to = x.end
        })
        return period
    }

    let getPricingPeriodPremiumUnit = (type: MultiplePricingBlockType, updatedPriceIndexes: DealPriceIndex[] | undefined = undefined): string => {
        if (!deal) return "?"

        let oldPriceIndexes = type === 'Final' ? deal.finalPricing.priceIndexes : deal.provisionalPricing.priceIndexes
        let dealPriceIndexes = updatedPriceIndexes ?? oldPriceIndexes
        let firstPriceIndex = priceIndexes.find(x => x.medecoCode === dealPriceIndexes[0]?.priceIndexCode)
        let premiumUnit = !!firstPriceIndex ? [firstPriceIndex?.currency, firstPriceIndex?.unitOfMeasurement].join('/') : deal?.quantityPriceUnit
        return premiumUnit ?? "?"
    }

    let changeFieldValue = (code: string, newValue: number) => {
        if (!deal) return
        let index = deal.fields.findIndex(x => x.code == code)
        deal.fields[index].price = newValue ? newValue : null
        setDeal({ ...deal })
    }

    return {
        deal, setDeal, counterpartys, dutyStatuses, dealTypes, updateDealCompany, loadDeal, saveDeal, duplicateDeal,
        assignMovements, unAssignMovement, associatedVesselId, locked, companys, sites, products, priceIndexes, paymentTerms,
        dealId, setDealId, isAssociatedToAMovement, unit, assigneeChoices, fieldStatus, costStatus, highlightErrors,
        isCreating, isAutonomous, isDuplicate, openSections, toggleSection, movementsVolume, createMovement,
        quantityUnitPrices, setAssignableMovements, assignableMovements, canAssignMovement, setSelectedMovements, selectedMovements,
        assignConfirmDialogOpen, setAssignConfirmDialogOpen, unAssignConfirmDialogOpen, setUnAssignConfirmDialogOpen,
        dealMovement, setDealMovement, currency, meanOfTransportations, forecastMktSales, isSaleOnTruckDeal,
        removeDealPriceIndex, existInSapShipToTable, getTranslatedMovementType, addOrUpdateDealPriceIndex,
        addOrUpdateDealPricingPeriod, removeDealPricingPeriod, getGlobalPricingPeriod, getPricingPeriodPremiumUnit, filters, setFilters,
        canForecastDailyPurchases, forecastDailyPurchases, isInternal, allCounterpartys, updatedDealFilters, changeFieldValue, isPaperTransaction
    }
}

let defaultFilters = {
    movementTypes: [],
    validOn: moment.utc().format("MM/DD/YYYY"),
    validInStart: moment.utc().format("MM/DD/YYYY"),
    validInEnd: moment.utc().format("MM/DD/YYYY"),
    currentUserDealsOnly: false,
    isDayPeriodMode: true,
    productIds: [],
    sites: []
}

let getLocalStorageFilters = (): DealFilter => {
    let localFilters = localStorage.getItem('dealFilters')

    if (localFilters) {
        let filterObj = JSON.parse(localFilters)
        let filtersLength = Object.keys(filterObj).length

        if (filtersLength === 0) return defaultFilters

        let parseArray = x => Array.isArray(x) ? x : null

        return {
            validOn: filterObj.validOn,
            validInStart: filterObj.validInStart,
            validInEnd: filterObj.validInEnd,
            currentUserDealsOnly: filterObj.currentUserDealsOnly,
            companys: parseArray(filterObj.company),
            counterpartyIds: parseArray(filterObj.counterpartyId),
            dealTypes: parseArray(filterObj.dealType),
            dutyStatuss: parseArray(filterObj.dutyStatus),
            productIds: parseArray(filterObj.productId),
            isDayPeriodMode: filterObj.isDayPeriodMode,
            movementTypes: parseArray(filterObj.movementType) as string[],
            sites: parseArray(filterObj.site)
        }
    }

    return defaultFilters
}

type OpenCloseDialogRef = { open: (id: Guid, pricingType?: DealPricingType) => void, close: () => void }
let dialogRef: RefObject<OpenCloseDialogRef> | null = null
export let dealDialog = {
    open: (id, pricingType?) => dialogRef?.current?.open(id, pricingType),
    close: () => dialogRef?.current?.close(),
}

export let DealDialogContainer = createContainer(useDealDialog)
export let DealContainer = createContainer(useDeal)

export type DealContainerStore = ReturnType<typeof useDeal>