import React, { useState, useEffect } from 'react'
import { withStyles, createStyles, Typography, Checkbox } from '@material-ui/core'
import { defaultStyles, muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { api } from '../../../infrastructure/api'
import { t } from '../../../infrastructure/i18nextHelper'
import { ExcelGeneratorContainer } from '../../../infrastructure/excelExport'
import guid from '../../../infrastructure/guid'
import { ColumnDescriptor, TableItem } from '../../common/customComponents'
import { MasterDataShell, MasterDataItem, createExcelLines } from './masterDataShell'

function ReadMasterDataShellProps({ classes }: MuiProps) {
    let excelGenerator = ExcelGeneratorContainer.useContainer()

    let params = new URLSearchParams(location.search)
    var url = params.get('url')!
    var name = params.get('name')!
    let title = t(`admin.masterdata.${name}.${name}`)

    let [items, setItems] = useState<TableItem<any>[]>([])
    let [filters, setFilters] = useState<any>({})

    let toTableItem = item => {
        if ('id' in item) return item
        if ('code' in item) return { ...item, id: item.code }
        return { ...item, id: guid.createNew() }
    }

    let getItems = () => applyFilters(items, filters).map(toTableItem).slice(0, 500)

    let load = async () => {
        setItems(await api.get<TableItem<any>[]>(url))
    }

    useEffect(() => { load() }, [url])

    let capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
    let improveHeader = (property) => capitalizeFirstLetter(property.replace(/([A-Z])/g, ' $1').trim())

    let columns: ColumnDescriptor<TableItem<MasterDataItem<any>>>[] =
        items.length === 0 ? [] :
            Object.getOwnPropertyNames(items[0]).map(property =>
            ({
                name: improveHeader(property),
                value: x => x[property],
                columnFilter: {
                    value: filters[property] ?? '',
                    onChange: (value: string) => {
                        filters[property] = value
                        setFilters({ ...filters })
                    }
                }
            }))

    let exportExcel = async () => {
        excelGenerator.generate({
            filename: name + '.xlsx',
            sheets: [{ name: name, lines: createExcelLines(getItems(), columns) }]
        })
    }

    return (
        <div className={classes.container}>
            <MasterDataShell tableId={name + '-read-table'}
                headerLabel={title}
                items={getItems()}
                onExportExcel={exportExcel}
                columns={columns}>
            </MasterDataShell>
        </div >)
}

const filterByInput = (prop: string, input: string) => (filter: any) => filter[prop].toLowerCase().contains(input.toLowerCase());

let applyFilters = (companys: any[], filters: any): any[] => {
    for (const input in filters) {
        if (filters[input])
            companys = companys.filter(filterByInput(input, filters[input]))
    }
    return companys
}

let styles = (theme) =>
    createStyles({
        container: { height: '100%' },
        isBooleanContainer: {
            ...defaultStyles.flexRow,
            width: '15.3em',
            justifyContent: 'space-between',
            "& >span": { padding: '0' }
        }
    })
export default withStyles(styles, muiOptions)(ReadMasterDataShellProps) 