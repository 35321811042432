import React from 'react'
import { snackbars } from '../../../infrastructure/snackbars'
import { DealContainer } from './dealEditStore'
import { DatePicker, Select, TextField } from '../../common/customComponents'
import { t } from '../../../infrastructure/i18nextHelper'
import { Typography, Tooltip } from '@material-ui/core'
import { hasFeature } from '../../../infrastructure/feature'
import { Button } from '../../common/customComponents'
import { assignableMovementsDialog } from './assignableMovements'

const tPrefix = 'deals.label.'

type StringDate = string

type ValidityPeriodDate = 'validFrom' | 'validTo'

type AvailabilityDateProps = {
    type: 'From' | 'To',
    label: string
}

export let ValidityPeriodDate = ({ type, label }: AvailabilityDateProps): JSX.Element => {
    let store = DealContainer.useContainer()
    if (!store || !store.deal) return (<div />)
    let field: ValidityPeriodDate = 'valid' + type as ValidityPeriodDate
    let storedValue = store?.deal ? store.deal[field] : null

    let setDate = (value: StringDate) => {
        if (store.deal === null) return

        if (field == 'validFrom') {
            store.setDeal({ ...store.deal, validFrom: value })
        }
        else
            store.setDeal({ ...store.deal, validTo: value })
    }

    return (
        <DatePicker date={storedValue}
            status={store.fieldStatus.getStatus(field)}
            label={label} disabled={store.locked()}
            setDate={newDate => { if (newDate) setDate(newDate) }} />)
}

export let Assignee = (): JSX.Element => {
    let store = DealContainer.useContainer()
    let deal = store.deal
    let changeAssignee = (username: string) => {
        if (deal === null) return
        let assignee = store.assigneeChoices.first(x => x.userName === username)
        store.setDeal({ ...deal, assignee: assignee, assigneeOverwritten: true })
    }

    return (
        <Select label={t(tPrefix + 'assignee')}
            value={deal?.assignee?.userName ?? ''}
            status={store.fieldStatus.getStatus('assignee')}
            choices={store.assigneeChoices.map(x => ({ value: x.userName, text: x.fullName }))}
            onChange={value => { if (value) changeAssignee(value) }}
            auto={deal?.assigneeOverwritten}
            disabled={store.isInternal()} />
    )
}

type DutyStatusProps = {
    isLocked: boolean,
    disabledForLinkedOrAutonomous: boolean
}

export let DutyStatus = ({ isLocked, disabledForLinkedOrAutonomous }: DutyStatusProps): JSX.Element => {
    let store = DealContainer.useContainer()
    let deal = store.deal

    let onChange: (value: string) => void =
        isLocked
            ? () => { }
            : val => { if (val && !!deal) store.setDeal({ ...deal, dutyStatus: val }) }

    let isDisabled = isLocked || disabledForLinkedOrAutonomous || store.isDuplicate()

    let selectDutyStatus: JSX.Element =
        <Select
            label={t(tPrefix + 'dutyStatus')}
            status={store.fieldStatus.getStatus('dutyStatus')}
            hideOnSingleChoice
            value={deal?.dutyStatus ?? ''}
            choices={store.dutyStatuses}
            onChange={onChange}
            disabled={isDisabled} />

    if (isLocked)
        return (<Tooltip title={<Typography variant='body1'>{t('deals.label.mandatoryCompany')}</Typography>} placement='top'>
            <span>
                {selectDutyStatus}
            </span>
        </Tooltip>)
    else
        return selectDutyStatus
}

export let Currency = () => {
    let store = DealContainer.useContainer()
    let deal = store.deal
    return (<TextField
        label={t(tPrefix + 'currency')}
        disabled={true}
        text={deal?.currency}
        status={store.fieldStatus.getStatus('currency')}
        onChange={event => { if (deal !== null) store.setDeal({ ...deal, currency: event.target.value }) }}
    />)
}

export let AssignMovementButton = ({ buttonClass }: { buttonClass: string }): JSX.Element => {
    let store = DealContainer.useContainer();
    let onClickAssignMovement = async () => {
        store.highlightErrors()
        if (store.fieldStatus.getFieldsWithStatus('alert').length === 0) {
            if (store.assignableMovements?.length! > 0)
                assignableMovementsDialog.open()
            else
                snackbars.info(t('deals.label.noAssignableMovementFound'))
        }
        else
            store.setDeal({ ...store.deal! })
    }
    return (
        <Button
            label={t(tPrefix + 'assignMovementButton')}
            onClick={onClickAssignMovement}
            className={buttonClass} />)
}

export let Mot = (): JSX.Element => {
    let store = DealContainer.useContainer()
    let deal = store.deal

    return (
        <Select label={t(tPrefix + 'mot')}
            disabled={deal?.generateMktSaleForecasts || store.isInternal() || store.isPaperTransaction()}
            value={!store.isPaperTransaction() ? deal?.meanOfTransportation  : ''}
            choices={store.meanOfTransportations.map(x => x.name)}
            onChange={val => { if (!!deal && val) store.setDeal({ ...deal, meanOfTransportation: val }) }} />
    )
}
