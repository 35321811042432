import React from 'react'
import { withStyles } from '@material-ui/core'
import { muiOptions, MuiProps } from '../../../../infrastructure/materialUiThemeProvider'
import { KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment'
import { ThemeProvider, createStyles } from '@material-ui/styles'
import { t } from '../../../../infrastructure/i18nextHelper'
import { BaseFieldProps } from '../../fieldProps'
import { fieldStatuses } from '../../styles'
import { customDatePickerTheme } from './theme'
import { useDatePickerState } from './state'

type DatePickerStyleProps = {
    datepicker?
}

type DatePickerProps = {
    date: string | null
    setDate: (newDate: string | null) => void
    auto?: boolean
    errormessage?: string
    maxDate?: string
    label: string
    placeholder?: string
    disableFuture?: boolean
    disableNewStyle?: boolean
    hideUnderline?: boolean
    smallIcon?: boolean
    onLostFocus?: (e) => void
    classesOverride?: DatePickerStyleProps
    size?: 'small' | 'standard'
} & BaseFieldProps & MuiProps

function _DatePicker({ classes, classesOverride, date, placeholder, onLostFocus, setDate, disableFuture, auto, status, onError, disableNewStyle, hideUnderline, smallIcon, ...props }: DatePickerProps) {
    let state = useDatePickerState(date, setDate, onError)

    let highlight = status === 'info' ? classes.fieldStatusInfo
        : status === 'warning' ? classes.fieldStatusWarning : ''

    let inputVariant = !disableNewStyle ? { inputVariant: 'filled' } as any : {}

    let sizeClass = props.size === 'standard' ? classes.standard : props.size === 'small' ? classes.small : ''

    return (<ThemeProvider theme={customDatePickerTheme}>
        <KeyboardDatePicker
            className={`${classesOverride ? classesOverride.datepicker : classes.picker} ${highlight} ${sizeClass}`}
            error={status === 'alert' || state.isOnError}
            clearable
            {...inputVariant}
            disableFuture={!!disableFuture}
            variant='dialog'
            onBlur={onLostFocus}
            autoOk={true}
            value={date}
            placeholder={props.disabled ? '' : (placeholder ? moment(placeholder) : moment()).format('MM/DD/YYYY')}
            onChange={state.onChange}
            maxDate={props.maxDate}
            InputLabelProps={{ shrink: true }}
            InputProps={{ disableUnderline: hideUnderline || props.disabled }}
            format='MM/DD/YYYY'
            helperText={status === 'alert' || onError ? (props.errormessage ? props.errormessage : t('components.error')) : (auto ? t('components.autoCalculated') : null)}
            KeyboardButtonProps={smallIcon ? { size: 'small' } : undefined}
            {...props}
        />
    </ThemeProvider>)
}

type MonthPickerProps = {
    date: string | null
    setDate: (newDate: string | null) => void
    auto?: boolean
    label?: string
    disablePast?: boolean
    disableNewStyle?: boolean
    onLostFocus?: (e) => void
    size?: 'small' | 'standard'
    classesOverride?: DatePickerStyleProps
} & BaseFieldProps & MuiProps

let _MonthPicker = ({ classes, classesOverride, date, onLostFocus, setDate, disablePast, auto, onError, disableNewStyle, ...props }: MonthPickerProps) => {
    let state = useDatePickerState(date, setDate, onError)

    let highlight = props.status === 'info' ? classes.fieldStatusInfo
        : props.status === 'warning' ? classes.fieldStatusWarning : ''

    let inputVariant = !disableNewStyle ? { inputVariant: 'filled' } as any : {}

    let sizeClass = props.size === 'standard' ? classes.standard : props.size === 'small' ? classes.small : ''

    return (
        <ThemeProvider theme={customDatePickerTheme} >
            <KeyboardDatePicker
                className={`${classesOverride ? classesOverride.datepicker : classes.picker} ${highlight} ${sizeClass}`}
                clearable
                disablePast={!!disablePast}
                views={['year', 'month']}
                openTo={'month'}
                {...inputVariant}
                variant='dialog'
                onBlur={onLostFocus}
                error={props.status === 'alert' || state.isOnError}
                value={date}
                InputLabelProps={{ shrink: true }}
                placeholder={moment().format('MM/YYYY')}
                onChange={(date, text) => {
                    if (date?.isValid()) {
                        date = date.startOf('month')
                        state.onChange(date, text ?? null)
                    } else if (!date) {
                        state.onChange(null, null);
                    }
                }}
                format='MM/YYYY'
                helperText={auto ? t('components.autoCalculated') : null}
                {...props}
            />
        </ThemeProvider >)
}

type MonthRangePickerProps = {
    classes: any;
    classesOverride?: any
    startDate: string | null
    endDate: string | null
    onLostFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
    onStartDateChange: (newDate: string | null) => void
    onEndDateChange: (newDate: string | null) => void
    disablePast?: boolean
    auto?: boolean
    onError?: boolean
    disableNewStyle?: boolean
    label?: string
    status?: 'info' | 'warning' | 'alert'
    size?: 'standard' | 'small'
}

let _MonthRangePicker = ({ classes, classesOverride, startDate, endDate, onStartDateChange, onEndDateChange, disablePast, auto, onError, disableNewStyle, status, size, label }: MonthRangePickerProps) => {
    let [error, setError] = React.useState('')
    let handleStartDateChange = (date) => {
        if (endDate && date > endDate) {
            setError(t('components.monthPicker.errorStartMonth'));
        } else {
            setError('');
            onStartDateChange(date);
        }
    }

    let handleEndDateChange = (date) => {
        if (startDate && date < startDate) {
            setError(t('components.monthPicker.errorEndMonth'));
        } else {
            setError('');
            onEndDateChange(date);
        }
    }

    return (
        <div>
            <div className="container">
                <label className={classes.label}>{label}</label>
            </div>
            <MonthPicker
                classes={classes}
                classesOverride={classesOverride}
                date={startDate}
                setDate={handleStartDateChange}
                disablePast={disablePast}
                auto={auto}
                onError={onError}
                disableNewStyle={disableNewStyle}
                status={status}
                size={size}
            />
            <MonthPicker
                classes={classes}
                classesOverride={classesOverride}
                date={endDate}
                setDate={handleEndDateChange}
                disablePast={disablePast}
                auto={auto}
                onError={onError}
                disableNewStyle={disableNewStyle}
                status={status}
                size={size}
            />
            {error && <p className={classes.label} style={{ color: 'red' }}>{error}</p>}
        </div>
    )
}

let styles = _ =>
    createStyles({
        small: { width: '8em !important' },
        standard: { width: '10.3em !important' },
        picker: {
            width: '15.3em',
            margin: '0em 0.5em',
            "& .Mui-disabled": {
                backgroundColor: 'white',
                "& span": {
                    color: 'white'
                }
            }
        },
        container: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1em'
        },
        label: {
            color: 'rgba(0, 0, 0, 0.54)',
            fontSize: '12px',
            fontFamily: 'Arial, sans-serif',
            padding: '0.5em'
        },
        ...fieldStatuses
    })

export let DatePicker = withStyles(styles, muiOptions)(_DatePicker)
export let MonthPicker = withStyles(styles, muiOptions)(_MonthPicker)
export let MonthRangePicker = withStyles(styles, muiOptions)(_MonthRangePicker)
