import moment from 'moment'

const minDate = moment.utc('01/01/2000', 'MM/DD/YYYY')
const maxDate = moment.utc('01/01/2099', 'MM/DD/YYYY')

export function isWithinAcceptableRange(date: moment.Moment) {
    return minDate <= date && date <= maxDate
}

export function asDate(date: string) {
    return moment(date)
}