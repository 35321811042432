import * as api from '../../../../../infrastructure/api'
import { OpenPoReleaseDetails, poReleaseDetailsDialog } from './createInSap/releaseDialog'
import { SapTypes, isPurchaseOrderValidated } from './createInSap/formRequirements'
import { MovementForm } from '../../../stockModels'
import { hasFeature } from '../../../../../infrastructure/feature'

export let handleSapCall = async (type: SapTypes, movement: MovementForm, movementReloader?: () => Promise<void>) => {
    const goodsMovementAction: SapTypes[] = ['migo', 'directTransfer', 'rebranding309', 'gains', 'losses', 'poMigo']
    const poCreateAction: SapTypes[] = ['sto', 'purchase501']
    const poReleaseAction: SapTypes[] = ['poRelease']
    const deliveryNoteAction: SapTypes[] = ['stoDn']
    const goodsIssueAction: SapTypes[] = ['stoGoodsIssue']
    let shouldReload = true
    try {
        if (goodsMovementAction.some(action => action === type))
            await api.post('sapApi/goodsMovement', { movementId: movement.id, sapType: type })

        if (poCreateAction.some(action => action === type)) {
            await api.post('sapApi/purchaseOrderCreate', { movementId: movement.id, sapType: type })
        }

        if (goodsIssueAction.some(action => action === type)) {
            await api.post('sapApi/goodsIssue', { movementId: movement.id })
        }

        if (poReleaseAction.some(action => action === type)) {
            await api.post('sapApi/savePurchaseOrderReleaseIndicator', { movementId: movement.id })
            let movementForm = await api.get<MovementForm>(`stock/movement/${movement.id}`)
            if (isPurchaseOrderValidated(movementForm.mainSapFlow)) {
                return
            }
            shouldReload = false
            let arg: OpenPoReleaseDetails = {
                movement: movement,
                movementReloader: movementReloader!
            }
            poReleaseDetailsDialog.open(arg)
        }

        if (deliveryNoteAction.some(action => action === type)) {
            await api.post('sapApi/deliveryNote', { movementId: movement.id })

            if (!hasFeature('GoodsIssueOutsideDeliveryNote'))
                await api.post('sapApi/goodsIssue', { movementId: movement.id })
        }
    }
    catch (e) {
        throw e
    }
    finally {
        if (movementReloader && shouldReload)
            await movementReloader()
    }
}