import { loader } from './loader'
import { navigateToThenBack } from './navigation'
import { SnackBarVariant } from '../app/common/customComponents'
import { importDialog } from '../app/common/import'
import { t } from './i18nextHelper'
import { changeAuthToken, currentAuthToken } from './signIn/userContext'
import { Guid } from '@guid'
import guid from '../infrastructure/guid'
import { getProfile } from './signIn/authToken'

export const url = createApiUrl()
let showSnackbar: (message: string, variant: SnackBarVariant) => void = () => { }
let showSnackbarReport: (correlationId: Guid) => any = async () => { }
let reloadNotifications: () => void = () => { }

export let setApiSnackbarMethod = (method: (message: string, variant: SnackBarVariant) => void): void => { showSnackbar = method }
export let setApiSnackbarReportMethod = (method: (correlationId: Guid) => any): any => { showSnackbarReport = method }
export let setApiReloadNotificationsMethod = (method: () => void) => { reloadNotifications = method }

function isHttp() {
    return document.location.href.indexOf('http://') == 0
}

function isLocalHost() {
    return document.location.hostname.indexOf('localhost') != -1
}

function isTotalDotCom() {
    return document.location.hostname.indexOf('total.com') != -1
}

function isProduction() {
    return document.location.hostname == 'lynks.totalenergies.com'
}

function checkHttps() {
    if (isHttp() && !isLocalHost()) {
        document.location.href = document.location.href.replace('http://', 'https://')
    } else if (isTotalDotCom()) {
        document.location.href = document.location.href.replace('total.com', 'totalenergies.com')
    }
}

function createApiUrl() {
    if (isLocalHost())
        return 'https://localhost:5001/'
    else if (isProduction())
        return 'https://lynks.alzp.tgscloud.net/'
    else
        return 'https://' + document.location.hostname.replace('cf.', '') + '/'
}

export function get<T>(path: string, parameter?: any, options?: ApiOptions) {
    return parameter
        ? handleFetch(path + createQueryParameters(), { method: 'GET' }, options) as Promise<T>
        : handleFetch(path, { method: 'GET' }, options) as Promise<T>

    function createQueryParameters() {
        let parameters = new Array<string>()
        for (let prop in parameter) {
            if (parameter[prop] !== null && parameter[prop] !== undefined)
                parameters.push(prop + '=' + parameter[prop])
        }
        return '?' + parameters.join('&')
    }
}
type ReportType = 'none' | 'snackbar' | 'dialog'

export type ApiOptions = { correlationId?: Guid, withReport?: ReportType, countryOverride?: string }

export async function post<T>(path: string, parameter: T, options?: ApiOptions): Promise<HttpResponseEnvelope> {
    let correlationId = options?.correlationId ?? guid.createNew()
    let response = await handleFetch(path, { method: 'POST', body: JSON.stringify(parameter) }, options)
    reloadNotifications()
    return { correlationId: correlationId, fetchResponse: response }
}

export async function patch<T>(path: string, parameter: T, options?: ApiOptions): Promise<HttpResponseEnvelope> {
    let correlationId = options?.correlationId ?? guid.createNew()
    let response = await handleFetch(path, { method: 'PATCH', body: JSON.stringify(parameter) }, options)
    reloadNotifications()
    return { correlationId: correlationId, fetchResponse: response }
}

export async function upload(path: string, file: Blob, fileName: string, options?: ApiOptions): Promise<HttpResponseEnvelope> {
    let correlationId = options?.correlationId ?? guid.createNew()
    let formData = new FormData()
    formData.append(fileName, file)
    let inputs = { method: 'POST', body: formData, fileContentType: true }
    let response = await handleFetch(path, inputs, options)
    return { correlationId: correlationId, fetchResponse: response }
}

export function del<T>(path: string, parameter?: T, options?: ApiOptions) {
    return handleFetch(path, { method: 'DELETE', body: JSON.stringify(parameter) }, options)
}

function currentCountry() {
    return localStorage.getItem('userCountry')
}

function handleFetch(path: string, input: any, opt: ApiOptions | undefined) {
    let isOfflineResponse = json => json && json.status === 'Offline'
    let request = makeRequest()
    loader.displayProgressFor(request)
    return request

    async function makeRequest() {
        let correlationId = opt?.correlationId ?? guid.createNew()

        input.headers = input.headers ?? {}
        input.headers['X-Auth'] = ''
        input.headers['CorrelationId'] = correlationId

        if (!input.noContentType && !input.fileContentType)
            input.headers['content-type'] = 'application/json'

        let profile = getProfile()
        if (profile) input.headers['UserId'] = profile.userName

        if (currentAuthToken()) input.headers['X-Access-Token'] = currentAuthToken()

        if (currentCountry() || opt?.countryOverride)
            input.headers['Country'] = opt?.countryOverride ? opt.countryOverride : currentCountry()

        try {
            let response = await fetch(url + path, input)
            let newToken = response.headers.get('X-Access-Token')

            if (newToken) changeAuthToken(newToken)

            if (response.status < 100 || response.status >= 400)
                throw { status: response.status }

            let json = await response.json().catch(_ => { })

            if (isOfflineResponse(json)) throw { status: 0, statusText: 'Offline' }

            if (input.headers && input.method.toUpperCase() !== 'GET')
                await showSnackbarReport(input.headers['CorrelationId'])

            return json
        }
        catch (e) {
            if (e.status === 401) {
                navigateToThenBack('/signin')
            }
            else if (e.status === 409) displaySnackbarFromStatusCode(e.status)
            else if (opt?.withReport === 'dialog' && input.headers) {
                showDialogReport(input.headers['CorrelationId'])
                if (e.status === 500) displaySnackbarFromStatusCode(e.status)
            }
            else if (input.headers && input.method.toUpperCase() !== 'GET') {
                let hasDisplayedSomething = await showSnackbarReport(input.headers['CorrelationId'])
                if (!hasDisplayedSomething)
                    displaySnackbarFromStatusCode(e.status)
            }
            else displaySnackbarFromStatusCode(e.status)
            console.error(e)
            throw e
        }
    }
}

function showDialogReport(correlationId: Guid) {
    importDialog.openFromCorrelationId(correlationId)
}

let displaySnackbarFromStatusCode = (statusCode) => {
    switch (statusCode) {
        case 400: showSnackbar(t('httpErrors.badRequest'), SnackBarVariant.Warning); break;
        case 403: showSnackbar(t('httpErrors.forbidden'), SnackBarVariant.Warning); break;
        case 409: showSnackbar(t('httpErrors.conflict'), SnackBarVariant.Error); break;
        case 504: showSnackbar(t('httpErrors.timeout'), SnackBarVariant.Warning); break;
        default: showSnackbar(t('httpErrors.default'), SnackBarVariant.Error)
    }
}

type HttpResponseEnvelope = {
    correlationId: Guid,
    fetchResponse: any
}

export let api = {
    checkHttps,
    createApiUrl,
    get, post, upload, del, patch
}