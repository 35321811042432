import React from 'react'
import moment from 'moment'
import { withStyles, createStyles, Typography, Divider, IconButton } from '@material-ui/core'
import { TextField } from '../../common/customComponents'
import { defaultStyles, defaultColors, muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { storageLanguageKey, t } from '../../../infrastructure/i18nextHelper'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'
import { hasFeature } from '../../../infrastructure/feature'
import { Select, DatePicker, NumberField, AttachFileButton } from '../../common/customComponents'
import { FieldStatusStore } from '../../common/fieldsStatus'
import { DealContainer } from './dealEditStore'
import guid, { Guid } from '../../../infrastructure/guid'
import * as Icons from '@material-ui/icons'
import { quotationDetailsDialog } from '../../pricings/quotationDetailsDialog'
import { FieldState, ValidationProcessForm, Deal, PriceIndex, DealPriceIndex, DealPricingPeriod, Period, MultiplePricingBlockType, DealFieldUnit } from '../dealModels'

const tPrefix = 'deals.label.'

function _FirstLine({ classes }) {
    return (
        <div className={classes.row}>
            <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                {t('deals.popup.pricingTitle')}
            </Typography>
        </div>
    )
}

function _SecondLine({ classes }) {
    let store = DealContainer.useContainer()
    if (!store || !store.deal) return <></>

    let priceIndexes = store.priceIndexes

    function isFinalValidationEngaged() {
        if (!store.deal) return false
        let process = store.deal.finalPricesValidation
        return !!process && (process.steps.findIndex(x => x === process?.status) > 0)
    }

    let updatePremium = (newValue: number) => {
        if (deal.pricingType == 'Trigger')
            store.setDeal({ ...deal, fixedPrice: { ...deal.fixedPrice, premium: { ...deal.fixedPrice.premium, value: newValue, isOverwritten: isAValue(newValue) } } })
        else
            store.setDeal({ ...deal, quotationPeriod: { ...deal.quotationPeriod, premium: { ...deal.quotationPeriod.premium, value: newValue, isOverwritten: isAValue(newValue) } } })
    }

    let deal = store.deal
    let premium = deal.pricingType == 'Trigger' ? deal.fixedPrice.premium : deal.quotationPeriod.premium
    let bookingExchangeRate = store.deal.fields.find(x => x.code === "booking_exchange_rate")
    let bookingExchangeRateBorderCountry = deal?.borderCountryCurrency !== null
        ? deal.fields.find(x => x.code === "booking_exchange_rate_" + deal.borderCountryCurrency?.toLowerCase())
        : null

    let bookingExchangeRateUsdEur = deal.fields.find(x => x.code === "booking_exchange_rate_usd_eur")

    let contractualDensity = store.deal.fields.find(x => x.code === "contractual_density")
    let densityBblTo = store.deal.fields.find(x => x.code === "density_bbl_to")
    let densityToM15 = store.deal.fields.find(x => x.code === "density_to_m15")
    let exchangeRate = store.deal.fields.find(x => x.code === "exchange_rate")

    let isFormulaV2 = deal.pricingType == 'Formula' && hasFeature('FormulaPricingV2')

    let priceIndexCurrency = priceIndexes.find(x => x.medecoCode === deal.quotationPeriod.quotation.value)?.currency
    let shouldDisplayExchangeRate = exchangeRate && deal.pricingType === 'Formula' && !hasFeature('FormulaPricingV2')
        && !!priceIndexCurrency && !!deal.currency && priceIndexCurrency !== deal.currency

    return (
        <>
            <div className={(isFormulaV2 ? classes.column : classes.row) + ' ' + classes.marginStartLine}>
                {isFormulaV2
                    ? <MultipleFormulaPricing classes={classes} priceIndexes={priceIndexes} isFinalValidationEngaged={isFinalValidationEngaged()} />
                    : <QuotationPeriod classes={classes}
                        quotations={priceIndexes.filter(x => x.type === 'Quotation').map(x => ({ value: x.medecoCode, text: x.name }))}
                        isFinalValidationEngaged={isFinalValidationEngaged()} />}
                {bookingExchangeRate ?
                    <NumberField
                        status={store.costStatus.getStatus(bookingExchangeRate.code)}
                        label={[t(tPrefix + 'bookingExchangeRate'), bookingExchangeRate.currencyPerQuantityUnit].join(' ')}
                        text={bookingExchangeRate.price}
                        disabled={bookingExchangeRate.readOnly || isFinalValidationEngaged()}
                        precision={5}
                        onChange={newValue => store.changeFieldValue("booking_exchange_rate", newValue)} />
                    : undefined}
                {bookingExchangeRateBorderCountry ?
                    <NumberField
                        status={store.costStatus.getStatus(bookingExchangeRateBorderCountry.code)}
                        label={[t(tPrefix + 'bookingExchangeRate'), bookingExchangeRateBorderCountry.currencyPerQuantityUnit].join(' ')}
                        text={bookingExchangeRateBorderCountry.price}
                        disabled={bookingExchangeRateBorderCountry.readOnly || isFinalValidationEngaged()}
                        precision={5}
                        onChange={newValue => bookingExchangeRateBorderCountry && store.changeFieldValue(bookingExchangeRateBorderCountry.code, newValue)} />
                    : undefined}
                {bookingExchangeRateUsdEur ?
                    <NumberField
                        status={store.costStatus.getStatus(bookingExchangeRateUsdEur.code)}
                        label={[t(tPrefix + 'bookingExchangeRate'), bookingExchangeRateUsdEur.currencyPerQuantityUnit].join(' ')}
                        text={bookingExchangeRateUsdEur.price}
                        disabled={bookingExchangeRateUsdEur.readOnly || isFinalValidationEngaged()}
                        precision={5}
                        onChange={newValue => bookingExchangeRateUsdEur && store.changeFieldValue(bookingExchangeRateUsdEur.code, newValue)} />
                    : undefined}
                {contractualDensity ?
                    <NumberField
                        status={store.costStatus.getStatus(contractualDensity.code)}
                        label={[t(tPrefix + 'contractualDensity'), contractualDensity.currencyPerQuantityUnit].join(' ')}
                        text={contractualDensity.price}
                        disabled={contractualDensity.readOnly || isFinalValidationEngaged()}
                        precision={5}
                        onChange={newValue => store.changeFieldValue("contractual_density", newValue)} />
                    : undefined}
                {densityBblTo ?
                    <NumberField
                        status={store.costStatus.getStatus(densityBblTo.code)}
                        label={[t(tPrefix + 'costs.density'), densityBblTo.currencyPerQuantityUnit].join(' ')}
                        text={densityBblTo.price}
                        disabled={densityBblTo.readOnly || isFinalValidationEngaged()}
                        precision={5}
                        onChange={newValue => store.changeFieldValue("density_bbl_to", newValue)} />
                    : undefined}
                {densityToM15 ?
                    <NumberField
                        status={store.costStatus.getStatus(densityToM15.code)}
                        label={[t(tPrefix + 'costs.density'), densityToM15.currencyPerQuantityUnit].join(' ')}
                        text={densityToM15.price}
                        disabled={densityToM15.readOnly || isFinalValidationEngaged()}
                        precision={5}
                        onChange={newValue => store.changeFieldValue("density_to_m15", newValue)} />
                    : undefined}
                {deal.pricingType != 'Fixed' && !isFormulaV2
                    ? <NumberField
                        status={store.costStatus.getStatus('premium')}
                        label={[t(tPrefix + 'premium'), premium.unit].join(' ')}
                        text={premium.value}
                        disabled={premium.readOnly || isFinalValidationEngaged()}
                        precision={5}
                        allowNegative
                        onChange={newValue => updatePremium(newValue)} />
                    : undefined}
                {shouldDisplayExchangeRate
                    ? <NumberField
                        status={store.costStatus.getStatus('')}
                        label={[t(tPrefix + 'exchangeRate'), resolveExchangeRateUnit(exchangeRate?.currencyPerQuantityUnit!, deal.currency, priceIndexCurrency)].join(' ')}
                        text={exchangeRate?.price}
                        disabled={exchangeRate!.readOnly}
                        precision={5}
                        allowNegative
                        onChange={newValue => store.changeFieldValue('exchange_rate', newValue)} />
                    : undefined}
            </div>
        </>
    )
}

function resolveExchangeRateUnit(exchangeRateDynamicUnit: string,
    dealCurrency: string | null,
    priceIndexCurrency: string | undefined,
    type: MultiplePricingBlockType = 'Final') {
    let currencyDynamicUnit: DealFieldUnit = type === 'Final'
        ? 'QuotationCurrency'
        : 'ProvisionalQuotationCurrency'
    let dealCurrencyUnit: DealFieldUnit = 'DealCurrency'

    return `${exchangeRateDynamicUnit
        .replace(dealCurrencyUnit, dealCurrency ?? '?')
        .replace(currencyDynamicUnit, priceIndexCurrency ?? '?')}`
}

type QuotationPeriodProps = {
    classes?: any
    quotations: { value: string, text: string }[]
    isFinalValidationEngaged: boolean
}

function QuotationPeriod({ classes, quotations, isFinalValidationEngaged }: QuotationPeriodProps) {
    let store = DealContainer.useContainer()
    if (!store || !store.deal) return <></>
    let deal = store.deal

    let quotationOverwritten = { readOnly: false }
    let fixedPriceOverwritten = { isOverwritten: true, readOnly: false }

    return (
        <div className={classes.row + ' ' + classes.marginStartLine}>
            {
                deal.pricingType != 'Fixed'
                    ? <Select choices={quotations} label={t(tPrefix + 'quotation')} value={deal.quotationPeriod.quotation.value}
                        disabled={(deal.quotationPeriod.quotation.readOnly || isFinalValidationEngaged)}
                        onChange={val => { if (event) store.setDeal({ ...deal, quotationPeriod: { ...deal.quotationPeriod, quotation: { ...quotationOverwritten, value: val, isOverwritten: true } } }) }} />
                    : undefined
            }
            {
                deal.pricingType != 'Trigger' && deal.pricingType != 'Fixed'
                    ? [<DatePicker key={'pricingStartDate'} label={t(tPrefix + 'pricingStartDate')} date={deal.quotationPeriod.start.value} auto={!deal.quotationPeriod.start.isOverwritten}
                        disabled={deal.quotationPeriod.start.readOnly || isFinalValidationEngaged}
                        setDate={newDate => {
                            let hasValue = !!newDate && moment(newDate).isValid()
                            store.setDeal({ ...deal, quotationPeriod: { ...deal.quotationPeriod, start: { ...quotationOverwritten, value: hasValue ? newDate : null, isOverwritten: hasValue } } })
                        }} />,
                    <DatePicker key={'pricingEndDate'} label={t(tPrefix + 'pricingEndDate')} date={deal.quotationPeriod.end.value} auto={!deal.quotationPeriod.end.isOverwritten}
                        disabled={deal.quotationPeriod.end.readOnly || isFinalValidationEngaged}
                        setDate={newDate => {
                            let hasValue = !!newDate && moment(newDate).isValid()
                            store.setDeal({ ...deal, quotationPeriod: { ...deal.quotationPeriod, end: { ...quotationOverwritten, value: hasValue ? newDate : null, isOverwritten: hasValue } } })
                        }} />]
                    : [<DatePicker key={'triggerDate'}
                        label={deal.pricingType === 'Trigger' ? t(tPrefix + 'triggerDate') : t(tPrefix + 'fixedPriceDate')}
                        date={deal.fixedPrice.date.value}
                        disabled={deal.fixedPrice.date.readOnly || isFinalValidationEngaged}
                        setDate={newDate => { if (newDate) store.setDeal({ ...deal, fixedPrice: { ...deal.fixedPrice, date: { ...fixedPriceOverwritten, value: newDate } } }) }} />,
                    <NumberField key={'triggerPrice'}
                        label={[deal.pricingType === 'Trigger'
                            ? t(tPrefix + 'triggerPrice')
                            : t(tPrefix + 'fixedPricePrice')
                            , deal.fixedPrice.price.unit].join(' ')}
                        text={deal.fixedPrice.price.value}
                        disabled={deal.fixedPrice.price.readOnly || isFinalValidationEngaged}
                        precision={5}
                        onChange={newValue => store.setDeal({ ...deal, fixedPrice: { ...deal.fixedPrice, price: { ...fixedPriceOverwritten, value: newValue, unit: deal.fixedPrice.price.unit } } })} />]
            }
        </div>)
}

type MultipleFormulaPricing = {
    classes?: any
    isFinalValidationEngaged: boolean,
    priceIndexes: PriceIndex[]
}

function MultipleFormulaPricing({ classes, isFinalValidationEngaged, priceIndexes }: MultipleFormulaPricing) {
    let store = DealContainer.useContainer()
    if (!store || !store.deal) return <></>
    let deal = store.deal

    return (<>
        <MultiplePriceIndexPricingPeriod
            classes={classes}
            isFinalValidationEngaged={isFinalValidationEngaged}
            priceIndexes={priceIndexes}
            type='Final'
            dealPriceIndexes={deal.finalPricing.priceIndexes}
            dealPricingPeriods={deal.finalPricing.pricingPeriods}
            quantityPriceUnit={deal.quantityPriceUnit}
            dealCurrency={deal.currency}
            exchangeRate={store.deal.fields.find(x => x.code === "exchange_rate")} />
        {hasFeature('ProvisionalPricing')
            ? <>
                <Divider className={classes.divider} />
                <MultiplePriceIndexPricingPeriod
                    classes={classes}
                    isFinalValidationEngaged={isFinalValidationEngaged}
                    priceIndexes={priceIndexes}
                    type='Provisional'
                    dealPriceIndexes={deal.provisionalPricing.priceIndexes}
                    dealPricingPeriods={deal.provisionalPricing.pricingPeriods}
                    quantityPriceUnit={deal.quantityPriceUnit}
                    dealCurrency={deal.currency}
                    exchangeRate={store.deal.fields.find(x => x.code === 'provisional_exchange_rate')} />
            </>
            : null}
    </>
    );
}

type MultiplePriceIndexPricingPeriodProps = {
    classes?: any
    isFinalValidationEngaged: boolean,
    priceIndexes: PriceIndex[],
    type: MultiplePricingBlockType,
    dealPriceIndexes: DealPriceIndex[],
    dealPricingPeriods: DealPricingPeriod[],
    quantityPriceUnit: string | null,
    dealCurrency: string | null,
    exchangeRate: FieldState | undefined
}

function MultiplePriceIndexPricingPeriod(
    { classes, isFinalValidationEngaged, priceIndexes, type, dealPriceIndexes, dealPricingPeriods, quantityPriceUnit, dealCurrency, exchangeRate }:
        MultiplePriceIndexPricingPeriodProps) {
    let store = DealContainer.useContainer()

    let onChangePriceIndexPercentage = (id: Guid, newValue: number) => {
        let existingPriceIndex = dealPriceIndexes.find(x => x.id === id)
        if (!existingPriceIndex) return
        let updatedPriceIndex = { ...existingPriceIndex, percentage: newValue }
        store.addOrUpdateDealPriceIndex(updatedPriceIndex, type)
    }

    let onChangePriceIndexCode = (id: Guid, newValue: string) => {
        let existingPriceIndex = dealPriceIndexes.find(x => x.id === id)
        if (!existingPriceIndex) return
        let updatedPriceIndex = { ...existingPriceIndex, priceIndexCode: newValue }
        store.addOrUpdateDealPriceIndex(updatedPriceIndex, type)
    }

    let onChangePricingPeriod = (id: Guid, applyChange: (x: DealPricingPeriod) => void) => {
        let existingPricingPeriod = dealPricingPeriods.find(x => x.id === id)
        if (!existingPricingPeriod) return
        let updatedPricingPeriod = { ...existingPricingPeriod }
        applyChange(updatedPricingPeriod)
        store.addOrUpdateDealPricingPeriod(updatedPricingPeriod, type)
    }

    let createDealPriceIndex = () => {
        store.addOrUpdateDealPriceIndex({ id: guid.createNew(), percentage: 0, priceIndexCode: '' }, type)
    }

    let createDealPricingPeriod = () => {
        store.addOrUpdateDealPricingPeriod({
            id: guid.createNew(),
            percentage: 0,
            start: null,
            end: null,
            premium: 0,
            baseUnitPrice: null,
            premiumUnit: store.getPricingPeriodPremiumUnit(type)
        }, type)
    }

    let onDeletePriceIndex = (id: Guid) => { store.removeDealPriceIndex(id, type) }
    let onDeletePricingPeriod = (id: string) => { store.removeDealPricingPeriod(id, type) }
    let allowPriceIndexDelete = dealPriceIndexes.length > 1 && !isFinalValidationEngaged
    let allowPricingPeriodDelete = dealPricingPeriods.length > 1 && !isFinalValidationEngaged
    let allowPriceIndexCreation = hasFeature('DealFormulaMultipleQuotationIndex') && !isFinalValidationEngaged
    let allowPricingPeriodCreation = hasFeature('DealFormulaMultiplePricingPeriod') && !isFinalValidationEngaged

    let principalPriceIndexCurrency = priceIndexes.find(x => x.medecoCode === dealPriceIndexes.first()?.priceIndexCode)?.currency
    let shouldDisplayExchangeRate = exchangeRate && !!principalPriceIndexCurrency && !!dealCurrency && principalPriceIndexCurrency !== dealCurrency
    return (
        <div className={[classes.column, classes.marginStartLine, classes.marginBottom].join(' ')}>
            <Typography className={classes.subtitle} variant='overline'>{t('deals.label.costGroups.'.concat((type === 'Final') ? 'default' : 'provisional'))}</Typography>
            {shouldDisplayExchangeRate && < NumberField
                status={store.costStatus.getStatus('')}
                label={[t(tPrefix + 'exchangeRate'), resolveExchangeRateUnit(exchangeRate?.currencyPerQuantityUnit!, dealCurrency, principalPriceIndexCurrency, type)]
                    .join(' ')}
                text={exchangeRate?.price}
                disabled={exchangeRate!.readOnly}
                precision={5}
                allowNegative
                onChange={newValue => store.changeFieldValue(type === 'Final' ? 'exchange_rate' : 'provisional_exchange_rate', newValue)} />}
            <div className={classes.marginBottom}>{dealPriceIndexes.map((x, index) =>
                <PriceIndexLine classes
                    key={x.id}
                    dealPriceIndex={x}
                    priceIndexes={priceIndexes}
                    onChangePriceIndexPercentage={val => onChangePriceIndexPercentage(x.id, val)}
                    onChangePriceIndexCode={val => onChangePriceIndexCode(x.id, val)}
                    onDelete={allowPriceIndexDelete ? (() => onDeletePriceIndex(x.id)) : undefined}
                    onCreate={allowPriceIndexCreation && index === 0 ? createDealPriceIndex : undefined}
                    globalPricingPeriod={store.getGlobalPricingPeriod(type)}
                    readonly={isFinalValidationEngaged} />)}
            </div>
            <div>
                {
                    dealPricingPeriods.map((x, index) =>
                        <PricingPeriodLine
                            classes={classes}
                            key={x.id}
                            pricingPeriod={x}
                            onChangePricingPeriod={(f) => onChangePricingPeriod(x.id, f)}
                            priceUnit={quantityPriceUnit ?? '?'}
                            onCreate={allowPricingPeriodCreation && index === 0 ? createDealPricingPeriod : undefined}
                            onDelete={allowPricingPeriodDelete ? (() => onDeletePricingPeriod(x.id)) : undefined}
                            readonly={isFinalValidationEngaged}
                        />)
                }
            </div>
        </div>
    )
}

type PriceIndexLineProps = {
    classes?: any
    dealPriceIndex: DealPriceIndex
    priceIndexes: PriceIndex[]
    onChangePriceIndexPercentage: (newValue: number) => void
    onChangePriceIndexCode: (newValue: string) => void
    onDelete?: () => void | undefined
    onCreate?: () => void | undefined
    globalPricingPeriod: Period
    readonly: boolean
}
function PriceIndexLine({ classes, dealPriceIndex, priceIndexes,
    onChangePriceIndexPercentage, onChangePriceIndexCode, onDelete, onCreate,
    globalPricingPeriod, readonly }: PriceIndexLineProps) {
    let priceIndexChoices = priceIndexes.map(x => ({ value: x.medecoCode, text: x.name }))
    let priceIndex = priceIndexes.find(x => x.medecoCode == dealPriceIndex.priceIndexCode)
    let priceIndexQuantityPriceUnit = [priceIndex?.currency ?? '?', priceIndex?.unitOfMeasurement ?? '?'].join('/')

    let openQuotationDialog = () => {
        if (!globalPricingPeriod.from || !globalPricingPeriod.to || !dealPriceIndex.priceIndexCode) return
        quotationDetailsDialog.open({ index: dealPriceIndex.priceIndexCode, from: globalPricingPeriod.from, to: globalPricingPeriod.to })
    }

    let enabaleQuotationPopupButton = !!globalPricingPeriod.from && !!globalPricingPeriod.to && !!dealPriceIndex.priceIndexCode && priceIndex?.type === 'Quotation'

    return (<div className={classes.row} >
        <IconButton disabled={!enabaleQuotationPopupButton} onClick={openQuotationDialog}>
            <Icons.OpenInNewOutlined />
        </IconButton>
        <NumberField
            size={'small'}
            label={t(tPrefix + 'percentage')}
            text={dealPriceIndex.percentage * 100}
            precision={5}
            diabled={readonly}
            onChange={val => { if (val !== null) onChangePriceIndexPercentage(val / 100) }} />
        <Select
            label={t(tPrefix + 'quotation')}
            choices={priceIndexChoices}
            value={dealPriceIndex.priceIndexCode}
            disabled={readonly}
            onChange={val => { if (val) onChangePriceIndexCode(val) }} />
        <TextField
            label={t(tPrefix + 'quantityPriceUnit')}
            text={priceIndexQuantityPriceUnit}
            disabled={true} />
        {onDelete && <IconButton onClick={onDelete}>
            <Icons.DeleteOutlined />
        </IconButton>}
        {onCreate && <IconButton onClick={onCreate}>
            <Icons.Add />
        </IconButton>}
    </div>)
}

type DealPricingPeriodLineProps = {
    classes?: any
    pricingPeriod: DealPricingPeriod
    priceUnit: string
    onChangePricingPeriod: (applyChange: (pp: DealPricingPeriod) => void) => void
    onDelete?: () => void | undefined
    onCreate?: () => void | undefined
    readonly: boolean
}
function PricingPeriodLine({ classes, pricingPeriod, priceUnit, onChangePricingPeriod, onDelete, onCreate, readonly }: DealPricingPeriodLineProps) {
    return (<div className={classes.row} >
        <NumberField
            size={'small'}
            label={t(tPrefix + 'percentage')}
            text={pricingPeriod.percentage * 100}
            precision={5}
            disabled={readonly}
            onChange={val => { if (val !== null) onChangePricingPeriod((x: DealPricingPeriod) => x.percentage = val / 100) }} />
        <DatePicker
            label={t(tPrefix + 'pricingStartDate')}
            date={pricingPeriod.start}
            disabled={readonly}
            setDate={newDate => onChangePricingPeriod((x: DealPricingPeriod) => x.start = !!newDate && moment(newDate).isValid() ? newDate : null)} />
        <DatePicker
            label={t(tPrefix + 'pricingEndDate')}
            date={pricingPeriod.end}
            disabled={readonly}
            setDate={newDate => onChangePricingPeriod((x: DealPricingPeriod) => x.end = !!newDate && moment(newDate).isValid() ? newDate : null)} />
        <NumberField
            label={[t(tPrefix + 'premium'), pricingPeriod.premiumUnit].join(' ')}
            text={pricingPeriod.premium}
            precision={5}
            disabled={readonly}
            onChange={val => { if (val !== null) onChangePricingPeriod((x: DealPricingPeriod) => x.premium = val) }} />
        <TextField
            label={[t(tPrefix + 'baseUnitPrice'), priceUnit].join(' ')}
            text={pricingPeriod.baseUnitPrice}
            disabled={true}
            auto={true} />
        {onDelete && <IconButton onClick={onDelete}>
            <Icons.DeleteOutlined />
        </IconButton>}
        {onCreate && <IconButton onClick={onCreate}>
            <Icons.Add />
        </IconButton>}
    </div>)
}

function isAValue(str: any) { return str != null && str !== '' }

function _ThirdLine({ classes }) {
    let store = DealContainer.useContainer()

    let elements = React.useMemo(() => {
        if (!store?.deal?.fields?.length) return []

        let costsPerGroup = store.deal.fields.filter(x => x.group !== "custom").indexMultipleValueByProp('group')
        return Object.keys(costsPerGroup).sort(compareCostsGroup)

        function compareCostsGroup(groupA: string, groupB: string): number {
            let indexA = costsPerGroup[groupA][0].groupOrder
            let indexB = costsPerGroup[groupB][0].groupOrder
            return indexA - indexB
        }
    }, [store?.deal?.fields])

    return (
        <div>
            {elements.map((costGroup, index) =>
                <CostGroup key={index} costGroup={costGroup} classes={classes} />)}
        </div>
    )
}

type CostGroupProps = {
    costGroup: string
    classes?: any
}

function CostGroup({ costGroup, classes }: CostGroupProps) {
    let store = DealContainer.useContainer()
    let deal = store.deal as Deal
    let costsPerGroup = deal.fields.indexMultipleValueByProp('group')

    let changeValue = (group: string, index: number, newValue: number, unit: string) => {
        let costs = deal.fields
        let globalIndex = costs.findIndex(x => x.code == costsPerGroup[group][index].code)
        costs[globalIndex].price = newValue ? newValue : null
        costs[globalIndex].currencyPerQuantityUnit = unit
        store.setDeal({ ...deal, fields: costs })
    }

    let isProvisional = (group: string) => group !== 'default'
    let isFinalPrice = (code: string) => ['provisional_total_amount', 'total_amount'].includes(code)
    let getGroupName = (group: string) => isProvisional(group) ? 'provisional' : 'final'

    function validationProcessEngaged(validationProcess: ValidationProcessForm | null): boolean {
        return !!validationProcess && validationProcess!.steps.findIndex(x => x === validationProcess!.status) > 0
    }

    let anyValidationProcessEngaged = (): boolean =>
        validationProcessEngaged(store?.deal?.provisionalPricesValidation ?? null)
        || validationProcessEngaged(store?.deal?.finalPricesValidation ?? null)

    function costGroupReadonly(costGroup: string) {
        return hasFeature("Pricing") || costGroupValidating(costGroup)
    }

    function costGroupValidating(costGroup: string) {
        return costGroup === 'default' && validationProcessEngaged(store?.deal?.finalPricesValidation ?? null)
            || costGroup === 'provisional' && anyValidationProcessEngaged()
    }

    return (
        <>
            <Divider className={classes.divider} />
            <div className={isProvisional(costGroup) ? classes.costGroupTitleLine : classes.costGroupTitleLine}>
                <Typography className={classes.subtitle} variant='overline'>{t('deals.label.costGroups.' + costGroup)}</Typography>
                <AttachFileButton
                    id={`upload-${getGroupName(costGroup)}-doc-button`}
                    className={classes.documentButton}
                    title={t(`deals.label.${getGroupName(costGroup)}DocumentsDialogTitle`)}
                    disableDelOrAdd={!hasClaim(Claims.DealManager) || costGroupValidating(costGroup)}
                    context={deal.company}
                    keyTemplate={`pricing-{dealId}-${costGroup}`}
                    keyParameters={{ dealId: store.dealId ? store.dealId.replace(/-/g, '') : '' }} />
            </div>
            <CostIncludedInTotal classes={classes}
                costGroup={costGroup}
                costs={costsPerGroup[costGroup]}
                changeValue={changeValue}
                isFinalPrice={isFinalPrice}
                hasAssociatedVessel={store.locked()}
                allDisabled={costGroupReadonly(costGroup)}
                dutyStatus={deal.dutyStatus ?? ''}
                costStatus={store.costStatus} />
            <CostNonIncludedInTotal classes={classes}
                costGroup={costGroup}
                costs={costsPerGroup[costGroup]}
                changeValue={changeValue}
                isFinalPrice={isFinalPrice}
                hasAssociatedVessel={store.locked()}
                allDisabled={costGroupReadonly(costGroup)}
                dutyStatus={deal.dutyStatus ?? ''}
                costStatus={store.costStatus} />
            <TotalPriceCosts
                classes={classes}
                costGroup={costGroup}
                costs={costsPerGroup[costGroup]}
                changeValue={changeValue}
                isFinalPrice={isFinalPrice}
                validationLocked={costGroupReadonly(costGroup)}
                dutyStatus={deal.dutyStatus ?? ''}
            />
        </>
    )
}

type CostBlockProps = {
    costGroup: string,
    costs: FieldState[],
    dutyStatus: string,
    changeValue: (group: string, index: number, newValue: number, unit: string) => void,
    isFinalPrice: (code: string) => boolean
    hasAssociatedVessel: boolean
    allDisabled: boolean
    costStatus: FieldStatusStore<{ [P in string]: string }>
}

let costPrefix = tPrefix + 'costs.'

function CostIncludedInTotal({ classes, costGroup, costs, changeValue, isFinalPrice, dutyStatus, allDisabled, costStatus }: CostBlockProps & MuiProps) {
    if (!costs.some(cost => cost.includedInTotal && !isFinalPrice(cost.code))) return (<></>)
    return (
        <div className={classes.wrapRow}>
            {costs.map((cost, index) =>
                cost.includedInTotal && !isFinalPrice(cost.code)
                    ? <NumberField key={costGroup + index}
                        status={costStatus.getStatus(cost.code)}
                        label={createCostLabel(costPrefix + cost.code, dutyStatus, cost.currencyPerQuantityUnit)}
                        text={cost.price}
                        disabled={cost.readOnly || allDisabled}
                        precision={cost.precision ?? 5}
                        onChange={newValue =>
                            changeValue(costGroup, index, newValue, cost.currencyPerQuantityUnit)} />
                    : null
            )}
            {
                costs.filter(x => x.includedInTotal).length > 0
                    ? <NumberField
                        label={createCostLabel(tPrefix + 'totalCost', dutyStatus, costs.filter(x => x.includedInTotal).first().currencyPerQuantityUnit)}
                        disabled={true} precision={5}
                        text={costs.filter(x => x.includedInTotal).reduce((x, cur) => x + (cur.price ?? 0), 0)} />
                    : null
            }
        </div>
    )
}

function CostNonIncludedInTotal({ classes, costGroup, costs, changeValue, isFinalPrice, allDisabled: validationLocked, dutyStatus }: CostBlockProps & MuiProps) {
    if (costs.every(cost => cost.includedInTotal || isFinalPrice(cost.code))) return (<></>)
    return (
        <div className={classes.wrapRow}>
            {costs.map((cost, index) =>
                cost.includedInTotal || isFinalPrice(cost.code)
                    ? null
                    : <NumberField key={index}
                        label={createCostLabel(costPrefix + cost.code, dutyStatus, cost.currencyPerQuantityUnit)}
                        text={cost.price}
                        disabled={cost.readOnly || validationLocked}
                        precision={cost.precision ?? 5}
                        onChange={(newValue) => {
                            if (newValue)
                                changeValue(costGroup, index, newValue, cost.currencyPerQuantityUnit)
                        }} />
            )}
        </div>
    )
}

type FinalePriceCostBlockProps = {
    costGroup: string,
    costs: FieldState[],
    dutyStatus: string,
    isFinalPrice: (code: string) => boolean,
    changeValue: (group: string, index: number, newValue: number, unit: string) => void,
    validationLocked: boolean
}

function TotalPriceCosts({ classes, costGroup, costs, changeValue, isFinalPrice, validationLocked, dutyStatus }: FinalePriceCostBlockProps & MuiProps) {
    let cost = costs.filter(x => isFinalPrice(x.code)).first()

    if (!cost) return null

    let index = costs.findIndex(x => x.code === cost?.code)
    let localeLang = localStorage.getItem(storageLanguageKey) ?? 'en'

    let formatCurrency = (val: number | null, unit: string): string =>
        val ? Intl.NumberFormat(localeLang, { style: 'currency', currency: unit, maximumFractionDigits: 5 }).format(val) : ''

    return (
        <div className={classes.wrapRow}>
            {cost.readOnly || validationLocked
                ? <div>
                    <Typography>
                        <span className={classes.finalePricePolice}>{createCostLabel(costPrefix + cost.code, dutyStatus, null) + ': '}</span>
                        <span className={classes.finalPriceValue}>{formatCurrency(cost.price, cost.currencyPerQuantityUnit!)}</span>
                    </Typography>
                </div>
                : <NumberField
                    label={createCostLabel(costPrefix + cost.code, dutyStatus, cost.currencyPerQuantityUnit)}
                    text={cost.price}
                    disabled={false}
                    precision={cost.precision ?? 5}
                    onChange={(newValue) => {
                        if (newValue)
                            changeValue(costGroup, index, newValue, cost.currencyPerQuantityUnit)
                    }} />}
        </div>
    )
}

let createCostLabel = (translationCode: string, dutyStatus: string, unit: string | null): string => {
    return dutyStatus === 'Export'
        ? [t(tPrefix + 'costs.export'), t(translationCode), unit].join(' ')
        : [t(translationCode), unit].join(' ')
}

let styles = _ =>
    createStyles({
        row: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        },
        column: {
            ...defaultStyles.flexColumn,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            rowGap: '1em'
        },
        wrapRow: {
            ...defaultStyles.flexRow,
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginLeft: '1em',
            '& > div': {
                margin: '0.5em'
            }
        },
        paperTitle: {
            color: defaultColors.red.main.color,
            marginLeft: '-0.5em'
        },
        subtitle: {
            alignSelf: 'flex-start',
            color: defaultColors.red.main.color,
            margin: '1em 0 0.5em 1em'
        },
        divider: {
            width: '100%',
            marginTop: '1em'
        },
        documentButton: {
            marginRight: '0.5em',
            marginTop: '0.5em',
            alignSelf: 'flex-end'
        },
        costGroupTitleLine: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
        },
        finalPriceValue: { marginLeft: '0.5em' },
        finalePricePolice: {
            color: defaultColors.grey.main.color,
            fontSize: '1.1em',
            fontWeight: 'bold',
            marginTop: '0.5em',
            marginLeft: '0.3em'
        },
        marginStartLine: {
            marginLeft: '0.5em'
        },
        marginBottom: {
            marginBottom: '1em'
        }
    })

export let PricingFirstLine = withStyles(styles, muiOptions)(_FirstLine)
export let PricingSecondLine = withStyles(styles, muiOptions)(_SecondLine)
export let PricingThirdLine = withStyles(styles, muiOptions)(_ThirdLine)