import React, { useState, useEffect, RefObject, useRef, useImperativeHandle } from 'react'
import { createStyles, withStyles, Paper, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core'
import { CSSProperties } from '@material-ui/styles'
import { interval, Subject, fromEvent } from 'rxjs'
import { debounce, distinctUntilChanged, switchMap, filter, withLatestFrom, map } from 'rxjs/operators'
import { Guid } from '../../infrastructure/guid'
import * as api from '../../infrastructure/api'
import { t } from '../../infrastructure/i18nextHelper'
import { ExcelLine, ExcelGeneratorContainer } from '../../infrastructure/excelExport'
import { defaultColors, defaultStyles, dialogCell, muiOptions } from '../../infrastructure/materialUiThemeProvider'
import { NumberField, DotIcon } from '../common/customComponents'
import { OpenInNewOutlined, EditOutlined, UndoOutlined } from '@material-ui/icons'
import { getQuotationStatusColor, quotationDetailsDialog, QuotationStatus } from './quotationDetailsDialog'

type PricingProps = {
    id?: Guid
    classes: any
    readOnly: boolean
}

type Change = {
    id?: Guid
    pricing: Pricing
    field: string
    value: number | null
}

function _PricingEdit({ classes, ...props }: PricingProps) {

    let [pricing, setPricing] = useState<Pricing | null>(null)
    let [changeInputs] = useState<Subject<Change>>(new Subject<Change>())
    let excelGenerator = ExcelGeneratorContainer.useContainer()

    exportRef = useRef<{ export: () => void }>(null)
    useImperativeHandle(exportRef, () => ({ export: () => exportPricing() }))

    let init = async () => {
        setPricing(null)
        await load()
    }

    let getPricing = (id) => api.get<Pricing>(`pricing/${id}`)

    let load = async () => {
        if (!props.id) return
        setPricing(await getPricing(props.id))
    }

    let onFieldChange = (field: string, value: number | null) => {
        if (!pricing) return
        changeInputs.next({ id: props.id, pricing, field, value })
    }

    let openQuotationDialog = () => {
        if (!pricing?.inputs) return

        let { quotationIndex, pricingStartDate, pricingEndDate } = pricing.inputs

        if (!quotationIndex || !pricingStartDate || !pricingEndDate) return

        quotationDetailsDialog.open({
            index: quotationIndex,
            from: pricingStartDate,
            to: pricingEndDate
        })
    }

    let deleteFieldLock = async (field: string) => {
        if (!pricing) return

        await api.del('pricing/field/lock', {
            id: pricing.id,
            fields: [field]
        })

        await load()
    }

    let setFieldEditMode = (field: string) => {
        if (!pricing) return

        const fieldIndex = pricing.fields.findIndex(x => x.name === field)

        if (fieldIndex === -1) return

        let pricingField = pricing.fields[fieldIndex]

        pricingField.readOnly = false

        setPricing({ ...pricing, fields: [...pricing.fields] })
    }

    let applyAndGet = async (change: Change) => {
        let fields = change.pricing.fields
            .filter(x => !x.readOnly && x.name != change.field)
            .map(x => ({ field: x.name, value: x.value }))
            .concat([{ field: change.field, value: change.value }])
        await api.post('pricing', { id: change.pricing.id, fields: fields })
        return { change, pricing: await getPricing(change.id) }
    }
    useEffect(() => { init() }, [props.id])
    useEffect(() => {
        let subscription = changeInputs
            .pipe(
                debounce(() => interval(500)),
                distinctUntilChanged(),
                switchMap(applyAndGet),
                withLatestFrom(changeInputs),
                filter(([{ change }, last]) => change.field == last.field && change.value == last.value),
                map(([change]) => change),
                withLatestFrom(fromEvent(document.body, 'keydown')),
                filter(([_, last]) => !((last.target as any).value as string).endsWith('.'))
            ).subscribe(([{ pricing }, _]) => setPricing(pricing))

        return () => {
            subscription.unsubscribe()
        }
    }, [])

    let getTrad = (text: string) => t('pricing.label.' + text)
    let fieldNames = pricing?.fields.filter(x => x.name.includes('quotation_average')) ?? []
    let quotationAverageField = fieldNames.length > 0 ? fieldNames[0].name : null

    let fieldLabel = (code: string) => t('pricing.field.' + code)

    let exportPricing = () => {
        if (!pricing) return
        let headerLine: ExcelLine = {
            cells: [
                { type: 'string', value: t('pricing.label.field') },
                { type: 'string', value: t('pricing.label.value') },
                { type: 'string', value: t('pricing.label.unit') }
            ]
        }
        let lines: ExcelLine[] = pricing.fields.map(x => ({
            cells: [
                { type: 'string', value: fieldLabel(x.name) },
                { type: 'number', value: x.value + '' },
                { type: 'string', value: x.unit }]
        }))

        excelGenerator.generate({
            filename: `${pricing.name.replace(/\//g, '')}.xlsx`,
            sheets: [{ name: 'Pricing', lines: [headerLine].concat(lines) }]
        })
    }

    return (
        <>
            {pricing &&
                <>
                    <TableContainer component={Paper}>
                        <Table className={classes?.table} aria-label='history table'>
                            <TableBody>
                                {pricing.fields.map((x, i) => (
                                    <TableRow className={classes.row} key={x.name}>
                                        <TableCell className={classes.labelCell} component='th' scope='row'>
                                            <div className={classes.labelContainer}>
                                                <span className={x.bold ? classes.subtotalLabel : ''}>{fieldLabel(x.name)}</span>
                                                {x.name === quotationAverageField && pricing!.inputs.pricingStartDate && pricing!.inputs.pricingEndDate &&
                                                    <IconButton className={classes.iconButton} onClick={openQuotationDialog}>
                                                        <OpenInNewOutlined />
                                                    </IconButton>}
                                            </div>
                                        </TableCell>
                                        <TableCell className={classes.valueCell}>
                                            <NumberField
                                                overrideStyle={{ root: (x.bold ? classes.boldField : classes.field) }}
                                                align={'right'} disableNewStyle
                                                disabled={pricing!.readOnly || (x.readOnly && !x.overwritten) || props.readOnly}
                                                onChange={v => onFieldChange(x.name, v)}
                                                text={x.value}
                                                allowNegative
                                                precision={x.precision ?? 5} />
                                        </TableCell>
                                        <TableCell className={x.bold ? classes.unitCellBold : classes.unitCell}>
                                            {x.unit}
                                        </TableCell>
                                        <TableCell className={classes.iconCell}>
                                            {x.name === quotationAverageField && pricing?.inputs.quotationStatus &&
                                                <DotIcon className={classes.svgMiddle} color={getQuotationStatusColor(pricing?.inputs.quotationStatus)} tooltipText={getTrad('quotation' + pricing?.inputs.quotationStatus)} />}
                                        </TableCell>
                                        <TableCell className={classes.iconCell}>
                                            {(!pricing!.readOnly && x.readOnly && !x.overwritten && !props.readOnly) &&
                                                <IconButton className={classes.editIconButton} onClick={() => setFieldEditMode(x.name)}>
                                                    <EditOutlined />
                                                </IconButton>}
                                            {(!pricing!.readOnly && x.readOnly && x.overwritten && !props.readOnly) &&
                                                <IconButton onClick={() => deleteFieldLock(x.name)}>
                                                    <UndoOutlined />
                                                </IconButton>}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <div className={classes.header}>
                            <QuantityLine classes={classes} title={getTrad('quantityTo')} quantity={pricing.inputs.quantityTo} />
                            <QuantityLine classes={classes} title={getTrad('quantityM20')} quantity={pricing.inputs.quantityM20} />
                            <QuantityLine classes={classes} title={getTrad('premium')} quantity={pricing.inputs.premium} />
                            <QuantityLine classes={classes} title={getTrad('bookingExchangeRate')} quantity={pricing.inputs.bookingExchangeRate} />
                            <QuantityLine classes={classes} title={getTrad('blQuantity')} quantity={pricing.inputs.billOfLadingQuantityTo} />
                            <QuantityLine classes={classes} title={getTrad('blQuantity')} quantity={pricing.inputs.billOfLadingReferenceQuantity} />
                            <QuantityLine classes={classes} title={getTrad('blConversionFactor')} quantity={pricing.inputs.billOfLadingConversionFactorBblTo} />
                            <QuantityLine classes={classes} title={getTrad('blConversionFactor')} quantity={pricing.inputs.billOfLadingConversionFactorToReferenceQuantity} />
                            <QuantityLine classes={classes} title={getTrad('outturnQuantity')} quantity={pricing.inputs.outturnQuantityTo} />
                            <QuantityLine classes={classes} title={getTrad('outturnQuantity')} quantity={pricing.inputs.outturnReferenceQuantity} />
                            <QuantityLine classes={classes} title={getTrad('outturnConversionFactor')} quantity={pricing.inputs.outturnConversionFactorBblTo} />
                            <QuantityLine classes={classes} title={getTrad('outturnConversionFactor')} quantity={pricing.inputs.outturnConversionFactorToReferenceQuantity} />
                        </div>
                    </TableContainer>
                </>
            }
        </>
    )
}

let exportRef: RefObject<{ export: () => void }> | null = null
export let exportPricing = {
    export: () => exportRef?.current?.export(),
}

function QuantityLine({ title, quantity, classes }: { title: string, quantity: PricingQuantity, classes: any }) {
    return (
        <Line classes={classes} title={title} value={quantity.value ? Math.round(quantity.value * 100) / 100 + '' : ''} unit={quantity.unit} />
    )
}

function Line({ classes, ...props }: { title: string, value: string | null, unit?: string, classes: any }) {
    return (
        <div className={classes.line}>
            <Typography className={classes.title}>{props.title}</Typography>
            <Typography className={classes.value}>{props.value}&nbsp;</Typography>
            <Typography className={classes.unit}>{props.unit}</Typography>
        </div>
    )
}

export type Pricing = {
    id: Guid
    name: string
    inputs: PricingInputs
    fields: PricingField[]
    readOnly: boolean
}

export type PricingInputs = {
    quotationIndex: string | null
    quotation: string | null
    quotationStatus: QuotationStatus | null
    pricingStartDate: string | null
    pricingEndDate: string | null
    quantityTo: PricingQuantity
    quantityM20: PricingQuantity
    premium: PricingQuantity
    bookingExchangeRate: PricingQuantity
    billOfLadingQuantityTo: PricingQuantity
    billOfLadingReferenceQuantity: PricingQuantity
    billOfLadingConversionFactorToReferenceQuantity: PricingQuantity
    billOfLadingConversionFactorBblTo: PricingQuantity
    outturnQuantityTo: PricingQuantity
    outturnReferenceQuantity: PricingQuantity
    outturnConversionFactorToReferenceQuantity: PricingQuantity
    outturnConversionFactorBblTo: PricingQuantity
}
export type PricingQuantity = { value: number | null, unit: string }

export type PricingField = {
    name: string
    label: string
    value: number | null
    unit: string
    bold: boolean
    readOnly: boolean
    overwritten: boolean
    order: number
    precision: number | null
}

let bold: CSSProperties = { fontWeight: 'bold' }

let inputField: CSSProperties = { color: 'black' }
let inputFieldBold: CSSProperties = { ...inputField, ...bold }
let field: CSSProperties = { '& input': inputField, width: '10em' }
let boldField: CSSProperties = { ...field, '& input': inputFieldBold }

let labelCell: CSSProperties = dialogCell
let valueCell: CSSProperties = {
    ...dialogCell,
    paddingRight: 0,
}
let iconCell: CSSProperties = {
    ...dialogCell,
    width: '2.2em',
}
let unitCell: CSSProperties = {
    ...dialogCell,
    paddingLeft: '0.5em',
    paddingRight: '0',
    color: defaultColors.grey.light.color,
}
let unitCellBold: CSSProperties = { ...unitCell, ...bold }

let styles = theme => createStyles({
    field, boldField, labelCell, valueCell, iconCell, unitCell, unitCellBold,
    header: { width: '100%', padding: '16px', fontSize: '0.875em' },
    line: { ...defaultStyles.flexRow, alignItems: 'flex-end' },
    title: { marginRight: '0.5em' },
    value: { fontWeight: 'lighter' },
    unit: {
        color: defaultColors.grey.light.color,
        fontSize: '0.875em'
    },
    labelContainer: {
        ...defaultStyles.flexRow
    },
    iconButton: {
        marginLeft: '0.3em',
        "& > svg": {
            width: '0.8em',
        }
    },
    subtotalLabel: bold,
    svgMiddle: {
        verticalAlign: 'middle'
    },
    italic: {
        fontStyle: 'italic'
    },
    comment: {
        color: 'silver',
        fontSize: '0.8em',
        fontFamily: 'Roboto',
        margin: '4em'
    },
    editIconButton: {
        visibility: 'hidden',
    },
    row: {
        "&:hover": {
            "& $editIconButton": {
                visibility: 'visible'
            }
        },
        "& $editIconButton": {
            visibility: 'hidden'
        }
    }
})


export let PricingEdit = withStyles(styles, muiOptions)(_PricingEdit)