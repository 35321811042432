import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'
import { defaultStyles, muiOptions, defaultColors, MuiProps } from '../../../../infrastructure/materialUiThemeProvider'
import { hasFeature } from '../../../../infrastructure/feature'
import { StockMovementContainer } from '../stockMovementStore'
import * as Component from './_movementComponents'
import { switcherStyle } from './_commonStyle'

function _rebrandingForm({ isIn, classes }: { isIn: boolean } & MuiProps) {
    let store = StockMovementContainer.useContainer()

    return (
        <div>
            <div className={classes.row + ' transferRow'}>
                <Component.Date isIn={isIn} />
                <Component.Company />
                <Component.DutyStatus isIn={isIn} />
                <Component.Site isIn={isIn} />
                <Component.Volume />
                {hasFeature('ObservedQuantityTemperature')
                    ? <>
                        <Component.ObservedTemperature />
                        <Component.ObservedVolume />
                    </>
                    : null}
            </div>
            <div className={classes.row}>
                <Component.Mot />
                <Component.Status />
            </div>
            <div className={classes.row + ' transferRow'}>
                <Component.ModeSwitcher
                    initiallyDualMode={true}
                    canSwitch={false}
                    forceDualMode={true}
                    originComponent={<Component.OriginProduct />}
                    destinationComponent={<Component.DestinationProduct />}
                    singleModeComponent={<></>}
                    onResetDualMode={() => undefined}
                    tooltipText={''}
                    dualModeIconClassName={classes.switchModeButton}
                    singleModeIconClassName={classes.adornment} />
                <Component.Reference />
                <Component.ExternalReference />
                {hasFeature('MovementExternalNumber') && <Component.OriginExternalNumber />}
                {hasFeature('LinkDealMovement') && <Component.LinkedDeal />}
                {store.canCreateDeal()
                    ? <Component.CreateDealButton buttonClass={classes.secondaryButton} />
                    : null}
                {store.canAssignDeal()
                    ? <Component.AssignDealButton buttonClass={classes.secondaryButton} />
                    : null}
                <Component.MirrorMovementButton buttonClass={classes.secondaryButton} />
            </div>
            <div className={classes.row}>
                <Component.Comment />
            </div>
        </div>
    )
}

let styles = () =>
    createStyles({
        ...switcherStyle,
        row: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginTop: '1em',
            marginBottom: '1em'
        },
        secondaryButton: {
            ...defaultStyles.secondaryButton,
            margin: '1em'
        }
    })

export default withStyles(styles, muiOptions)(_rebrandingForm)
