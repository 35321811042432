import React, { useState, useEffect } from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import { t } from '../../../infrastructure/i18nextHelper'
import { defaultStyles, muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'
import { api } from '../../../infrastructure/api'
import guid, { Guid } from '../../../infrastructure/guid'
import { ExcelGeneratorContainer } from '../../../infrastructure/excelExport'
import { ColumnDescriptor, TextField, NumberField, Select, TableItem } from '../../common/customComponents'
import { Company, DutyStatus, Product, LocalProduct, Site } from './models'
import { MasterDataItem, MasterDataShell, createExcelLines } from './masterDataShell'
import { MeanOfTransportation } from '../../stock/stockModels'

type SupplyOrigin = {
    id: Guid,
    company: string,
    dutyStatus: string,
    product: LocalProduct,
    originSite: string,
    supplyDestination: string,
    defaultDestinationSite: string,
    tripDurationDays: number,
    meanOfTransportation: string
}

let productZero = (): LocalProduct => {
    return {
        productId: guid.empty,
        product: ''
    }
}

let emptySupplyOrigin = (): SupplyOrigin => {
    return {
        id: guid.createNew(),
        company: '',
        dutyStatus: '',
        product: productZero(),
        originSite: '',
        supplyDestination: '',
        defaultDestinationSite: '',
        tripDurationDays: 0,
        meanOfTransportation: ''
    }
}

let toTableItem = (supplyOrigin: SupplyOrigin): TableItem<MasterDataItem<SupplyOrigin>> => {
    return {
        id: supplyOrigin.id,
        company: supplyOrigin.company,
        dutyStatus: supplyOrigin.dutyStatus,
        product: supplyOrigin.product,
        originSite: supplyOrigin.originSite,
        supplyDestination: supplyOrigin.supplyDestination,
        defaultDestinationSite: supplyOrigin.defaultDestinationSite,
        tripDurationDays: supplyOrigin.tripDurationDays,
        meanOfTransportation: supplyOrigin.meanOfTransportation,
        isModified: false
    }
}

type SupplyOriginFilters = {
    company: string | null,
    dutyStatus: string | null,
    product: string | null,
    originSite: string | null,
    supplyDestination: string | null,
    defaultDestinationSite: string | null,
    meanOfTransportation: string | null
}

type SupplyDestination = {
    code: string,
    name: string,
    company: string,
    defaultSite: string,
    sites: string[]
}

let noFilters: SupplyOriginFilters = {
    company: null,
    product: null,
    originSite: null,
    supplyDestination: null,
    defaultDestinationSite: null,
    dutyStatus: null,
    meanOfTransportation: null
}

let applyFilters = (supplyOrigin: SupplyOrigin[], filters: SupplyOriginFilters, companys: Company[], products: Product[], sites: Site[]): SupplyOrigin[] => {
    let getCompanyNameFromCode = (code: string): string => companys.find(x => x.code == code)?.name ?? ''
    let getSiteNameFromCode = (code: string): string => sites.find(x => x.code == code)?.name ?? ''

    if (filters.company)
        supplyOrigin = supplyOrigin.filter(x => x.company.toLowerCase().contains(filters.company!.toLowerCase()) ||
            getCompanyNameFromCode(x.company).toLowerCase().contains(filters.company!.toLowerCase()))
    if (filters.dutyStatus)
        supplyOrigin = supplyOrigin.filter(x => x.dutyStatus.toLowerCase().contains(filters.dutyStatus!.toLowerCase()))
    if (filters.product)
        supplyOrigin = supplyOrigin.filter(x => x.product.product.toLowerCase().contains(filters.product!.toLowerCase()))
    if (filters.originSite)
        supplyOrigin = supplyOrigin.filter(x => getSiteNameFromCode(x.originSite).toLowerCase().contains(filters.originSite!.toLowerCase()))
    if (filters.supplyDestination)
        supplyOrigin = supplyOrigin.filter(x => x.supplyDestination.toLowerCase().contains(filters.supplyDestination!.toLowerCase()))
    if (filters.defaultDestinationSite)
        supplyOrigin = supplyOrigin.filter(x => getSiteNameFromCode(x.defaultDestinationSite).toLowerCase().contains(filters.defaultDestinationSite!.toLowerCase()))
    if (filters.meanOfTransportation)
        supplyOrigin = supplyOrigin.filter(x => x.meanOfTransportation.toLowerCase().contains(filters.meanOfTransportation!.toLowerCase()))
    return supplyOrigin
}

function SupplyOriginMasterData({ classes }: MuiProps) {
    let excelGenerator = ExcelGeneratorContainer.useContainer()

    let [supplyOrigins, setSupplyOrigins] = useState<TableItem<SupplyOrigin>[]>([])
    let [companys, setCompanys] = useState<Company[]>([])
    let [products, setProducts] = useState<Product[]>([])
    let [sites, setSites] = useState<Site[]>([])
    let [dutyStatus, setDutyStatus] = useState<DutyStatus[]>([])
    let [supplyDestinations, setSupplyDestinations] = useState<SupplyDestination[]>([])
    let [filters, setFilters] = useState<SupplyOriginFilters>(noFilters)
    let [meanOfTransportations, setMeanOfTransportations] = useState<MeanOfTransportation[]>([])

    let getCompanyNameFromCode = (code: string): string => companys.find(x => x.code == code)?.name ?? ''
    let getSiteNameFromCode = (code: string): string => sites.find(x => x.code == code)?.name ?? ''

    let load = async () => {
        let companyPromise = api.get<Company[]>('masterdata/supplyOrigin/company')
        let productPromise = api.get<Product[]>('masterdata/supplyOrigin/product')
        let sitePromise = api.get<Site[]>('masterdata/supplyOrigin/site')
        let supplyDestinationPromise = api.get<SupplyDestination[]>('masterdata/supplyDestination')
        let supplyOriginPromise = api.get<TableItem<SupplyOrigin>[]>('masterdata/supplyOrigin')
        let meanOftransportationPromise = api.get<MeanOfTransportation[]>('deal/meanOfTransportation')

        let companys = await companyPromise
        setCompanys(companys)
        setDutyStatus(companys.flatMap(x => x.dutyStatuses).distinct())
        setProducts(await productPromise)
        setSites(await sitePromise)
        setSupplyDestinations(await supplyDestinationPromise)
        setSupplyOrigins(await supplyOriginPromise)
        setMeanOfTransportations(await meanOftransportationPromise)
    }

    useEffect(() => {
        let effect = async () => {
            await load()
        }
        effect()
    }, [])

    let onSave = async (item: SupplyOrigin) => {
        await api.post('masterdata/supplyOrigin', item)
        await load()
        return true
    }

    let onDelete = async (ids: string[]) => {
        await api.post('masterdata/supplyOrigin/delete', { supplyOriginIdsToDelete: ids })
        await load()
        return true
    }

    let columns: ColumnDescriptor<TableItem<MasterDataItem<SupplyOrigin>>>[] = [
        { name: t('admin.masterdata.supplyOrigin.id'), value: x => x.id, hidden: true },
        {
            name: t('admin.masterdata.supplyOrigin.company'), value: x => getCompanyNameFromCode(x.company),
            columnFilter: { value: filters.company ?? '', onChange: (company: string) => setFilters({ ...filters, company: company }) }
        },
        {
            name: t('admin.masterdata.supplyOrigin.product'), value: x => x.product.product,
            columnFilter: { value: filters.product ?? '', onChange: (product: string) => setFilters({ ...filters, product: product }) }
        },
        {
            name: t('admin.masterdata.supplyOrigin.originSite'), value: x => getSiteNameFromCode(x.originSite), valueForExport: x => x.originSite,
            columnFilter: { value: filters.originSite ?? '', onChange: (site: string) => setFilters({ ...filters, originSite: site }) }
        },
        {
            name: t('admin.masterdata.supplyOrigin.dutyStatus'), value: x => x.dutyStatus,
            columnFilter: { value: filters.dutyStatus ?? '', onChange: (dutyStatus: string) => setFilters({ ...filters, dutyStatus: dutyStatus }) }
        },
        {
            name: t('admin.masterdata.supplyOrigin.supplyDestination'), value: x => supplyDestinations.find(y => y.code == x.supplyDestination)!.name,
            columnFilter: { value: filters.supplyDestination ?? '', onChange: (supplyDestination: string) => setFilters({ ...filters, supplyDestination: supplyDestination }) }
        },
        {
            name: t('admin.masterdata.supplyOrigin.defaultDestinationSite'), value: x => x.defaultDestinationSite,
            columnFilter: { value: filters.defaultDestinationSite ?? '', onChange: (site: string) => setFilters({ ...filters, defaultDestinationSite: site }) }
        },
        {
            name: t('admin.masterdata.supplyOrigin.meanOfTransportation'), value: x => x.meanOfTransportation,
            columnFilter: { value: filters.meanOfTransportation ?? '', onChange: (mot: string) => setFilters({ ...filters, meanOfTransportation: mot }) }
        },
        {
            name: t('admin.masterdata.supplyOrigin.tripDurationDays'), value: x => x.tripDurationDays,
        }
    ]

    let getItems = () => applyFilters(supplyOrigins, filters, companys, products, sites).map(toTableItem)

    let exportExcel = () => {
        excelGenerator.generate({
            filename: 'SupplyOrigin.xlsx',
            sheets: [{ name: 'SupplyOrigin', lines: createExcelLines(getItems(), columns) }]
        })
    }

    let onImportExcel = (file: Blob) => {
        let uploadUrl = 'masterdata/supplyOrigin/import'
        api.upload(uploadUrl, file, 'import', { withReport: 'dialog' }).then(_ => load())
    }

    let isManager = hasClaim(Claims.MasterdataSupplyOriginManager)

    let getSupplyDestinationSites = (company: string, supplyDestination: string) => {
        if (!company || !supplyDestination)
            return []

        let destination = supplyDestinations.find(x => x.company === company && x.code === supplyDestination)
        return destination
            ? destination.sites.map(x => ({ value: x, text: x }))
            : []
    }

    return (
        <div className={classes.container}>
            <MasterDataShell
                tableId={'supply-origin-table'}
                headerLabel={t('admin.masterdata.supplyOrigin.supplyOrigins')}
                itemLabel={t('admin.masterdata.supplyOrigin.supplyOrigin')}
                isManager={isManager}
                onExportExcel={exportExcel}
                onImportExcel={onImportExcel}
                onNew={emptySupplyOrigin}
                onDelete={onDelete}
                onSave={onSave}
                items={getItems()}
                columns={columns}>{
                    (selectedItem, setSelectedItem) => (
                        <>
                            <Select label={t('admin.masterdata.supplyOrigin.company')}
                                disabled={!isManager}
                                value={selectedItem.company}
                                choices={companys.map(x => ({ value: x.code, text: x.name }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, company: val, supplyDestination: '' }) }} />
                            <Select label={t('admin.masterdata.supplyOrigin.product')}
                                disabled={!isManager}
                                value={selectedItem.product.productId}
                                choices={products.map(x => ({ value: x.id, text: x.code }))}
                                onChange={val => {
                                    if (val) {
                                        let product = products.find(x => x.id == val)
                                        let localProduct = product ? { productId: product.id, product: product.code } : null
                                        setSelectedItem({ ...selectedItem, product: localProduct })
                                    }
                                }} />
                            <Select label={t('admin.masterdata.supplyOrigin.dutyStatus')}
                                disabled={!isManager}
                                value={selectedItem.dutyStatus}
                                choices={dutyStatus.map(x => ({ value: x, text: x }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, dutyStatus: val }) }} />
                            <Select label={t('admin.masterdata.supplyOrigin.originSite')}
                                disabled={!isManager}
                                value={selectedItem.originSite}
                                choices={sites.map(x => ({ value: x.code, text: x.name }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, originSite: val }) }} />
                            <Select label={t('admin.masterdata.supplyOrigin.supplyDestination')}
                                disabled={!isManager}
                                value={selectedItem.supplyDestination}
                                choices={supplyDestinations.filter(x => x.company == selectedItem.company).map(x => ({ value: x.code, text: x.name }))}
                                onChange={val => { if (val) { setSelectedItem({ ...selectedItem, supplyDestination: val }) } }} />
                            <Select label={t('admin.masterdata.supplyOrigin.defaultDestinationSite')}
                                value={selectedItem.defaultDestinationSite}
                                disabled={!isManager}
                                choices={getSupplyDestinationSites(selectedItem.company, selectedItem.supplyDestination)}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, defaultDestinationSite: val }) }} />
                            <Select label={t('admin.masterdata.supplyOrigin.meanOfTransportation')}
                                disabled={!isManager}
                                value={selectedItem.meanOfTransportation}
                                choices={meanOfTransportations.map(x => ({ value: x.name, text: x.name }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, meanOfTransportation: val }) }} />
                            <NumberField label={t('admin.masterdata.supplyOrigin.tripDurationDays')}
                                text={selectedItem.tripDurationDays ? selectedItem.tripDurationDays : null}
                                onChange={x => setSelectedItem({ ...selectedItem, tripDurationDays: x })} />
                        </>
                    )}
            </MasterDataShell>
        </div >)
}

let styles = (theme) =>
    createStyles({
        container: { height: '100%' },
        booleanContainer: {
            ...defaultStyles.flexRow,
            width: '15.3em',
            justifyContent: 'space-between',
            "& >span": { padding: '0' }
        },
    })

export default withStyles(styles, muiOptions)(SupplyOriginMasterData)