import React, { useState } from 'react'
import { MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import { Select, DatePicker, NumberField } from '../../common/customComponents'
import { VesselProduct } from '../vesselModels'
import { hasFeature } from '../../../infrastructure/feature'

type FormulaPriceDataRowProps = {
    product: VesselProduct,
    quotations: { value: string, text: string }[],
    changeProduct: (product: VesselProduct) => void
}

export function FormulaPriceDataRow({ classes, product, quotations, changeProduct }: FormulaPriceDataRowProps & MuiProps) {
    return (<div className={classes.productDataRow}>
        <Select label={t('vessels.label.product.quotationCode')}
            value={product.quotationCode}
            choices={quotations}
            onChange={val => changeProduct({ ...product, quotationCode: val })} />
        {!hasFeature('VesselMultiplePricingPeriod') ?
            <DatePicker date={product.pricingStartDate}
                label={t('vessels.label.product.pricingStartDate')}
                setDate={newDate => changeProduct({ ...product, pricingStartDate: newDate })} />
            : undefined}
        {!hasFeature('VesselMultiplePricingPeriod') ?
            <DatePicker date={product.pricingEndDate}
                label={t('vessels.label.product.pricingEndDate')}
                setDate={newDate => changeProduct({ ...product, pricingEndDate: newDate })} />
            : undefined}
        {product.costingInformation && !hasFeature('VesselMultiplePremium') ?
            <NumberField label={t('vessels.label.product.premium', { unit: product.premiumCurrency })}
                onChange={val => changeProduct({ ...product, premium: val })}
                text={product.premium}
                allowNegative={true} />
            : undefined}
    </div>)
}

type FixedPriceDataRowProps = {
    productUnit: string,
    changeFixedPrice: (fixedPrice: number | null) => void,
    value: number | null
}

export function FixedPriceDataRow({ classes, productUnit, changeFixedPrice, value }: FixedPriceDataRowProps & MuiProps) {
    return (<div className={classes.productDataRow}>
        <NumberField label={t('vessels.label.purchaseMovement.fixedPrice', { unit: productUnit })}
            onChange={val => changeFixedPrice(val)}
            text={value} />
    </div>)
}
