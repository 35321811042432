import React, { useState, useEffect, KeyboardEventHandler } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import createStyles from '@material-ui/styles/createStyles'
import InputAdornment from '@material-ui/core/InputAdornment'
import MuiTextField from '@material-ui/core/TextField'
import { muiOptions, defaultColors, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import { BaseFieldProps } from '../fieldProps'
import { fieldStatuses } from '../styles'

type TextFieldStyleProps = {
    root?
}

type TextFieldProps = {
    label: string
    text: string | number | null
    defaultValue?: string
    onChange: (e) => void
    onKeyDown?: KeyboardEventHandler<any>
    inputProps?: any
    auto?: boolean
    fullWidth?: boolean
    password?: boolean
    helperText?: string
    disableNewStyle?: boolean
    hideUnderline?: boolean
    overrideStyle?: TextFieldStyleProps
} & BaseFieldProps & { [x: string]: any }

function _TextField(props: TextFieldProps & MuiProps) {
    let fixValue = (x) => x ?? props.defaultValue ?? ''
    let isReadOnly = props.disabled

    let highlight = props.status === 'info' ? props.classes.fieldStatusInfo
        : props.status === 'warning' ? props.classes.fieldStatusWarning : ''

    let variant = !props.disableNewStyle ? { variant: 'filled' } as any : {}

    return (
        <MuiTextField
            {...variant}
            className={props.hideTitle ? props.classes.hideLabel : '' + ' ' + highlight}
            classes={{
                root: props.overrideStyle?.root ?? (props.fullWidth
                    ? props.classes.textFieldDesignLine : props.classes.textFieldDesign)
            }}
            id={props.id}
            label={props.label}
            hiddenLabel={!props.label}
            InputLabelProps={{ shrink: true }}
            InputProps={Object.assign({ disableUnderline: props.hideUnderline || isReadOnly, readOnly: isReadOnly, 'data-testid': props.id }, props.inputProps)}
            error={props.status === 'alert' || !!props.onError}
            type={props.password ? 'password' : 'text'}
            value={fixValue(props.text)}
            onChange={x => { x.target.value = fixValue(x.target.value); props.onChange(x) }}
            helperText={props.status === 'alert' || props.onError ? t('components.error') : props.auto ? t('components.autoCalculated') : props.helperText ?? null}
            onKeyDown={props.onKeyDown}
            onClick={e => e.stopPropagation()}
            disabled={isReadOnly}
        />
    )
}

export type NumberFieldProps = {
    label: string,
    unit: string | null
    text: number | null
    inputProps?: any
    max?: number
    min?: number
    maxLength?: number
    auto?: boolean
    allowNegative?: boolean
    precision?: number
    errorMessage?: string
    disableNewStyle?: boolean
    hideUnderline?: boolean
    overrideStyle?: TextFieldStyleProps
    align?: 'right' | 'left' | 'center'
    size?: 'small' | 'standard'
    onChange: (e: number | null) => void
    onFocus?: () => void
    onBlur?: () => void
    onKeyDown?: KeyboardEventHandler<any>
    inputRef?: any
} & BaseFieldProps & { [x: string]: any }

let _NumberField = (props: NumberFieldProps & MuiProps) => {
    let { classes, text, allowNegative, onChange, onFocus, onBlur, overrideStyle, inputProps, disabled, onError, status, hideTitle, label } = props
    let [value, setValue] = useState<string>(text + '')

    useEffect(() => { setValue(text + '') }, [text])

    let handleUnsigned = (x) => allowNegative ? parseFloat(x) : Math.abs(parseFloat(x))
    let fixPrecision = (x: number) => props.precision != null ? parseFloat(x.toFixed(props.precision)) : x
    let minMax = (input: number) => props.min != null && input < props.min ? props.min
        : props.max != null && input > props.max ? props.max
            : input
    let fixValue = (x: number | null): number | null => {
        let value = handleUnsigned(x)
        value = fixPrecision(value)
        return !isNaN(value) ? fixPrecision(minMax(value)) : null
    }

    let lastCharIsDecimalSeparator = (text: string) => text !== '' && text?.indexOf('.') === text.length - 1
    let newValueIsDifferentFromPrevious = (text: string) => Number.parseFloat(text) !== Number.parseFloat(value)
    let lastCharIsZeroAfterDecimalSeparator = (text: string) => (text !== '' && text?.indexOf('.') !== -1 && text?.lastIndexOf('0') === text.length - 1)
    let onlyCharIsNegativeSign = (text: string) => props.allowNegative && text.length == 1 && text.indexOf('-') == 0

    let shouldRaiseChange = (text: string) =>
        !lastCharIsDecimalSeparator(text) &&
        (newValueIsDifferentFromPrevious(text) || !lastCharIsZeroAfterDecimalSeparator(text))
        && !onlyCharIsNegativeSign(text)


    let replaceComma = (text: string) => replaceAll(text, ',', '.')
    let replaceSpaces = (text: string) => replaceAll(text, ' ', '')
    let formatText = (text: string) => replaceComma(replaceSpaces(text))

    let inputText = formatText(shouldRaiseChange(value) ? (fixValue(parseFloat(value)) ?? '') + '' : value)

    let variant = !props.disableNewStyle ? { variant: 'filled' } as any : {}

    let highlightClass = status === 'info' ? classes.fieldStatusInfo : status === 'warning' ? classes.fieldStatusWarning : ''
    let sizeClass = props.size === 'standard' ? classes.standard : props.size === 'small' ? classes.small : ''

    return (
        <MuiTextField
            id={props.id}
            inputRef={props.inputRef}
            label={label}
            hiddenLabel={!props.label}
            {...variant}
            classes={{ root: overrideStyle?.root ?? classes.textFieldDesign }}
            className={`${hideTitle ? classes.hideLabel : ''} ${sizeClass} ${highlightClass}`}
            error={status === 'alert' || !!onError}
            value={inputText}
            onKeyDown={props.onKeyDown}
            onChange={x => {
                let input = formatText(x.target.value ?? '')
                setValue(props.max && parseFloat(input) > props.max
                    ? props.max + ''
                    : props.min && parseFloat(input) < props.min
                        ? props.min + ''
                        : input)
                if (shouldRaiseChange(input))
                    onChange(fixValue(parseFloat(input)))
            }}
            InputLabelProps={{ shrink: true }}
            inputProps={{ style: { textAlign: props.align ?? 'left' }, maxLength: props.maxLength ?? 10, 'data-testid': props.id }}
            InputProps={props.unit
                ? { disableUnderline: props.hideUnderline || disabled, readOnly: disabled, endAdornment: <InputAdornment position="end">{props.unit}</InputAdornment> }
                : { disableUnderline: props.hideUnderline || disabled, readOnly: disabled, ...inputProps }}
            helperText={status === 'alert' || onError
                ? props.errorMessage
                    ? props.errorMessage
                    : t('components.error')
                : props.auto
                    ? t('components.autoCalculated')
                    : null}
            onClick={e => e.stopPropagation()}
            onFocus={onFocus ?? (() => { })}
            onBlur={onBlur ?? (() => { })}
            disabled={disabled} />
    )
}

function replaceAll(text: string, search: string, replace: string) {
    return text.split(search).join(replace);
}

let styles = _ =>
    createStyles({
        textFieldDesign: {
            width: '15.3em',
            margin: '0em 0.5em',
            '& .Mui-focused:not(.Mui-disabled):before': {
                borderBottomColor: defaultColors.darkBlue.main.color,
                borderBottomStyle: 'solid',
                borderBottomWidth: '2px',
            },
            '& label.Mui-focused:not(.Mui-error)': {
                color: defaultColors.darkBlue.main.color,
            },
            "& .MuiFilledInput-root": {
                backgroundColor: 'rgba(0, 0, 0, 0.05) !important',
                "& .Mui-disabled": {
                    backgroundColor: 'white',
                    color: defaultColors.inputText
                }
            },
            "& .MuiInputBase-root": {
                "& .Mui-disabled": {
                    backgroundColor: 'white',
                    color: defaultColors.inputText
                }
            },
            "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
                fontSize: '0.75em !important',
                transform: 'translate(12px,10px) !important'
            },
            "& .MuiInputLabel-shrink": {
                fontSize: '0.75em !important',
                transform: 'translate(0px,0px) !important'
            }
        },
        textFieldDesignLine: {
            width: '98%',
            margin: '0em 0.5em',
            '& .Mui-focused:not(.Mui-disabled):before': {
                borderBottomColor: defaultColors.darkBlue.main.color,
                borderBottomStyle: 'solid',
                borderBottomWidth: '2px',
            },
            '& label.Mui-focused:not(.Mui-error)': {
                color: defaultColors.darkBlue.main.color,
            },
            "& .MuiFilledInput-root": {
                backgroundColor: 'rgba(0, 0, 0, 0.05) !important',
                "& .Mui-disabled": {
                    backgroundColor: 'white',
                    color: defaultColors.inputText
                }
            },
            "& .MuiInputBase-root": {
                "& .Mui-disabled": {
                    backgroundColor: 'white',
                    color: defaultColors.inputText
                }
            },
            "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
                fontSize: '0.75em !important',
                transform: 'translate(12px,10px) !important'
            },
            "& .MuiInputLabel-shrink": {
                fontSize: '0.75em !important',
                transform: 'translate(0px,0px) !important'
            }
        },
        hideLabel: {
            marginTop: '0',
            '& label': {
                display: 'none'
            },
            '& div': {
                marginTop: '0'
            }
        },
        standard: {
            width: '10.3em',
            margin: '0em 0.5em'
        },
        small: {
            width: '6em',
            margin: '0em 0.5em'
        },
        ...fieldStatuses
    })

export let TextField = withStyles(styles, muiOptions)(_TextField)
export let NumberField = withStyles(styles, muiOptions)(_NumberField)