import { useState, useEffect } from 'react'
import moment from 'moment'
import { createContainer } from 'unstated-next'
import * as api from '../../../infrastructure/api'
import { t } from '../../../infrastructure/i18nextHelper'
import { hasFeature } from '../../../infrastructure/feature'
import { snackbars } from '../../../infrastructure/snackbars'
import { ChannelCustomerSegment, MktSaleForecast, Site, Company, SupplyProduct, CustomerSegment, CustomerSegmentProduct } from '../models'

function useForecast() {
    let [sites, setSites] = useState<Site[]>([])
    let [companies, setCompanies] = useState<Company[]>([])
    let [products, setProducts] = useState<SupplyProduct[]>([])
    let [customerSegmentGroupedByChannel, setCustomerSegmentGroupedByChannel] = useState<{ [key: string]: ChannelCustomerSegment[] }>({})
    let [customerSegment, setCustomerSegment] = useState<{ [customerSegment: string]: { [site: string]: CustomerSegmentProduct[] } }>({})
    let [mktSaleForecast, setMktSaleForecast] = useState<MktSaleForecast>({ company: null, date: null, daysInPeriod: null, values: [], snapshotsInfos: [] })
    let [channelCustomerSegments, setChannelCustomerSegments] = useState<ChannelCustomerSegment[]>([])
    let [isSnapshotCreationDialogOpen, setIsSnapshotCreationDialogOpen] = useState<boolean>(false)
    let [isSnapshotCancellationDialogOpen, setIsSnapshotCancellationDialogOpen] = useState<boolean>(false)
    let [documentKeyDate, setDocumentKeyDate] = useState<string | null>(null)
    let [startDate, setStartDate] = useState<string | null>(null)
    let [endDate, setEndDate] = useState<string | null>(null)
    let [isSecondFortnight, setIsSecondFortnight] = useState<boolean>(false)
    let [numberOfCalendarDays, setNumberOfCalendarDays] = useState<number | null>(null)

    useEffect(() => { loadSites() }, [mktSaleForecast.company])
    useEffect(() => { setNumberOfCalendarDays(getPeriodDuration()) }, [startDate, endDate])
    useEffect(() => setDocumentKeyDate(getPeriod().start), [startDate])

    let loadSites = async () => {
        if (!mktSaleForecast?.company) {
            setSites([])
        } else {
            let sites = await api.get<Site[]>('stock/movement/mktSale/forecast/site?company=' + mktSaleForecast.company)
            setSites(sites)
        }
    }

    let loadForecastDatas = async () => {
        let companysPromise = api.get<Company[]>('stock/movement/mktSale/company')
        let channelCustomerSegmentsPromise = api.get<ChannelCustomerSegment[]>('stock/movement/mktSale/channelCustomerSegment')
        let productsPromise = api.get<SupplyProduct[]>('stock/movement/mktSale/product')

        await loadSites()
        let companies = await companysPromise
        let channelCustomerSegments = await channelCustomerSegmentsPromise
        let products = await productsPromise

        setCompanies(companies)
        setChannelCustomerSegments(channelCustomerSegments)
        setProducts(products)
    }

    let getPeriodDuration = () => {
        let { start, end } = getPeriod()
        if (!start || !end) return null
        let diff = moment(end).diff(start, 'days') + 1
        return diff > 0 ? diff : null
    }

    let isValidPeriod = () => {
        let duration = getPeriodDuration()
        return duration && duration > 0
    }

    let loadMktSales = async () => {
        if (!isValidPeriod()) return

        let { start, end } = getPeriod()
        let forecast = await api.get<MktSaleForecast>('stock/movement/mktsale/forecast',
            { company: mktSaleForecast.company, date: start, endDate: end })
        setMktSaleForecast(forecast)
    }

    let getPeriod = () => ({
        start: isSecondFortnight ? moment(startDate).add(15, 'd').format('yyyy-MM-DD') : startDate,
        end: hasFeature('MktSalesForecastByPeriod')
            ? endDate
            : hasFeature('MktSalesForecastByFortnight') && !isSecondFortnight
                ? moment(startDate).add(14, 'd').format('yyyy-MM-DD')
                : moment(startDate).add(1, 'M').add(-1, 'd').format('yyyy-MM-DD')
    })

    let isCompanyMonthFilled = () => !!mktSaleForecast && !!mktSaleForecast.company && !!startDate
    let isProductsByCustomerSegmentSet = () => Object.keys(customerSegment).length > 0
    let hasSavedOts = () => !!mktSaleForecast.snapshotsInfos?.length

    let createOtsSnapshot = async () => {
        if (isCompanyMonthFilled()) {
            let { start, end } = getPeriod()
            if (!hasFeature('MktSalesForecastShipTo'))
                await callSave()

            await api.post('stock/movement/mktsale/snapshot', { company: mktSaleForecast.company, month: start })
            snackbars.success(t('mktSales.forecast.saveSnapshotSuccess'))
            setIsSnapshotCreationDialogOpen(false)
            await loadMktSales()
        }
    }

    let cancelOtsSnapshot = async () => {
        if (isCompanyMonthFilled() && hasSavedOts()) {
            await api.del(`stock/movement/mktsale/snapshot/${mktSaleForecast.snapshotsInfos?.first().id}`)
            snackbars.success(t('mktSales.forecast.cancelSnapshotSuccess'))
            setIsSnapshotCancellationDialogOpen(false)
            await loadMktSales()
        }
    }

    let callSave = async () => {
        mktSaleForecast.date = getPeriod().start
        await api.post('stock/movement/mktsale/forecast', mktSaleForecast)
    }

    let save = async () => {
        if (!isValidPeriod()) return
        if (!isCompanyMonthFilled()) return
        await callSave()
        await loadMktSales()
        snackbars.success(t('httpSuccess.forecastSaved'))
    }

    let setDefaultValues = () => {
        let defaultVal: MktSaleForecast = { company: null, date: null, daysInPeriod: null, values: [], snapshotsInfos: [] }
        setMktSaleForecast(defaultVal)
    }

    let loadProductByCustomerSegment = async () => {
        if (!isValidPeriod()) return
        if (!isCompanyMonthFilled()) return

        let { start, end } = getPeriod()

        let customerSegmentProducts = await api.get<CustomerSegment[]>(
            `stock/movement/mktsale/customerSegmentProduct/${mktSaleForecast.company}/${start}/${end}`)

        let productsByCustomerSegment = customerSegmentProducts
            .flatMap(x => x.products.map(product => ({ customerSegment: x.code, site: x.site, product: product })))
            .groupBy(x => x.customerSegment, x => x)
            .indexValueBy(([customerSegment, _]) => customerSegment, ([_, x]) => x.indexMultipleValueBy(x => x.site, x => x.product))

        setCustomerSegment(productsByCustomerSegment)
    }

    let toggleSecondFortnight = () => setIsSecondFortnight(!isSecondFortnight)

    return {
        sites, products, customerSegmentGroupedByChannel, companies, channelCustomerSegments, isSnapshotCreationDialogOpen,
        mktSaleForecast, isSnapshotCancellationDialogOpen, productsByCustomerSegment: customerSegment, setDefaultValues, createOtsSnapshot, cancelOtsSnapshot, loadForecastDatas,
        setCustomerSegmentGroupedByChannel, loadMktSales, save, isCompanyMonthFilled, hasSavedOts, setMktSaleForecast,
        setIsSnapshotCreationDialogOpen, setIsSnapshotCancellationDialogOpen, loadProductByCustomerSegment, isProductsByCustomerSegmentSet,
        isSecondFortnight, toggleSecondFortnight, startDate, setStartDate, endDate, setEndDate, numberOfCalendarDays, documentKeyDate
    }
}

export let ForecastContainer = createContainer(useForecast)