import moment from 'moment'
import { createContainer } from 'unstated-next'
import React, { useImperativeHandle, useState, useRef, RefObject } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, createStyles, withStyles, Typography, Card, CardContent, Grid, Paper, CardActionArea, CardActions } from '@material-ui/core'
import { muiOptions, defaultStyles, defaultColors } from '../../infrastructure/materialUiThemeProvider'
import { t } from '../../infrastructure/i18nextHelper'
import * as api from '../../infrastructure/api'
import { VesselListItem } from './vesselModels'
import { Button } from '../common/customComponents'

function useCreateVesselTransportState() {
    let [open, setOpen] = useState<boolean>(false)
    let [vessels, setVessels] = useState<VesselListItem[]>([])
    let [selectedVessel, setSelectedVessel] = useState<VesselListItem | null>(null)

    return { open, setOpen, vessels, setVessels, selectedVessel, setSelectedVessel }
}

export let CreateVesselTransportContainer = createContainer(useCreateVesselTransportState)

function _CreateVesselTransport({ classes }) {
    let store = CreateVesselTransportContainer.useContainer()

    dialogRef = useRef<Open>(null)

    useImperativeHandle(dialogRef, () => ({
        open: (vessels: VesselListItem[]) => {
            store.setOpen(true)
            store.setVessels(vessels)
        }
    }))

    let selectVessel = (vessel: VesselListItem) => {
        store.setSelectedVessel(vessel)
    }

    let close = () => {
        store.setOpen(false)
        setTimeout(() => {
            store.setSelectedVessel(null)
            store.setVessels([])
        }, 500);
    }

    let confirm = async () => {
        if (!store.selectedVessel) return
        let parameter = {
            masterId: store.selectedVessel.id,
            vesselIds: store.vessels.map(x => x.id).filter(x => x !== store.selectedVessel?.id)
        }
        await api.post('vessel/transport', parameter)
        close()
    }

    return (
        <Dialog
            open={store.open}
            onClose={() => store.setOpen(false)}
            scroll={'paper'}
            aria-labelledby='scroll-dialog-title'
            aria-describedby='scroll-dialog-description'>
            <DialogTitle>Choose the reference vessel</DialogTitle>
            <DialogContent dividers={true} className={classes.noPadding + ' ' + classes.minSize}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="stretch">
                    {store.vessels.map(x =>
                        <Card key={x.id} className={classes.card + (x.id === store.selectedVessel?.id ? ' ' + classes.selectedCard : '')}
                            variant={x.id === store.selectedVessel?.id ? 'outlined' : undefined}
                            onClick={_ => selectVessel(x)}>
                            <CardActionArea>
                                <CardContent>
                                    <Typography variant="subtitle2" component="h6">{t('vessels.label.product.referenceNumber')}</Typography>
                                    <Typography variant="body2" component="p">{x.referenceNumber}&#160;</Typography>
                                    <br />
                                    <Typography variant="subtitle2" component="h6">{t('vessels.label.jetty')}</Typography>
                                    <Typography variant="body2" component="p">{x.jetty}&#160;</Typography>
                                    <br />
                                    <Typography variant="subtitle2" component="h6">{t('vessels.label.laycanDates')}</Typography>
                                    <Typography variant="body2" component="p">
                                        {formatDate(x.revisedLaycanStartDate)} {x.revisedLaycanStartDate && x.revisedLaycanEndDate ? '->' : ''} {formatDate(x.revisedLaycanEndDate)}&#160;
                                    </Typography>
                                    <br />
                                    <Typography variant="subtitle2" component="h6">{t('vessels.table.products')}</Typography>
                                    <Typography variant="body2" component="p">{x.products}&#160;</Typography>
                                    <br />
                                    <Typography variant="subtitle2" component="h6">{t('vessels.label.purchaseMovement.nominatedQuantity')}</Typography>
                                    <Typography variant="body2" component="p">{x.quantity}&#160;</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>)}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button className={classes.closeButton}
                    label={t('vessels.createVesselTransport.cancel')}
                    onClick={close}
                    color='primary' />
                <Button className={classes.confirmButton}
                    label={t('vessels.createVesselTransport.confirm')}
                    onClick={confirm}
                    color='primary' />
            </DialogActions>
        </Dialog >
    )
}

let formatDate = (date: string | null) => !!date ? moment(date).format('MM/DD') : ' '

let dialogRef: RefObject<Open> | null = null
export let createVesselTransportDialog = { open: (vessels: VesselListItem[]) => dialogRef?.current?.open(vessels) }
type Open = { open: (entity: VesselListItem[]) => void }

let styles = theme => createStyles({
    noPadding: {
        padding: '0'
    },
    minSize: {
        minWidth: '60em',
    },
    card: {
        width: '14em',
        margin: '1em 0.5em',
    },
    selectedCard: {
        backgroundColor: 'aliceblue',
    },
    closeButton: {
        ...defaultStyles.dialogCloseButton
    },
    confirmButton: {
        ...defaultStyles.dialogPrimaryButton
    }
})

export let CreateVesselTransport = withStyles(styles, muiOptions)(_CreateVesselTransport)