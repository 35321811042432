import { Dialog, DialogTitle, DialogContent, DialogActions, createStyles, withStyles } from "@material-ui/core"
import React, { RefObject, useState, useRef, useImperativeHandle, useEffect } from 'react'
import { api } from "../../infrastructure/api"
import { MuiProps, muiOptions } from "../../infrastructure/materialUiThemeProvider"
import { MktSalesContainer } from "./mktSalesStore"
import { MktSaleMovement } from "./models"
import { convertToRaw, convertFromRaw } from 'draft-js'
import { createContainer } from "unstated-next"
import { Button } from "../common/customComponents"
import MUIRichTextEditor from "mui-rte"
import { t } from '../../infrastructure/i18nextHelper'

function useMktSalesCommentDialogContainer() {
    let [mktSale, setMktSale] = useState<MktSaleMovement | null>(null)
    return { mktSale, setMktSale }
}

export let MktSalesCommentDialogContainer = createContainer(useMktSalesCommentDialogContainer)

function _MktSalesCommentDialog({ classes }: MuiProps) {
    let store = MktSalesContainer.useContainer()
    let mktSalesCommentStore = MktSalesCommentDialogContainer.useContainer()
    let [content, setContent] = useState<Draft.EditorState | null>(null)
    let [isDialogOpened, setIsDialogOpened] = useState<boolean>(false)
    let [comment, setComment] = useState<string | null>(null)

    useEffect(() => {
        let showDialog = !!mktSalesCommentStore.mktSale
        setComment(mktSalesCommentStore.mktSale?.comment ?? null)
        setIsDialogOpened(showDialog)
    }, [mktSalesCommentStore.mktSale])

    let onSave = async () => {
        if (!content) return

        let currentContent = JSON.stringify(convertToRaw(content.getCurrentContent()))
        let comment = isContentEmpty(currentContent) ? null : currentContent

        await api.post('stock/movement/mktsale/comment', {
            mktSaleId: mktSalesCommentStore.mktSale!.id,
            comment: comment
        })
        await store.loadMktSaleMovements()
        close()
    }

    let isContentEmpty = (content: string | undefined) => {
        if (content) {
            let parsedComment = convertFromRaw(JSON.parse(content))
            let plainTxt = parsedComment.getPlainText()
            return !plainTxt
        }
        return true
    }

    let close = () => mktSalesCommentStore.setMktSale(null)

    return (
        <Dialog
            open={isDialogOpened}
            onClose={close}
            scroll={'paper'}
            aria-labelledby='scroll-dialog-title'
            aria-describedby='scroll-dialog-description'>
            <DialogTitle className={classes.dialogContainer}>
                <div className={classes.popupHeader}> {t('comment.dialogTitle')} </div>
            </DialogTitle>
            <DialogContent className={classes.dialogContentContainer} dividers={true}>
                <div className={classes.contentContainer}>
                    <MUIRichTextEditor
                        defaultValue={comment}
                        onChange={e => setContent(e)}
                        inheritFontSize={true}
                        label={t('comment.placeHolder')}
                        controls={[]} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button className={classes.closeButton}
                    label={t('comment.closeButton')}
                    onClick={close}
                    color='primary' />
                <Button className={classes.saveButton}
                    label={t('comment.saveButton')}
                    onClick={onSave}
                    color='primary' />
            </DialogActions>
        </Dialog>)
}
let styles = () =>
    createStyles({
        dialogContentContainer: {
            paddingTop: '0px',
            paddingBottom: '10px'
        },
        contentContainer: { minHeight: '10em', width: '45em' }
    })

export let MktSalesCommentDialog = withStyles(styles, muiOptions)(_MktSalesCommentDialog)