import React, { useState, useEffect } from 'react'
import { withStyles, createStyles, Paper, Typography } from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { Edit, Save } from '@material-ui/icons'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { muiOptions, MuiProps, defaultStyles, defaultColors } from '../../infrastructure/materialUiThemeProvider'
import { t } from '../../infrastructure/i18nextHelper'
import * as api from '../../infrastructure/api'
import { Claims } from '../../infrastructure/signIn/models'
import { hasClaim } from '../../infrastructure/signIn/userContext'
import { navigateTo } from '../../infrastructure/navigation'
import { hasFeature } from '../../infrastructure/feature'
import { snackbars } from '../../infrastructure/snackbars'
import { queryStringBuilder } from '../../infrastructure/queryStringBuilder'
import { formatFilename } from '../../infrastructure/excelExport'
import { Button, SearchField, CustomDialog } from '../common/customComponents'
import { VesselEditDialogContainer, vesselDialog } from './vesselEdit/vesselEditStore'
import { CreateVesselTransport, CreateVesselTransportContainer } from './createVesselTransport'
import VesselTable from './vesselTable'
import { VesselContainer } from './vesselStore'
import { VesselFilters } from './_vesselFilters'
import { VesselListItem } from './vesselModels'

function VesselList({ classes }: MuiProps) {
    let createVesselTransportContainer = CreateVesselTransportContainer.useContainer()
    let store = VesselContainer.useContainer()
    let vesselEdit = VesselEditDialogContainer.useContainer()

    let openCreatePopup = () => vesselDialog.open()

    let onQueryChanged = (e) => {
        if (e.key === 'Enter') return onValidatedQuery()
        else store.setSearchQuery(e.target.value)
    }

    let saveFiltersInLocalStorage = () => {
        let filtersLength = Object.keys(store.filters).length

        if (filtersLength > 0)
            localStorage.setItem('vesselFilters', JSON.stringify(store.filters))
    }

    let onValidatedQuery = () => store.setFilters({ ...store.filters, query: store.searchQuery })

    let exportVessels = async () => {
        let filename = formatFilename('Vessel.xlsx')
        await api.post(`vessel/export`, { filename: filename, filters: store.filters, vesselIds: store.selectedVessels.map(x => x.id) })

        let url = `${api.url}download/${filename}`

        window.open(url, '_blank')
    }

    let simulateVessels = () => {
        if (store.selectedVessels.some(x => !x.availabilityDate)) {
            snackbars.warning(t('vessels.simulation.errors.availabilityDateNotValid'))
            return
        }
        let query = queryStringBuilder({ ids: store.selectedVessels.map(x => x.id) }, 'repeat')
        store.selectedVessels.length > 0 && navigateTo(`/vessel/simulate${query}`)
    }

    let shouldDisplaySimulateButton = () => {
        if (store.selectedVessels == null) return
        let isInRange = store.selectedVessels.length > 0 && store.selectedVessels.length < 5
        return isInRange && hasFeature('VesselSimulation')
    }

    let setFilterPerimeter = (newValue: 'assigned' | 'country' | 'all') =>
        store.setFilters({ ...store.filters, perimeter: newValue })

    useEffect(() => { if (!createVesselTransportContainer.open) store.loadVessels() }, [createVesselTransportContainer.open])
    useEffect(() => { if (!vesselEdit.isOpen) store.loadVessels() }, [vesselEdit.isOpen])

    useEffect(() => {
        saveFiltersInLocalStorage()
        store.loadVessels()
    }, [store.filters])

    let deleteVessels = async (vesselOnly: boolean) => {
        store.setDeleteVesselConfirmIsOpen(false)
        store.deleteVessels(store.toDeleteVessels, vesselOnly)
    }

    let tradbase = hasFeature('VesselAutoCreateDeal') ? 'vessels.table.confirmDelete.autoCreateDealOn' : 'vessels.table.confirmDelete.autoCreateDealOff'

    return (
        <div className={classes.pageContainer}>
            <div className={classes.firstRow}>
                <Typography className={classes.pageTitle} variant='h5' display='block' gutterBottom>{t('vessels.name')}</Typography>
                <div>
                    <VesselFilters />
                </div>
            </div>

            <div className={classes.tableRow}>
                <Paper className={classes.root}>
                    <div className={classes.tableHead}>
                        <div className={classes.titleSwitchRow}>
                            <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>{t('vessels.table.title')}</Typography>
                            <ToggleButtonGroup
                                exclusive
                                value={store.filters.perimeter}
                                onChange={(_, newValue) => { setFilterPerimeter(newValue) }}>
                                <ToggleButton value={'all'} size={'small'} classes={{ selected: classes.perimeterSelected }}>
                                    {t('vessels.label.allVessels')}
                                </ToggleButton>
                                <ToggleButton value={'assigned'} size={'small'} classes={{ selected: classes.perimeterSelected }}>
                                    {t('vessels.label.myVessels')}
                                </ToggleButton>
                                {hasFeature("CountryVesselPerimeter") &&
                                    <ToggleButton value={'country'} size={'small'} classes={{ selected: classes.perimeterSelected }}>
                                        {t('vessels.label.countryVessels')}
                                    </ToggleButton>}
                            </ToggleButtonGroup>
                        </div>
                        <div className={classes.rightButtonsContainer}>
                            <SearchField label={t('vessels.table.search')} onChange={onQueryChanged} onClick={onValidatedQuery} text={store.searchQuery} />
                            {shouldDisplaySimulateButton() &&
                                <Button onClick={simulateVessels} label={t('vessels.table.simulate')} className={classes.secondaryButton} />
                            }
                            {(hasClaim(Claims.VesselManager) || hasClaim(Claims.VesselWriter))
                                ? <Button
                                    className={classes.secondaryButton}
                                    label={t(`vessels.table.${!store.tableEditMode ? 'edit' : 'save'}`)}
                                    img={!store.tableEditMode ? (<Edit />) : (<Save />)}
                                    onClick={() => store.tableEditMode ? store.saveTableEditItems() : store.setTableEditMode(!store.tableEditMode)} />
                                : null}
                            <Button onClick={exportVessels} label={t('vessels.table.export')} img='/static/images/excel_red.svg' className={classes.secondaryButton} />
                            {hasClaim(Claims.VesselManager)
                                ? <Button
                                    label={t('vessels.table.createNew')}
                                    img={<Icons.Add />}
                                    onClick={openCreatePopup}
                                    className={classes.primaryButton} />
                                : null}
                        </div>
                    </div>
                    <VesselTable loadVessels={store.loadVessels} />
                </Paper>
                <CustomDialog isOpen={store.deleteVesselConfirmIsOpen} yesNo
                    title={t('vessels.table.confirmDelete.title')}
                    contentText={t(tradbase + '.content')}
                    content2Text={t(tradbase + '.content2')}
                    yesButtonText={t(tradbase + '.yes')}
                    noButtonText={t('vessels.table.confirmDelete.no')}
                    onYes={() => deleteVessels(false)}
                    onNo={() => deleteVessels(true)}
                    onCancel={() => store.setDeleteVesselConfirmIsOpen(false)}
                    onClose={() => store.setDeleteVesselConfirmIsOpen(false)}
                />
                <CreateVesselTransport />
            </div>
        </div >
    )
}

let styles = () =>
    createStyles({
        pageTitle: {
            color: defaultColors.grey.dark.color,
            alignSelf: 'flex-start'
        },
        paperTitle: {
            color: defaultColors.red.main.color,
            marginRight: '3em'
        },
        tableHead: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0.2em 1%',
            width: '98%',
            whiteSpace: 'nowrap'
        },
        titleSwitchRow: {
            ...defaultStyles.flexRow,
        },
        primaryButton: {
            ...defaultStyles.primaryButton
        },
        secondaryButton: {
            ...defaultStyles.secondaryButton
        },
        row: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            alignItems: 'stretch',
            padding: '1em'
        },
        firstRow: {
            ...defaultStyles.flexRow,
            alignItems: 'stretch',
            justifyContent: 'space-between',
            'grid-area': 'firstRow'
        },
        tableRow: {
            ...defaultStyles.flexRow,
            alignItems: 'stretch',
            justifyContent: 'space-between',
            flexGrow: 1,
            'grid-area': 'secondRow'
        },
        root: {
            flexGrow: 2,
            width: '100%',
            minHeight: '25em',
            overflow: 'auto'
        },
        rightButtonsContainer: {
            '& > *': {
                marginRight: '1em',
                '&:first-child': {
                    marginRight: '3em'
                },
                '&:last-child': {
                    marginRight: '0em'
                }
            }
        },
        pageContainer: {
            display: 'grid',
            height: '100%',
            paddingBottom: '0.5em',
            'grid-template-areas': "'firstRow' 'secondRow'",
            'grid-template-columns': 'auto',
            'grid-template-rows': '4em minmax(0, 1fr)',
            'grid-gap': '0.8em',
            'overflow-y': 'hidden',
            'box-sizing': 'border-box',
            '&> div': {
                height: '100%',
                width: '100%'
            }
        },
        perimeterSelected: {
            "&&": {
                backgroundColor: defaultColors.lightBlue.main.color,
                color: 'white',
                '&:hover': {
                    backgroundColor: defaultColors.lightBlue.light.color,
                },
            }
        }
    })

export default withStyles(styles, muiOptions)(VesselList)