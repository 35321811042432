import { useState } from 'react'
import moment from 'moment'
import { createContainer } from 'unstated-next'
import { Guid } from '../../infrastructure/guid'
import { VesselListItem, Jetty, VesselFilter, VesselErrorDates, TableVesselListItem, VesselEditChangedDate, EditedVesselItem } from './vesselModels'
import { api } from '../../infrastructure/api'

export const defaultStartDate = () => moment.utc().startOf('month').format()
export const defaultEndDate = () => moment.utc().startOf('month').add(1, 'M').add(-1, 'day').format()

function useVessel() {
    let [vessels, setVessels] = useState<TableVesselListItem[]>([])
    let [selectedVessels, setSelectedVessels] = useState<TableVesselListItem[]>([])
    let [vesselTransportHighlighted, setVesselTransportHighlighted] = useState<Guid | null>(null)
    let [vesselErrorDates, setVesselErrorDates] = useState<VesselErrorDates[]>([])
    let [jetties, setJetties] = useState<Jetty[]>([])
    let [products, setProducts] = useState<{ id: string, code: string }[]>([])
    let [filters, setFilters] = useState<VesselFilter>(overrideWithSavedFilters({ start: defaultStartDate(), end: defaultEndDate(), perimeter: 'all' }))
    let [searchQuery, setSearchQuery] = useState<string>('')
    let [tableEditMode, setTableEditMode] = useState<boolean>(false)
    let [editedVesselItems, setEditedVesselItems] = useState<EditedVesselItem[]>([])
    let [filterDatesOnError, setFilterDatesOnError] = useState<boolean>(false)
    let [deleteVesselConfirmIsOpen, setDeleteVesselConfirmIsOpen] = useState<boolean>(false)
    let [toDeleteVessels, setToDeleteVessels] = useState<VesselListItem[]>([])

    function overrideWithSavedFilters(baseFilter: VesselFilter): VesselFilter {
        let filters = localStorage.getItem('vesselFilters')
        if (!filters) return baseFilter

        let filterObj: VesselFilter = JSON.parse(filters)
        let filtersLength = Object.keys(filterObj).length

        if (filtersLength === 0) return baseFilter

        let parseArray = x => !Array.isArray(x) || x.length == 0 ? null : x

        return {
            ...baseFilter,
            jetty: parseArray(filterObj.jetty),
            productId: parseArray(filterObj.productId)
        }
    }

    let checkDates = () => { if (datesHasErrors()) setFilterDatesOnError(true) }
    let datesHasErrors = () => {
        if (!filters.start || !filters.end) return true

        let startDate = moment(filters.start)
        let endDate = moment(filters.end)
        let limitDate = moment("12/31/2018")

        if (startDate.format('MM/DD/YYYY') === endDate.format('MM/DD/YYYY')) return false

        return startDate.isAfter(endDate) || startDate.isBefore(limitDate)
    }

    type VesselFiltersDateProps = Pick<VesselFilter, 'start' | 'end'>
    let changeDate = (newDate: string | null, prop: keyof VesselFiltersDateProps) => {
        let vesselFilters: VesselFilter = { ...filters }
        vesselFilters[prop] = newDate

        if (prop === 'start') {
            let endDate = moment(filters.end)
            if (moment(newDate).isAfter(endDate))
                vesselFilters = { ...vesselFilters, end: moment(newDate).endOf('month').format('MM/DD/YYYY') }
        }
        else {
            let startDate = moment(filters.start)
            if (moment(newDate).isBefore(startDate))
                vesselFilters = { ...vesselFilters, start: moment(newDate).startOf('month').format('MM/DD/YYYY') }
        }
        setFilters(vesselFilters)
        setFilterDatesOnError(false)
    }

    let loadVessels = async () => {
        let query = ''
        let filterProperties = Object.keys(filters)
        let initializedFilters = filterProperties.filter(x => (filters[x] !== null && x !== 'productId' && x !== 'jetty'))

        initializedFilters.forEach(x => query += `${x}=${filters[x]}&`)
        filters.jetty?.forEach(x => query += `jetty=${x}&`)
        filters.productId?.forEach(x => query += `productId=${x}&`)

        let vessels = await api.get<TableVesselListItem[]>('vessel?' + query)
        setVessels(vessels)
        setVesselErrorDates(getErrorDates(vessels))
        checkDates()
    }

    let getErrorDates = (vesselItems: VesselListItem[]) => {
        let vesselsGroupedByJetty = vesselItems.indexMultipleValueByProp('jetty')
        let vesselErrorDates: VesselErrorDates[] = []

        Object.values(vesselsGroupedByJetty).forEach(vessels => {
            vesselErrorDates.push({ vesselId: vessels[0].id, isEtbOnError: false, isCodOnError: false })
            if (vessels.length > 1) {
                for (let i = 1; i < vessels.length; i++) {
                    let previousVessel = vessels[i - 1]
                    let currentVessel = vessels[i]
                    if (!!previousVessel.berthingDate && !!previousVessel.completionOfDischargeDate
                        && !!currentVessel.berthingDate && !!currentVessel.completionOfDischargeDate
                        && moment(currentVessel.berthingDate).isSameOrAfter(previousVessel.berthingDate)
                        && moment(currentVessel.berthingDate).isBefore(previousVessel.completionOfDischargeDate)) {
                        vesselErrorDates.push({ vesselId: currentVessel.id, isEtbOnError: true, isCodOnError: true })
                    }
                    else if (!!previousVessel.berthingDate && !!currentVessel.berthingDate
                        && moment(currentVessel.berthingDate).isSame(previousVessel.berthingDate)) {
                        vesselErrorDates.push({ vesselId: currentVessel.id, isEtbOnError: true, isCodOnError: false })
                    }
                    else { vesselErrorDates.push({ vesselId: currentVessel.id, isEtbOnError: false, isCodOnError: false }) }
                }
            }
        })
        return vesselErrorDates
    }

    let unlinkVessels = async (selectedVessels: VesselListItem[]) => {
        if (selectedVessels.length === 0) return
        await api.patch('vessel/transport', { removeVesselIds: selectedVessels.map(x => x.id) })
        await loadVessels()
    }

    let deleteVessels = async (vessels: VesselListItem[], vesselOnly: boolean) => {
        if (vessels.length === 0) return

        let request = { vesselIds: vessels.map(x => x.id), vesselOnly: vesselOnly }
        await api.post('vessel/batchDelete', request)
        await loadVessels()
    }

    let updateTableVesselListItem = async (line: TableVesselListItem) => {
        let index = vessels.findIndex(x => x.id === line.id)

        vessels[index] = line
        setVessels([...vessels])
    }

    let saveTableEditItems = async () => {

        for (let index = 0; index < editedVesselItems.length; index++) {
            const element = editedVesselItems[index];
            await api.post('vessel/date', element)
        }

        setTableEditMode(false)
        await loadVessels()
    }

    return {
        vessels, setVessels,
        selectedVessels, setSelectedVessels,
        jetties, setJetties,
        products, setProducts,
        filters, setFilters,
        searchQuery, setSearchQuery,
        vesselTransportHighlighted, setVesselTransportHighlighted,
        vesselErrorDates, setVesselErrorDates, loadVessels,
        deleteVessels, unlinkVessels, changeDate,
        tableEditMode, setTableEditMode, updateTableVesselListItem,
        editedVesselItems, setEditedVesselItems, saveTableEditItems,
        filterDatesOnError, deleteVesselConfirmIsOpen,
        setDeleteVesselConfirmIsOpen, setToDeleteVessels, toDeleteVessels
    }
}

export let VesselContainer = createContainer(useVessel)