import { MovementForm, SapFlow, ReleaseIndicator } from '../../../../stockModels'
import { StockMovementStore } from '../../../stockMovementStore'
import { t } from '../../../../../../infrastructure/i18nextHelper'

export type SapTypes = 'sto' | 'stoDn' | 'stoGoodsIssue' | 'migo' | 'directTransfer' | 'rebranding309' | 'purchase501' | 'gains' | 'losses' | 'poMigo' | 'poRelease'

type FormRequirements = {
    [P in SapTypes]: { [P in keyof MovementForm]?: 'filled' | 'empty' }
}

export const formRequirements: (isMainSapFlow: boolean) => FormRequirements = (isMainSapFlow: boolean) => {
    return {
        sto: {
            company: 'filled',
            destinationProductId: 'filled',
            volume: 'filled',
            destinationDutyStatus: 'filled',
            stockInputDate: 'filled',
            ...(isMainSapFlow ? { stockOutputDate: 'filled' } : {}),
            meanOfTransportation: 'filled',
            originSite: 'filled',
            destinationSite: 'filled',
        },
        stoDn: {},
        stoGoodsIssue: {},
        migo: {
            stockInputDate: 'filled',
            stockOutputDate: 'filled',
        },
        directTransfer: {
            reference: 'filled',
            destinationDutyStatus: 'filled',
            destinationProductId: 'filled',
            volume: 'filled',
            company: 'filled',
            originSite: 'filled',
            destinationSite: 'filled',
        },
        rebranding309: {
            stockInputDate: 'filled',
            reference: 'filled',
            destinationDutyStatus: 'filled',
            volume: 'filled',
            originProductId: 'filled',
            destinationProductId: 'filled',
            destinationSite: 'filled',
            company: 'filled',
        },
        purchase501: {
            reference: 'filled',
            destinationDutyStatus: 'filled',
            destinationSite: 'filled',
            destinationProductId: 'filled',
            volume: 'filled',
            counterpartyId: 'filled',
            company: 'filled'
        },
        poRelease: {
        },
        gains: {
            company: 'filled',
            reference: 'filled',
            destinationSite: 'filled',
            destinationDutyStatus: 'filled',
            destinationProductId: 'filled',
            volume: 'filled',
            stockInputDate: 'filled',
        },
        losses: {
            company: 'filled',
            reference: 'filled',
            destinationSite: 'filled',
            destinationDutyStatus: 'filled',
            destinationProductId: 'filled',
            volume: 'filled',
            stockOutputDate: 'filled',
        },
        poMigo: {
            stockInputDate: 'filled',
            volume: 'filled',
            reference: 'filled',
        }
    }
}

export const sapFlowRequirements: { [P in SapTypes]: { [P in keyof SapFlow]?: 'filled' | 'empty' } } = {
    sto: {
        originPlant: 'filled',
        originStorageLocation: 'filled',
        destinationPlant: 'filled',
        destinationStorageLocation: 'filled',
        referenceOrder: 'empty',
        deliveryNote: 'empty',
        goodsIssue: 'empty',
        materialDocument: 'empty'
    },
    stoDn: {
        referenceOrder: 'filled',
        deliveryNote: 'empty',
        goodsIssue: 'empty',
        materialDocument: 'empty'
    },
    stoGoodsIssue: {
        referenceOrder: 'filled',
        deliveryNote: 'filled',
        goodsIssue: 'empty',
        materialDocument: 'empty'
    },
    migo: {
        referenceOrder: 'filled',
        deliveryNote: 'filled',
        materialDocument: 'empty',
        goodsIssue: 'filled'
    },
    directTransfer: {
        originPlant: 'filled',
        destinationPlant: 'filled',
        originStorageLocation: 'filled',
        destinationStorageLocation: 'filled',
        materialDocument: 'empty'
    },
    rebranding309: {
        originPlant: 'filled',
        originStorageLocation: 'filled',
        destinationStorageLocation: 'filled',
        materialDocument: 'empty'
    },
    purchase501: {
        destinationPlant: 'filled',
        destinationStorageLocation: 'filled',
        referenceOrder: 'empty',
    },
    poRelease: {
        referenceOrder: 'filled',
        deliveryNote: 'empty'
    },
    gains: {
        destinationPlant: 'filled',
        destinationStorageLocation: 'filled',
        materialDocument: 'empty'
    },
    losses: {
        destinationPlant: 'filled',
        destinationStorageLocation: 'filled',
        materialDocument: 'empty'
    },
    poMigo: {
        referenceOrder: 'filled',
        destinationPlant: 'filled',
        destinationStorageLocation: 'filled',
        materialDocument: 'empty'
    }
}

export function isValidated(type: SapTypes, sapFlow: SapFlow) {
    return (type == 'poRelease' && isPurchaseOrderValidated(sapFlow))
}

export function isEnabled(type: SapTypes, form: MovementForm, sapFlow: SapFlow) {
    let mvtRequirements = formRequirements(form.movementType == sapFlow.movementType)[type]
    let sapRequirements = sapFlowRequirements[type]

    if (type == 'poRelease' && isPurchaseOrderValidated(sapFlow))
        return false

    if (type == 'poMigo' && !isPurchaseOrderValidated(sapFlow))
        return false

    return (
        form.movementStatus != 0
        && Object.keys(mvtRequirements).every(prop =>
            mvtRequirements[prop] === 'filled' && form[prop] != null && form[prop] !== '' ||
            mvtRequirements[prop] === 'empty' && (form[prop] == null || form[prop] === ''))
        && Object.keys(sapRequirements).every(prop =>
            sapRequirements[prop] === 'filled' && sapFlow[prop] != null && sapFlow[prop] !== '' ||
            sapRequirements[prop] === 'empty' && (sapFlow[prop] == null || sapFlow[prop] === ''))
    )
}

export function isDisplayable(type: SapTypes, featureContainer, hasAssociatedDeal?: boolean) {
    let isNeeded = hasAssociatedDeal === undefined ? false : isAssociatedDealNeeded(type)
    return hasTheRequiredFeatures(type, featureContainer) && (!isNeeded || hasAssociatedDeal)

    function hasTheRequiredFeatures(type: SapTypes, featureContainer): boolean {
        switch (type) {
            case 'purchase501': return featureContainer.hasFeature('SapPoNbCreate')
            case 'poRelease': return featureContainer.hasFeature('SapPoRelease')
            case 'rebranding309': return featureContainer.hasFeature('SapGm309')
            case 'gains': return featureContainer.hasFeature('SapGmGain')
            case 'losses': return featureContainer.hasFeature('SapGmLosses')
            case 'sto': return featureContainer.hasFeature('SapPoUb')
            case 'stoDn': return featureContainer.hasFeature('SapDnSto')
            case 'stoGoodsIssue': return featureContainer.hasFeature('SapGiSto') && featureContainer.hasFeature('GoodsIssueOutsideDeliveryNote')
            case 'migo': return featureContainer.hasFeature('SapGmMigo')
            case 'directTransfer': return featureContainer.hasFeature('SapGmDirectTransfer')
            case 'poMigo': return featureContainer.hasFeature('SapPoGmMigo')
        }
    }

    function isAssociatedDealNeeded(type: SapTypes) {
        switch (type) {
            case 'purchase501':
            case 'poRelease':
            case 'poMigo': return true
            default: return false
        }
    }
}

export function highlightRequiredFields(type: SapTypes, isMainSapFlow: boolean, store: StockMovementStore) {
    let mvtRequirements = formRequirements(isMainSapFlow)[type]
    let sapRequirements = sapFlowRequirements[type]

    let mvtRequirementsKeys = Object.keys(mvtRequirements)
    let sapRequirementsKeys = Object.keys(sapRequirements)

    store.formErrors.clear()

    let mvtFieldsToShowAsInfo = mvtRequirementsKeys.filter(key => mvtRequirements[key] === 'filled')
    store.formErrors.movementFieldStatus.setStatus(mvtFieldsToShowAsInfo, 'info')

    let mvtFieldsToShowAsWarning = mvtRequirementsKeys.filter(key => mvtRequirements[key] === 'empty')
    store.formErrors.movementFieldStatus.setStatus(mvtFieldsToShowAsWarning, 'warning')

    let sapFieldsToShowAsInfo = sapRequirementsKeys.filter(key => sapRequirements[key] === 'filled')
    store.getSapFlowFieldStatus(isMainSapFlow).setStatus(sapFieldsToShowAsInfo, 'info')

    let sapFieldsToShowAsWarning = sapRequirementsKeys.filter(key => sapRequirements[key] === 'empty')
    store.getSapFlowFieldStatus(isMainSapFlow).setStatus(sapFieldsToShowAsWarning, 'warning')
}

export function getTrad(key: string) {
    return t(`stock.label.movement.sapAction.${key}`)
}

export function getSapLabel(type: SapTypes, sapFlow: SapFlow) {
    if (type == 'poRelease'
        && isPurchaseOrderValidated(sapFlow))
        return getTrad('validatedPoButton')
    return getTrad(type + 'Button')
}

export function getSapTooltip(type: SapTypes, sapFlow: SapFlow) {
    if (type == 'poRelease'
        && isPurchaseOrderValidated(sapFlow))
        return getTrad('validatedPoButtonTooltip')
    return getTrad(type + 'ButtonTooltip')
}

export function isPurchaseOrderValidated(sapFlow: SapFlow): boolean {
    return referenceOrderExist(sapFlow.referenceOrder) && !needValidation(sapFlow.releaseIndicator)

    function needValidation(releaseIndicator: ReleaseIndicator) {
        return releaseIndicator == "ToBeValidated" || releaseIndicator == null
    }

    function referenceOrderExist(referenceOrder: string | null): boolean { return referenceOrder != null && referenceOrder !== '' }
}