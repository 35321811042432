import React, { useEffect, useState } from 'react'
import { createStyles, withStyles, Typography, Table, TableHead, TableBody, TableRow, TableCell, TableFooter } from '@material-ui/core'
import { NumberField } from '../../common/customComponents'
import { muiOptions, defaultStyles, defaultColors, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { hasChannelSalesManagerClaimWithScope, hasClaim } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'
import { t } from '../../../infrastructure/i18nextHelper'
import { ChannelCustomerSegment, CustomerSegmentProduct, MktSaleForecast, MktSaleForecastValue, Site, SupplyProduct } from '../models'
import { hasFeature } from '../../../infrastructure/feature'

type CustomerSegmentFormProps = {
    channelCustomerSegment: ChannelCustomerSegment
    products: { [site: string]: CustomerSegmentProduct[] }
    sites: Site[]
    mktSaleForecast: MktSaleForecast
    setMarketSalesForecastValue: (index: number, newValue: MktSaleForecastValue) => void
    setMktSaleForecast: (forecast: MktSaleForecast) => void
} & MuiProps

let CustomerSegmentTable = ({ classes, channelCustomerSegment, products, sites,
    mktSaleForecast, setMarketSalesForecastValue, setMktSaleForecast }: CustomerSegmentFormProps) => {
    let [productValues, setProductValues] = useState<CustomerSegmentProduct[]>([])
    let [siteNames, setSiteNames] = useState<string[]>([])

    useEffect(() => {
        if (products) {
            setProductValues(Object.values(products)[0])
            setSiteNames(Object.keys(products))
        }
    }, [products])

    let onVolumeChange = (value, productId, siteCode) => {
        let mktSalesForecastValue: MktSaleForecastValue = {
            perimeter: {
                channel: channelCustomerSegment.channel,
                customerSegment: channelCustomerSegment.customerSegment,
                productId: productId,
                site: siteCode,
            },
            volume: value
        }

        let index = mktSaleForecast.values?.findIndex(x =>
            x.perimeter.customerSegment === channelCustomerSegment.customerSegment
            && x.perimeter.productId === productId
            && x.perimeter.site === siteCode
            && x.perimeter.channel === channelCustomerSegment.channel)

        if (index !== undefined && index > -1)
            setMarketSalesForecastValue(index, mktSalesForecastValue)
        else {
            let val: MktSaleForecastValue[] = mktSaleForecast.values ?? []
            val.push(mktSalesForecastValue)
            setMktSaleForecast({ ...mktSaleForecast, values: val })
        }
    }

    let onDayVolumeChange = (value, productId, siteCode, periodCoeff) =>
        onVolumeChange(value * periodCoeff, productId, siteCode)

    let getVolume = (productId, siteCode) => {
        let result = mktSaleForecast.values?.first(x =>
            x.perimeter.customerSegment === channelCustomerSegment.customerSegment
            && x.perimeter.productId === productId
            && x.perimeter.site === siteCode
            && x.perimeter.channel === channelCustomerSegment.channel)?.volume
        if (result !== null && result !== undefined)
            return result
        else
            return null
    }

    let isManagerWithScope = (customerSegment: string | null) => hasClaim(Claims.SalesManager) &&
        hasChannelSalesManagerClaimWithScope(customerSegment)

    let getVolumePerDay = (volume: number | null, coeff: number) =>
        volume !== null && !isNaN(volume) && coeff ? Math.round(volume / coeff) : null

    let sitesWithStock = sites?.filter(x => !!x.isSellingPoint && products[x.code] && products[x.code].some(p => p.hasStock))

    return (products &&
        <Table key={channelCustomerSegment.customerSegment} className={classes.tableMargin}>
            <TableHead className={classes.tableHead}>
                <TableRow>
                    <TableCell align={'center'}>
                        <Typography className={classes.pageTitle} variant="h5" display="block" gutterBottom>{channelCustomerSegment.customerSegment}</Typography>
                    </TableCell>
                    {productValues.map((x: CustomerSegmentProduct) =>
                        <TableCell key={x.code} align={'center'}>
                            <Typography variant="h6" display="block" gutterBottom>
                                {x.code}
                            </Typography>
                            {t("mktSales.forecast.sellingDays")} {x.periodCoeff}
                        </TableCell>)
                    }
                </TableRow>
                <TableRow>
                    <TableCell></TableCell>
                    {productValues.map((x: CustomerSegmentProduct) =>
                        <TableCell key={x.code}>
                            <div className={classes.doubleCell}>
                                <div className={classes.cellSizeLeft}>
                                    <Typography className={classes.cellTextLeft} variant="h6" display="block" gutterBottom>{t("mktSales.forecast.forecastQty", { unit: x.unit })}</Typography>
                                </div>
                                <div className={classes.cellSizeRight}>
                                    <Typography className={classes.cellTextRight} variant="h6" display="block" gutterBottom>{t("mktSales.forecast.qtyPerDay")}</Typography>
                                </div>
                            </div>
                        </TableCell>)
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {sitesWithStock.map(site =>
                    <TableRow key={site.code} className={classes.tableRow}>
                        <TableCell align={'center'} component='th' scope='row' size='small'>
                            <Typography variant="h6" display="block" gutterBottom>{site.name}</Typography>
                        </TableCell>
                        {(products[site.code]).map(y => {
                            let volume = getVolume(y.id, site.code)
                            let currentSiteProduct = products[site.code]?.find(x => x.id == y.id) ?? y
                            let dayVolume = getVolumePerDay(volume, currentSiteProduct.periodCoeff) ?? ''

                            if (!y.hasStock)
                                return (<TableCell key={y.code}></TableCell>)
                            else
                                return (<TableCell key={y.code}>
                                    <div className={classes.doubleCell}>
                                        <div className={classes.cellSizeLeft}>
                                            {isManagerWithScope(channelCustomerSegment.customerSegment)
                                                ? <NumberField disableNewStyle
                                                    disabled={hasFeature('MktSalesForecastShipTo')}
                                                    onChange={v => onVolumeChange(v, y.id, site.code)}
                                                    size={'small'}
                                                    text={volume} />
                                                : volume !== null ? Math.round(volume).toString() : null}
                                        </div>
                                        <div className={classes.cellSizeRight}>
                                            {isManagerWithScope(channelCustomerSegment.customerSegment)
                                                ? <NumberField
                                                    disabled={hasFeature('MktSalesForecastShipTo')}
                                                    disableNewStyle
                                                    onChange={v => onDayVolumeChange(v, y.id, site.code, y.periodCoeff)}
                                                    size={'small'}
                                                    text={dayVolume} />
                                                : dayVolume !== null ? dayVolume : null}
                                        </div>
                                    </div>
                                </TableCell>)
                        })}
                    </TableRow>)
                }
            </TableBody>
            {sitesWithStock.length > 1 &&
                <TableFooter className={classes.footer}>
                    <TableRow key={'sum'} className={classes.tableRow}>
                        <TableCell key={'cell'}></TableCell>
                        {productValues.map((product: CustomerSegmentProduct, index: number) => {
                            let sum = siteNames.map((site: string) => {
                                let volume = getVolume(product.id, site)
                                let currentSiteProduct = products[site]?.find(x => x.id == product.id) ?? product
                                let dayVolume = getVolumePerDay(volume, currentSiteProduct.periodCoeff)

                                return { volume: volume, dayVolume: dayVolume }
                            }).reduce((x, acc) => {
                                return {
                                    volume: (acc.volume ?? 0) + (x.volume ?? 0),
                                    dayVolume: (acc.dayVolume ?? 0) + (x.dayVolume ?? 0)
                                }
                            })
                            return <TableCell key={index}>
                                <div className={classes.doubleCell}>
                                    <div className={classes.footerSum}>
                                        <b>{sum.volume}</b>
                                        {`(${t('components.table.sum')})`}
                                    </div>
                                    <div className={classes.footerSum}>
                                        <b>{sum.dayVolume}</b>
                                        {`(${t('components.table.sum')})`}
                                    </div>
                                </div>
                            </TableCell>
                        })}
                    </TableRow>
                </TableFooter>
            }
        </Table >
    )
}

let styles = theme =>
    createStyles({
        tableMargin: { margin: '2em 0em 2em 0em' },
        tableHead: {
            backgroundColor: '#fafafa',
            '& th:first-child': {
                width: '20em',
                maxWidth: '20em',
            }
        },
        tableRow: {
            '&>th:first-child': {
                width: '20em',
                maxWidth: '20em',
            }
        },
        doubleCell: {
            display: 'flex',
            justifyContent: 'space-around',
        },
        cellTextLeft: { textAlign: 'left', marginLeft: '1em' },
        cellTextRight: { textAlign: 'right', marginRight: '1em' },
        cellSizeLeft: { width: '100%', textAlign: "left", marginLeft: '1em' },
        cellSizeRight: { width: '100%', textAlign: "right", marginRight: '1em' },
        nbDayText: {
            textAlign: 'center',
            fontWeight: "bold",
            fontSize: '1.2em',
            maxWidth: "100%"
        },
        pageTitle: {
            margin: "1em",
            color: defaultColors.red.main.color,
            fontWeight: 'bold',
        },
        row: {
            ...defaultStyles.flexRow,
            alignItems: 'stretch',
            justifyContent: 'space-between',
            margin: '0.2em 1em',
            height: '3.2em'
        },
        secondaryButton: { ...defaultStyles.secondaryButton },
        footer: {
            ...theme.overrides.MuiTableCell.footer,
            position: 'relative'
        },
        footerSum: { width: '100%', textAlign: "left" },
    })

export default withStyles(styles, muiOptions)(CustomerSegmentTable)