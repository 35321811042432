import React from 'react'
import {
    Accordion, AccordionSummary, AccordionDetails, Divider,
    Typography, createStyles, withStyles
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import moment from 'moment'
import { MuiProps, muiOptions, defaultStyles, defaultColors } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import { hasFeature } from '../../../infrastructure/feature'
import { NumberField, TextField } from '../../common/customComponents'
import { CostingInformation } from '../vesselCostingInformationModels'
import { VesselEditContainer } from './vesselEditStore'
import { DemurrageTimestampPicker, CostNumberField, CostSelect, CostDatePicker } from './_costingInformationComponents'

let tPrefix = 'vessels.label.product.costingInformation.'
function KenyaCostingInformation({ costingInformation, productUnit, classes }: { costingInformation: CostingInformation, productUnit: string } & MuiProps) {
    let store = VesselEditContainer.useContainer()

    let createCostLabel = (translationCode: string, unit: string | null): string => {
        return [t(translationCode), unit].join(" ")
    }

    let updateCostingInformation = (...props: { prop: keyof CostingInformation, value: number | boolean | string | null }[]) => {
        let newCostingInformation = costingInformation
        props.forEach(x => newCostingInformation = { ...newCostingInformation, [x.prop]: x.value })
        store.setCostingInformation(newCostingInformation.vesselProductId, newCostingInformation)
    }

    let formatDate = (date: string) => moment(date).format('MM/DD/YYYY')

    let isBlockAuthorized = (...fields: (keyof CostingInformation)[]): boolean => fields.reduce((acc, curr) => acc || store.isAuthorized(curr), false)

    let regulatedPricePeriods = (): { value: string, text: string }[] => {
        let productId = store.state?.products.find(x => x.id == costingInformation.vesselProductId)?.productId
        if (!productId) return []

        return store.regulatedPricePeriods
            .filter(x => x.productId == productId)
            .map(x => { return { value: x.id, text: `${x.name} : ${formatDate(x.startDate)} -> ${formatDate(x.endDate)}` } })
    }

    return (
        <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.panel}>
            <AccordionSummary expandIcon={<ExpandMore />} className={classes.summary}>
                <Typography className={classes.title} variant='overline'>{t(tPrefix + 'title')}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <div className={classes.row} style={{ marginBottom: '1em' }}>
                    <TextField label={t('vessels.label.comment')} multiline fullWidth
                        text={costingInformation.comment}
                        onChange={event => updateCostingInformation({ prop: 'comment', value: event.target.value })} />
                </div>
                <div className={classes.row}>
                    <div className={classes.demurrageFields}>
                        <DemurrageTimestampPicker demurrageMinutes={costingInformation.demurrageMinutes}
                            overrided={costingInformation.demurrageMinutesOverwritten}
                            onChange={value => updateCostingInformation(
                                { prop: 'demurrageMinutes', value: value },
                                { prop: 'demurrageMinutesOverwritten', value: !!value })
                            }
                            classes={classes} />
                        <NumberField label={t(tPrefix + 'demurrageRate')}
                            onChange={(newValue) => { updateCostingInformation({ prop: 'demurrageRate', value: newValue }) }}
                            text={costingInformation.demurrageRate} />
                        {hasFeature('VesselDemurragePerUnit') ?
                            <NumberField label={t(tPrefix + 'demurragePerUnit', { unit: productUnit })}
                                onChange={(newValue) => {
                                    updateCostingInformation(
                                        { prop: 'demurragePerUnit', value: newValue },
                                        { prop: 'demurragePerUnitOverwritten', value: !!newValue })
                                }}
                                text={costingInformation.demurragePerUnit}
                                auto={!costingInformation.demurragePerUnitOverwritten} /> : null}
                    </div>
                </div>
                {store.regulatedPricePeriods?.length
                    ? <div className={classes.row}>
                        <CostSelect label={t(tPrefix + 'regulatedPricePeriod')}
                            prop={'regulatedPricePeriodId'}
                            onChange={updateCostingInformation}
                            costingInformation={costingInformation}
                            choices={regulatedPricePeriods()}
                            isAuthorized={store.isAuthorized} />
                    </div>
                    : undefined}
                <div className={classes.row}>
                    <CostNumberField label={t(tPrefix + 'customProcessingFees')}
                        prop={'customProcessingFees'}
                        costingInformation={costingInformation}
                        onChange={updateCostingInformation}
                        disabled={costingInformation.isLocked || costingInformation.isProvisionalLocked}
                        isAuthorized={store.isAuthorized} />
                    <CostNumberField label={t(tPrefix + 'tbsChargesFees')}
                        prop={'tbsChargesFees'}
                        costingInformation={costingInformation}
                        onChange={updateCostingInformation}
                        disabled={costingInformation.isLocked || costingInformation.isProvisionalLocked}
                        isAuthorized={store.isAuthorized} />
                    <CostNumberField label={t(tPrefix + 'tasacFees')}
                        prop={'tasacFees'}
                        costingInformation={costingInformation}
                        onChange={updateCostingInformation}
                        disabled={costingInformation.isLocked || costingInformation.isProvisionalLocked}
                        isAuthorized={store.isAuthorized} />
                    <CostNumberField label={t(tPrefix + 'wmaFees')}
                        prop={'wmaFees'}
                        costingInformation={costingInformation}
                        onChange={updateCostingInformation}
                        disabled={costingInformation.isLocked || costingInformation.isProvisionalLocked}
                        isAuthorized={store.isAuthorized} />
                </div>
                <div className={classes.row}>
                    <CostDatePicker label={t(tPrefix + 'industryDemurrageStart')}
                        onChange={updateCostingInformation}
                        prop={'industryDemurragePeriodStart'}
                        costingInformation={costingInformation}
                        isAuthorized={store.isAuthorized} />
                    <CostDatePicker label={t(tPrefix + 'industryDemurrageEnd')}
                        onChange={updateCostingInformation}
                        prop={'industryDemurragePeriodEnd'}
                        costingInformation={costingInformation}
                        isAuthorized={store.isAuthorized} />
                </div>
                <div className={classes.row}>
                    <CostDatePicker label={t(tPrefix + 'industryProductStart')}
                        onChange={updateCostingInformation}
                        prop={'industryProductPeriodStart'}
                        costingInformation={costingInformation}
                        isAuthorized={store.isAuthorized}
                        disabled auto />
                    <CostDatePicker label={t(tPrefix + 'industryProductEnd')}
                        onChange={updateCostingInformation}
                        prop={'industryProductPeriodEnd'}
                        costingInformation={costingInformation}
                        isAuthorized={store.isAuthorized}
                        disabled auto />
                </div>
                <div className={classes.row}>
                    <CostNumberField disabled auto
                        label={t(tPrefix + 'tklGlobal')}
                        prop={'tklGlobalVolume'}
                        costingInformation={costingInformation}
                        onChange={updateCostingInformation}
                        isAuthorized={store.isAuthorized} />
                    <CostNumberField disabled auto
                        label={t(tPrefix + 'tklLocal')}
                        prop={'tklLocalVolume'}
                        costingInformation={costingInformation}
                        onChange={updateCostingInformation}
                        isAuthorized={store.isAuthorized} />
                    <CostNumberField disabled auto
                        label={t(tPrefix + 'gklGlobal')}
                        prop={'gklGlobalVolume'}
                        costingInformation={costingInformation}
                        onChange={updateCostingInformation}
                        isAuthorized={store.isAuthorized} />
                    <CostNumberField disabled auto
                        label={t(tPrefix + 'gklLocal')}
                        prop={'gklLocalVolume'}
                        costingInformation={costingInformation}
                        onChange={updateCostingInformation}
                        isAuthorized={store.isAuthorized} />
                </div>
                {isBlockAuthorized('industryLandedCost', 'industryDemurrage', 'industryHospitality', 'industryFinancing', 'industryTaxAndLevies') &&
                    <Divider className={classes.divider} />}
                <>
                    <div className={classes.row}>
                        <CostNumberField label={t(tPrefix + 'industryLanded')}
                            prop={'industryLandedCost'}
                            costingInformation={costingInformation}
                            onChange={updateCostingInformation}
                            isAuthorized={store.isAuthorized} />
                        <CostNumberField label={t(tPrefix + 'industryDemurrage')}
                            prop={'industryDemurrage'}
                            costingInformation={costingInformation}
                            onChange={updateCostingInformation}
                            isAuthorized={store.isAuthorized} />
                        <CostNumberField label={t(tPrefix + 'industryHospitality')}
                            prop={'industryHospitality'}
                            costingInformation={costingInformation}
                            onChange={updateCostingInformation}
                            isAuthorized={store.isAuthorized} />
                        <CostNumberField label={t(tPrefix + 'industryFinancing')}
                            prop={'industryFinancing'}
                            costingInformation={costingInformation}
                            onChange={updateCostingInformation}
                            isAuthorized={store.isAuthorized} />
                        <CostNumberField label={t(tPrefix + 'industryTaxAndLevies')}
                            prop={'industryTaxAndLevies'}
                            costingInformation={costingInformation}
                            onChange={updateCostingInformation}
                            isAuthorized={store.isAuthorized} />
                    </div>
                </>
                {isBlockAuthorized('provisionalLandedCost', 'provisionalDemurrage', 'provisionalFinancing', 'provisionalTaxAndLevies') &&
                    <>
                        <Divider className={classes.divider} />
                        <Typography className={classes.subtitle} variant='overline'>{t(tPrefix + 'provisionalPricetitle')}</Typography>
                    </>
                }
                <div className={classes.row}>
                    <CostNumberField label={createCostLabel(tPrefix + 'landedCost', costingInformation.localVolumeUnit)}
                        prop={'provisionalLandedCost'}
                        costingInformation={costingInformation}
                        onChange={updateCostingInformation}
                        disabled={costingInformation.isLocked || costingInformation.isProvisionalLocked}
                        isAuthorized={store.isAuthorized} />
                    <CostNumberField label={createCostLabel(tPrefix + 'demurrage', costingInformation.localVolumeUnit)}
                        prop={'provisionalDemurrage'}
                        costingInformation={costingInformation}
                        onChange={updateCostingInformation}
                        disabled={costingInformation.isLocked || costingInformation.isProvisionalLocked}
                        isAuthorized={store.isAuthorized} />
                    <CostNumberField label={createCostLabel(tPrefix + 'financing', costingInformation.localVolumeUnit)}
                        prop={'provisionalFinancing'}
                        costingInformation={costingInformation}
                        onChange={updateCostingInformation}
                        disabled={costingInformation.isLocked || costingInformation.isProvisionalLocked}
                        isAuthorized={store.isAuthorized} />
                    <CostNumberField label={createCostLabel(tPrefix + 'provisional_tax_and_levies', costingInformation.localVolumeUnit)}
                        prop={'provisionalTaxAndLevies'}
                        costingInformation={costingInformation}
                        onChange={updateCostingInformation}
                        disabled={costingInformation.isLocked || costingInformation.isProvisionalLocked}
                        isAuthorized={store.isAuthorized} />
                </div>
                <div className={classes.row}>
                    <CostNumberField label={createCostLabel(tPrefix + 'exportLandedCost', costingInformation.globalVolumeUnit)}
                        prop={'provisionalExportLandedCost'}
                        costingInformation={costingInformation}
                        onChange={updateCostingInformation}
                        disabled={costingInformation.isLocked || costingInformation.isProvisionalLocked}
                        isAuthorized={store.isAuthorized} />
                    <CostNumberField label={createCostLabel(tPrefix + 'exportDemurrage', costingInformation.globalVolumeUnit)}
                        prop={'provisionalExportDemurrageCost'}
                        costingInformation={costingInformation}
                        onChange={updateCostingInformation}
                        disabled={costingInformation.isLocked || costingInformation.isProvisionalLocked}
                        isAuthorized={store.isAuthorized} />
                </div>
                {isBlockAuthorized('localLandedCost', 'localDemurrage', 'localFinancing', 'localTaxAndLevies') &&
                    <>
                        <Divider className={classes.divider} />
                        <Typography className={classes.subtitle} variant='overline'>{t(tPrefix + 'finalPricetitle')}</Typography>
                    </>
                }
                <div className={classes.row}>
                    <CostNumberField label={createCostLabel(tPrefix + 'landedCost', costingInformation.localVolumeUnit)}
                        prop={'localLandedCost'}
                        costingInformation={costingInformation}
                        onChange={updateCostingInformation}
                        disabled={costingInformation.isLocked}
                        isAuthorized={store.isAuthorized} />
                    <CostNumberField label={createCostLabel(tPrefix + 'demurrage', costingInformation.localVolumeUnit)}
                        prop={'localDemurrage'}
                        costingInformation={costingInformation}
                        onChange={updateCostingInformation}
                        disabled={costingInformation.isLocked}
                        isAuthorized={store.isAuthorized} />
                    <CostNumberField label={createCostLabel(tPrefix + 'financing', costingInformation.localVolumeUnit)}
                        prop={'localFinancing'}
                        costingInformation={costingInformation}
                        onChange={updateCostingInformation}
                        disabled={costingInformation.isLocked}
                        isAuthorized={store.isAuthorized} />
                    <CostNumberField label={createCostLabel(tPrefix + 'tax_and_levies', costingInformation.localVolumeUnit)}
                        prop={'localTaxAndLevies'}
                        costingInformation={costingInformation}
                        onChange={updateCostingInformation}
                        disabled={costingInformation.isLocked}
                        isAuthorized={store.isAuthorized} />
                </div>
                <div className={classes.row}>
                    <CostNumberField label={createCostLabel(tPrefix + 'exportLandedCost', costingInformation.globalVolumeUnit)}
                        prop={'globalLandedCost'}
                        costingInformation={costingInformation}
                        onChange={updateCostingInformation}
                        disabled={costingInformation.isLocked}
                        isAuthorized={store.isAuthorized} />
                    <CostNumberField label={createCostLabel(tPrefix + 'exportDemurrage', costingInformation.globalVolumeUnit)}
                        prop={'globalDemurrage'}
                        costingInformation={costingInformation}
                        onChange={updateCostingInformation}
                        disabled={costingInformation.isLocked}
                        isAuthorized={store.isAuthorized} />
                </div>
            </AccordionDetails>
        </Accordion >)
}

let styles = _ =>
    createStyles({
        panel: {
            margin: '2em 0px'
        },
        details: { ...defaultStyles.flexColumn },
        demurrageFields: { display: 'flex' },
        demurrageMinutesDiv: { ...defaultStyles.flexRow },
        demurrageMinutesHelper: {
            alignSelf: 'flex-start',
            marginLeft: '0.6em'
        },
        demurrageMinutesField: {
            width: '7em',
            '& input': { textAlign: 'center' },
        },
        demurrageTimeRow: {
            ...defaultStyles.flexRow,
            alignItems: 'baseline',
        },
        row: {
            ...defaultStyles.flexRow,
            margin: '0.5em 0px',
            alignItems: 'baseline',
            width: '100%',
            flexWrap: 'wrap'
        },
        title: {
            color: defaultColors.red.main.color
        },
        subtitle: {
            alignSelf: 'flex-start',
            color: defaultColors.red.main.color,
            marginTop: '1em'
        },
        divider: {
            width: '100%',
            marginTop: '1em'
        },
        comment: {
            width: '98%',
            margin: '0em 0.5em'
        },
        tooltipContainer: {
            height: '100%',
            alignSelf: 'flex-start'
        }
    })

export default withStyles(styles, muiOptions)(KenyaCostingInformation)