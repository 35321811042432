import React, { useState, useEffect } from 'react'
import * as Icons from '@material-ui/icons'
import { defaultStyles, muiOptions, defaultColors } from './../../infrastructure/materialUiThemeProvider'
import { withStyles, createStyles, Paper, Typography, Tooltip, Chip, Grid } from '@material-ui/core'
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'
import guid, { Guid } from '../../infrastructure/guid'
import { DataTable, ColumnDescriptor, CustomDialog, Button } from '../common/customComponents'
import { api, get, post } from '../../infrastructure/api'
import {
    DealListItem,
    DealMovementType,
    SearchFields,
    DutyStatus,
    Deal,
    DuplicateDealCommand,
    DealPricingType,
    GetMovementType
} from './dealModels'
import { t } from '../../infrastructure/i18nextHelper'
import { DealContainer, DealDialogContainer } from './dealEdit/dealEditStore'
import { SearchField, DatePicker, MultipleSelect, Switch, MonthRangePicker, GroupedMultiSelect } from '../common/customComponents'
import { historyDialog } from '../common/history/dialog'
import moment from 'moment'
import { popupNavigator } from '../../infrastructure/popupNavigator'
import { hasFeature } from '../../infrastructure/feature'
import { ActionDescriptor, GroupDescriptor } from '../common/components/table/table'
import { DealPricing, exportDealList } from './dealListExporter'
import { hasClaim } from '../../infrastructure/signIn/userContext'
import { Claims } from '../../infrastructure/signIn/models'
import { Pricing } from '../pricings/pricing'
import { ExcelGeneratorContainer } from '../../infrastructure/excelExport'
import { useActionDebounceWithArgs } from '../common/debounce'
import { MovementMotIcon, MovementTypeIcon } from '../stock/_movementIcon'

let emptySearchFields = (): SearchFields => ({
    sites: [],
    companies: [],
    counterparties: [],
    products: [],
    dealAssignees: [],
    dealTypes: [],
    statuss: [],
    moTs: []
})

let toTableItem = (dealItem: DealListItem): DealListItem => {
    return {
        id: dealItem.id,
        type: dealItem.type,
        movementType: dealItem.movementType,
        referenceNumber: dealItem.referenceNumber,
        dutyStatus: dealItem.dutyStatus,
        company: dealItem.company,
        counterparty: dealItem.counterparty,
        validFrom: dealItem.validFrom,
        validTo: dealItem.validTo,
        productCode: dealItem.productCode,
        volume: dealItem.volume,
        site: dealItem.site,
        nextValidator: dealItem.nextValidator,
        contractReference: dealItem.contractReference,
        meanOfTransportation: dealItem.meanOfTransportation,
        signed: dealItem.signed,
        generateMktSaleForecasts: dealItem.generateMktSaleForecasts,
        assigneeUserName: dealItem.assigneeUserName,
        currency: dealItem.currency,
        das: dealItem.das,
        dealConsumption: dealItem.dealConsumption,
        dealNumber: dealItem.dealNumber,
        exchangeNumber: dealItem.exchangeNumber,
        frontOfficer: dealItem.frontOfficer,
        movementQuantity: dealItem.movementQuantity,
        overTolerance: dealItem.overTolerance,
        overToleranceQuantity: dealItem.overToleranceQuantity,
        pricingType: dealItem.pricingType,
        quantity: dealItem.quantity,
        quantityPriceUnit: dealItem.quantityPriceUnit,
        sapHandlingType: dealItem.sapHandlingType,
        sapPlant: dealItem.sapPlant,
        sapQuantity: dealItem.sapQuantity,
        sapValuationType: dealItem.sapValuationType,
        sapVendor: dealItem.sapVendor,
        shipTo: dealItem.shipTo,
        status: dealItem.status,
        termSpot: dealItem.termSpot,
        toleranceOption: dealItem.toleranceOption,
        underTolerance: dealItem.underTolerance,
        underToleranceQuantity: dealItem.underToleranceQuantity,
        unit: dealItem.unit
    }
}

type DealListFilters = {
    referenceNumber: string | null
}

let noFilters: DealListFilters = {
    referenceNumber: null
}

function DealList({ classes }) {
    let [searchFields, setSearchFields] = useState<SearchFields>(emptySearchFields)
    let [dutyStatuses, setDutyStatuses] = useState<DutyStatus[]>([])
    let [deleteDealConfirmIsOpen, setDeleteDealConfirmIsOpen] = useState<boolean>(false)
    let [deleteDealSimpleConfirmIsOpen, setDeleteDealSimpleConfirmIsOpen] = useState<boolean>(false)
    let [toDeleteDeals, setToDeleteDeals] = useState<DealListItem[]>([])
    let [searchQuery, setSearchQuery] = useState<string>('')
    let [dealItems, setDealItems] = useState<DealListItem[]>([])
    let [selectedDeals, setSelectedDeals] = useState<DealListItem[]>([])
    let [duplicateConfirmDialogOpen, setDuplicateConfirmDialogOpen] = useState<boolean>(false)
    let [currentDeal, setCurrentDeal] = useState<DealListItem | null>(null)
    let [dealListFilters, setDealListFilters] = useState<DealListFilters>(noFilters)

    let dealContainer = DealDialogContainer.useContainer()
    let dealStore = DealContainer.useContainer()
    let excelGenerator = ExcelGeneratorContainer.useContainer()

    const termSpot = ['Term', 'Spot']
    const signedNotSigned = ['Signed', 'Not Signed']

    let filters = dealStore.filters

    let getItems = () => applyFilters(dealItems).map(toTableItem)

    let openPopup = (targetId: Guid, pricingType?: DealPricingType) => {
        popupNavigator.open('deal', targetId, { pricingType: pricingType })
    }

    let loadDeals = async () => {
        let filtersQuery = constructFiltersQuery()
        let route = filtersQuery ? `deal?${filtersQuery}` : 'deal'
        setDealItems(await get<DealListItem[]>(route))
    }

    let loadDealSearchFields = async () => {
        let searchFields = await get<SearchFields>('deal/searchField')
        setSearchFields(searchFields)

        let dutyStatuses = searchFields.companies.map(x => x.dutyStatuses).reduce((a, b) => a.concat(b)).distinct()
        setDutyStatuses(dutyStatuses)
    }

    let saveFiltersInLocalStorage = () => {
        let filtersLength = Object.keys(filters).length

        if (filtersLength > 0)
            localStorage.setItem('dealFilters', JSON.stringify(filters))
    }

    let changeDate = (newDate: string | null, key) => {
        dealStore.setFilters((prevFilters) => ({
            ...prevFilters,
            [key]: newDate
        }))
    }

    let constructFiltersQuery = () => {
        let filtersToApply = filters
        let query = ''
        let arrays = ['dealTypes', 'movementTypes', 'termSpot',
            'companys', 'productIds', 'sites', 'moTs',
            'dutyStatuss', 'counterpartyIds', 'statuss',
            'signedNotSigned', 'dealAssignees', 'frontOfficers']

        let filterProperties = Object.keys(filtersToApply).filter(x => arrays.indexOf(x) === -1)
        let initializedFilters = filterProperties.filter(x =>
            (filtersToApply[x] !== undefined && filtersToApply[x] !== null))

        initializedFilters.map(x => query = query + `${x}=${filtersToApply[x]}&`)

        arrays.forEach(prop => {
            if (filtersToApply[prop] && filtersToApply[prop].length > 0)
                filtersToApply[prop].map(value => query = query + `${prop}=${value}&`)
        })

        return query
    }

    let applyFilters = (deals: DealListItem[]): DealListItem[] => {
        if (dealListFilters.referenceNumber)
            deals = deals.filter(x => x.referenceNumber?.toLowerCase().contains(dealListFilters.referenceNumber!.toLowerCase()))
        return deals
    }

    let onSelect = async (deal: DealListItem) => {
        openPopup(deal.id)
    }

    let onDeleteDeals = (selectedDeals: DealListItem[]) => {
        setToDeleteDeals(selectedDeals)

        let shouldNotAllowMovementToStay = hasFeature('BatchDailyMovementCreationFromDeal') &&
            selectedDeals.some(x => x.meanOfTransportation === 'Road' && x.movementType === DealMovementType.PURCHASE || x.generateMktSaleForecasts)

        if (shouldNotAllowMovementToStay)
            setDeleteDealSimpleConfirmIsOpen(true)
        else
            setDeleteDealConfirmIsOpen(true)
    }

    let deleteDeals = async (deleteMovements: boolean) => {
        setDeleteDealConfirmIsOpen(false)
        setDeleteDealSimpleConfirmIsOpen(false)
        await post('deal/batchDelete', { dealIds: toDeleteDeals.map(x => x.id), deleteMovements: deleteMovements })
        await loadDeals()
    }

    useEffect(() => {
        if (!dealContainer.isOpen)
            loadDeals()
    }, [dealContainer.isOpen])

    useEffect(() => {
        saveFiltersInLocalStorage()
        loadDeals()
        loadDealSearchFields()
        setSearchQuery(filters.query ?? '')
    }, [filters])

    let onValidatedQuery = () => dealStore.setFilters({ ...filters, query: searchQuery })

    let onQueryChanged = (e) => {
        if (e.key === 'Enter')
            return onValidatedQuery()
        else
            setSearchQuery(e.target.value)
    }

    let prepareDealExport = async () => {
        let dealsToExport = selectedDeals.length > 0 ? selectedDeals : dealItems
        let allDeals = await Promise.all(dealsToExport.map(x => api.get<Deal>(`deal/${x.id}`)))

        let dealPricings: DealPricing[] = await Promise.all(allDeals.map(async (item): Promise<DealPricing> => {
            let pricing = await api.get<Pricing>(`pricing/${item.id}`)

            return { deal: item, pricing: pricing }
        }))

        exportDealList(dealPricings, dealsToExport, excelGenerator.generate)
    }

    let duplicateDeal = async (selectedDeal: DealListItem): Promise<void> => {
        let command: DuplicateDealCommand = {
            sourceId: selectedDeal.id,
            duplicateId: guid.createNew()
        }
        await dealStore.duplicateDeal(command);
        openPopup(command.duplicateId)
    }

    let handleDuplicateDebouncer = useActionDebounceWithArgs(duplicateDeal)

    let formatFromToDate = (date: Date | null): string => !!date ? moment(date).format("MM/DD/YY") : ''
    let roundNumber = (value: number | null): string => value ? Math.round(value).toString() : ''

    let hasOneCompany = searchFields.companies.map(x => x.code).distinct().length === 1

    let totalQuantity = dealItems.reduce((acc, curr) => acc + (curr.quantity ?? 0), 0)
    let totalVolume = dealItems.reduce((acc, curr) => acc + (curr.volume ?? 0), 0)

    let hasFeatureUpdateSapVolume = hasFeature('DealsSapQuantityVolumeUpdate')
    let columns: ColumnDescriptor<DealListItem>[] = [
        { name: t('deals.label.company'), value: x => x.company, hidden: hasOneCompany },
        { name: t('deals.label.dealNumberAbr'), value: x => x.dealNumber },
        {
            name: t('deals.label.referenceNumberAbr'),
            value: x => x.referenceNumber,
            columnFilter:
            {
                value: dealListFilters.referenceNumber ?? '',
                onChange: (referenceNumber: string) => setDealListFilters({ ...dealListFilters, referenceNumber })
            },
            wrapTextMax: true
        },
        {
            name: t('deals.label.mvtType'), htmlFor: x =>
                <span className={!!x.movementType && classes.iconsActive}>
                    <MovementTypeIcon movementType={GetMovementType(x.movementType ?? '')} />
                </span>
        },
        { name: t('deals.label.dealType'), value: x => x.type },
        {
            name: t('deals.label.termSpot'), value: _ => '', alignRight: true,
            htmlFor: x => (
                x.termSpot == 'Term'
                    ? <CheckBox className={classes.iconsActive} />
                    : <CheckBoxOutlineBlank />
            )
        },
        { name: t('deals.label.product'), value: x => x.productCode },
        { name: t('deals.label.site'), value: x => x.site, wrapTextMax: true },
        { name: t('deals.label.dutyStatusAbr'), value: x => x.dutyStatus },
        {
            name: t('deals.label.mot'), htmlFor: x =>
                <span className={!!x.meanOfTransportation && classes.iconsActive}>
                    <MovementMotIcon meanOfTransportation={x.meanOfTransportation ?? ''} />
                </span>
        },
        { name: t('deals.label.counterparty'), value: x => x.counterparty?.name ?? '', wrapTextMax: true },
        {
            name: t('deals.label.validityPeriodAbr'),
            value: x => formatFromToDate(x.validFrom) + ' -> ' + formatFromToDate(x.validTo)
        },
        {
            name: t('deals.label.sapText'), defaultGroup: 'sapDetails',
            htmlFor: x =>
            (!dealStore.isPaperTransaction()
                ? <div>
                    <Chip
                        className={x.contractReference ? classes.sapChipWithValue : classes.sapChip}
                        classes={{ label: classes.noPadding }}
                        label={x.contractReference ?? 'PC'}
                        title={hasFeatureUpdateSapVolume ? t('deals.label.updateSapQuantity') : ''}
                        disabled={!x.contractReference && hasFeatureUpdateSapVolume}
                        clickable={hasFeatureUpdateSapVolume}
                        onClick={async (event) => {
                            if (!hasFeatureUpdateSapVolume) return
                            event.stopPropagation();
                            await api.post('sapApi/updateDealSapQuantity ', { dealId: x.id })
                        }}
                    />
                </div>
                : <></>
            )
        },
        { name: t('deals.label.exchangeNumberAbr'), groupedBy: 'sapDetails', value: x => x.exchangeNumber },
        { name: t('deals.label.sapPlant'), groupedBy: 'sapDetails', value: x => x.sapPlant },
        { name: t('deals.label.das'), groupedBy: 'sapDetails', value: x => x.das },
        { name: t('deals.label.sapHandlingType'), groupedBy: 'sapDetails', value: x => x.sapHandlingType },
        { name: t('deals.label.sapValuationType'), groupedBy: 'sapDetails', value: x => x.sapValuationType },
        { name: t('deals.label.sapVendor'), groupedBy: 'sapDetails', value: x => x.sapVendor },
        { name: t('deals.label.shipTo'), groupedBy: 'sapDetails', value: x => x.shipTo },
        { name: t('deals.label.pricingType'), defaultGroup: 'priceDetails', value: x => x.pricingType },
        { name: t('deals.label.currency'), groupedBy: 'priceDetails', value: x => x.currency },
        { name: t('deals.label.quantityPriceUnitAbr'), groupedBy: 'priceDetails', value: x => x.quantityPriceUnit },
        {
            name: t('deals.label.volumeAbr'), defaultGroup: 'quantityDetails',
            value: x => roundNumber(x.volume), total: roundNumber(totalVolume),
            alignRight: true
        },
        {
            name: t('deals.label.quantityAbr'), groupedBy: 'quantityDetails',
            value: x => roundNumber(x.quantity), total: roundNumber(totalQuantity),
            alignRight: true
        },
        {
            name: t('deals.label.movementQuantity'), groupedBy: 'quantityDetails',
            value: x => roundNumber(x.movementQuantity), alignRight: true
        },
        {
            name: t('deals.label.sapQuantityAbr'), groupedBy: 'quantityDetails',
            value: x => roundNumber(x.sapQuantity), alignRight: true
        },
        { name: t('deals.label.unit'), groupedBy: 'quantityDetails', value: x => x.unit },
        { name: t('deals.label.underTolerancePercentage'), groupedBy: 'quantityDetails', value: x => x.underTolerance },
        { name: t('deals.label.UnderToleranceQuantity'), groupedBy: 'quantityDetails', value: x => x.underToleranceQuantity },
        { name: t('deals.label.overTolerancePercentage'), groupedBy: 'quantityDetails', value: x => x.overTolerance },
        { name: t('deals.label.overToleranceQuantity'), groupedBy: 'quantityDetails', value: x => x.overToleranceQuantity },
        { name: t('deals.label.toleranceOption'), groupedBy: 'quantityDetails', value: x => x.toleranceOption },
        {
            name: t('deals.label.dealConsumption'), groupedBy: 'quantityDetails',
            value: x => x.dealConsumption != null ? (x.dealConsumption * 100).toFixed(2) : null
        },
        { name: t('deals.label.nextValidator'), defaultGroup: 'organizationnalDetails', value: x => x.nextValidator },
        { name: t('deals.label.status'), groupedBy: 'organizationnalDetails', value: x => x.status },
        {
            name: t('deals.label.signed'), groupedBy: 'organizationnalDetails',
            value: _ => '', alignRight: true,
            htmlFor: x => (
                x.signed
                    ? <CheckBox />
                    : <CheckBoxOutlineBlank />
            )
        },
        { name: t('deals.label.assigneeUserName'), groupedBy: 'organizationnalDetails', value: x => x.assigneeUserName },
        { name: t('deals.label.frontOfficerAbr'), groupedBy: 'organizationnalDetails', value: x => x.frontOfficer }
    ]

    let dealsTableActions: ActionDescriptor<DealListItem>[] = [
        { name: 'Delete', action: onDeleteDeals, icon: <Icons.DeleteOutlined />, isHidden: !hasClaim(Claims.DealManager) },
        {
            name: 'History', action: (x: DealListItem) =>
                historyDialog.open({
                    id: x.id, type: 'deal', name: `
                            ${x.referenceNumber ?? ''}
                            ${x.company ?? ''}
                            ${x.dutyStatus ?? ''}
                            ${x.productCode ?? ''}
                            ${x.volume ? x.volume + ' M20' : ''}`
                }),
            isBodyAction: true, icon: <Icons.History />
        },
        {
            name: 'Duplicate', action: (x: DealListItem) => { setCurrentDeal(x); setDuplicateConfirmDialogOpen(true) },
            icon:
                <Tooltip title={<Typography variant='body1'>{t('deals.label.duplicateDeal')}</Typography>} placement='top'>
                    <Icons.FileCopyOutlined />
                </Tooltip>,
            isBodyAction: true,
            disabled: (x: DealListItem) => !hasFeature('CreateDeal') || !hasClaim(Claims.DealManager) || x.type === 'INTERNAL'
        }
    ]

    let dealsTableGroups: GroupDescriptor[] = [
        { name: 'details', title: '', colSpan: hasOneCompany ? 12 : 13 },
        { name: 'sapDetails', title: t('deals.label.sapDetails'), withHiddenColumns: true },
        { name: 'priceDetails', title: t('deals.label.priceDetails'), withHiddenColumns: true },
        { name: 'quantityDetails', title: t('deals.label.quantityDetails'), withHiddenColumns: true },
        { name: 'organizationnalDetails', title: t('deals.label.organizationnalDetails'), withHiddenColumns: true },
        { name: 'options', title: '' }
    ]

    let canCreate = hasFeature('CreateDeal') && hasClaim(Claims.DealManager)

    return (
        <div className={classes.pageContainer}>
            <div className={classes.firstRow}>
                <Typography className={classes.pageTitle} variant='h5' display='block' gutterBottom>{t('deals.name')}</Typography>
                <div>
                    <Paper className={classes.paperFilter}>
                        <MultipleSelect label={t('deals.label.type')} classesOverride={{ form: classes.filterField }}
                            values={filters.dealTypes} disableNewStyle
                            choices={searchFields.dealTypes}
                            onChange={x => dealStore.setFilters({ ...filters, dealTypes: x })} />
                        <MultipleSelect label={t('deals.label.movementType')} classesOverride={{ form: classes.filterField }}
                            values={filters.movementTypes} disableNewStyle
                            choices={Object.keys(DealMovementType).map(x => { return { value: DealMovementType[x], text: dealStore.getTranslatedMovementType(DealMovementType[x]) } })}
                            onChange={x => dealStore.setFilters({ ...filters, movementTypes: x })} />
                        {dealStore.updatedDealFilters() && <MultipleSelect label={t('deals.label.termSpot')} classesOverride={{ form: classes.filterField }}
                            values={filters.termSpot} disableNewStyle
                            choices={termSpot}
                            onChange={x => dealStore.setFilters({ ...filters, termSpot: x })} />}
                        {!hasOneCompany && <MultipleSelect label={t('deals.label.company')} classesOverride={{ form: classes.filterField }}
                            values={filters.companys} disableNewStyle
                            choices={searchFields.companies.map(x => ({ value: x.code, text: x.name }))}
                            onChange={x => dealStore.setFilters({ ...filters, companys: x })} />}
                        <MultipleSelect label={t('deals.label.product')} classesOverride={{ form: classes.filterField }}
                            values={filters.productIds} disableNewStyle
                            choices={searchFields.products.map(x => ({ value: x.id, text: x.code }))}
                            onChange={x => dealStore.setFilters({ ...filters, productIds: x })} />
                        {dealStore.updatedDealFilters() && <GroupedMultiSelect
                            label={t('deals.label.site')}
                            classesOverride={{ form: classes.filterField }}
                            values={filters.sites} disableNewStyle
                            choices={searchFields.sites.map(x => { return { value: x.code, text: x.name, group: x.siteGroup } })}
                            allWhenEmpty={false}
                            onChange={x => dealStore.setFilters({ ...filters, sites: x })} />}
                        <MultipleSelect label={t('deals.label.dutyStatus')} hideOnSingleChoice
                            values={filters.dutyStatuss} classesOverride={{ form: classes.filterField }}
                            choices={dutyStatuses} disableNewStyle
                            onChange={x => dealStore.setFilters({ ...filters, dutyStatuss: x })} />
                        {dealStore.updatedDealFilters() && <MultipleSelect label={t('deals.label.mot')} hideOnSingleChoice
                            values={filters.moTs} classesOverride={{ form: classes.filterField }}
                            choices={searchFields.moTs} disableNewStyle
                            onChange={x => dealStore.setFilters({ ...filters, moTs: x })} />}
                        <MultipleSelect label={t('deals.label.counterparty')} classesOverride={{ form: classes.filterField }}
                            values={filters.counterpartyIds} disableNewStyle
                            choices={searchFields.counterparties.map(x => ({ value: x.id, text: x.name }))}
                            onChange={x => dealStore.setFilters({ ...filters, counterpartyIds: x })} />
                        {dealStore.updatedDealFilters() &&
                            <>
                                <MultipleSelect label={t('deals.label.status')} classesOverride={{ form: classes.filterField }}
                                    values={filters.statuss} disableNewStyle
                                    choices={searchFields.statuss}
                                    onChange={x => dealStore.setFilters({ ...filters, statuss: x })} />
                                <MultipleSelect label={t('deals.label.signed')} classesOverride={{ form: classes.filterField }}
                                    values={filters.signedNotSigned} disableNewStyle
                                    choices={signedNotSigned}
                                    onChange={x => dealStore.setFilters({ ...filters, signedNotSigned: x })} />
                                <MultipleSelect label={t('deals.label.assigneeUserName')} classesOverride={{ form: classes.filterField }}
                                    values={filters.dealAssignees} disableNewStyle
                                    choices={searchFields.dealAssignees.map(x => ({ value: x.userName, text: x.fullName }))}
                                    onChange={x => dealStore.setFilters({ ...filters, dealAssignees: x })} />
                                <MultipleSelect label={t('deals.label.frontOfficer')} classesOverride={{ form: classes.filterField }}
                                    values={filters.frontOfficers} disableNewStyle
                                    choices={searchFields.dealAssignees.map(x => ({ value: x.userName, text: x.fullName }))}
                                    onChange={x => dealStore.setFilters({ ...filters, frontOfficers: x })} />
                            </>}
                    </Paper>
                </div>
            </div>
            <Paper className={classes.root + ' ' + classes.secondRow}>
                <div className={classes.tableHead}>
                    <div className={classes.titleSwitchRow}>
                        <Switch form
                            isChecked={filters.currentUserDealsOnly}
                            changeCallback={() => dealStore.setFilters({ ...filters, currentUserDealsOnly: !filters.currentUserDealsOnly })}
                            offText={t('deals.label.allDeals')}
                            onText={t('deals.label.myPendingActions')} />

                    </div>
                    <div className={classes.datePickerContainer}>
                        <Switch
                            isChecked={filters.isDayPeriodMode}
                            changeCallback={() => dealStore.setFilters({ ...filters, isDayPeriodMode: !filters.isDayPeriodMode })}
                            offText={t('deals.label.monthFilter')}
                            onText={t('deals.label.dayFilter')}
                        />
                        <Grid>
                            {filters.isDayPeriodMode
                                ? <DatePicker
                                    date={filters.validOn || null}
                                    label={t('deals.table.validOn')}
                                    disableNewStyle
                                    setDate={newDate => changeDate(newDate, 'validOn')}
                                    classesOverride={{ datepicker: classes.filterFieldDate }}
                                />
                                : <MonthRangePicker
                                    label={t('deals.table.validIn')}
                                    startDate={filters.validInStart || null}
                                    endDate={filters.validInEnd || null}
                                    onStartDateChange={(newDate) => changeDate(newDate, 'validInStart')}
                                    onEndDateChange={(newDate) => changeDate(newDate, 'validInEnd')}
                                    disableNewStyle
                                    classesOverride={{ datepicker: classes.filterFieldDate }}
                                    status="info"
                                />
                            }
                        </Grid>
                    </div>
                    <div className={classes.buttonsRow}>
                        {hasFeature('ExportDealList') && (hasClaim(Claims.DealManager) || hasClaim(Claims.DealReader))
                            ? <Button className={classes.headerButton}
                                label={t('admin.masterdata.export')}
                                img={'/static/images/excel_red.svg'}
                                onClick={prepareDealExport} />
                            : null}
                        <SearchField label={t('deals.label.search')} onChange={onQueryChanged} onClick={onValidatedQuery} text={searchQuery} />
                        {
                            canCreate
                            && <Button className={classes.createButton}
                                label={t('deals.table.createNewFormula')}
                                img={<Icons.Add />}
                                onClick={() => openPopup(guid.createNew() as Guid, 'Formula')} />}
                        {
                            canCreate && hasFeature('PricingTrigger')
                            && <Button
                                label={t('deals.table.createNewTrigger')}
                                img={<Icons.Add />}
                                onClick={() => openPopup(guid.createNew() as Guid, 'Trigger')} />
                        }
                        {
                            canCreate && hasFeature('PricingFixedPrice')
                            && <Button
                                label={t('deals.table.createNewFixed')}
                                img={<Icons.Add />}
                                onClick={() => openPopup(guid.createNew() as Guid, 'Fixed')} />
                        }
                    </div>
                </div>
                <DataTable
                    tableId={'deal-table'}
                    items={getItems()}
                    idSelector={(x: DealListItem) => x.id}
                    onSelectionChange={(x: DealListItem[]) => setSelectedDeals(x)}
                    onClick={onSelect}
                    columns={columns}
                    actions={dealsTableActions}
                    groups={dealsTableGroups} />
            </Paper>
            <CustomDialog isOpen={deleteDealConfirmIsOpen} yesNo
                title={t('deals.table.confirmDelete.title')}
                contentText={t('deals.table.confirmDelete.content')}
                content2Text={t('deals.table.confirmDelete.content2')}
                yesButtonText={t('deals.table.confirmDelete.yes')}
                noButtonText={t('deals.table.confirmDelete.no')}
                onYes={() => deleteDeals(true)}
                onNo={() => deleteDeals(false)}
                onCancel={() => setDeleteDealConfirmIsOpen(false)}
                onClose={() => setDeleteDealConfirmIsOpen(false)}
            />
            <CustomDialog isOpen={deleteDealSimpleConfirmIsOpen}
                title={t('deals.table.simpleConfirmDelete.title')}
                contentText={t('deals.table.simpleConfirmDelete.content')}
                confirmButtonText={t('deals.table.simpleConfirmDelete.confirm')}
                onConfirm={() => deleteDeals(true)}
                onCancel={() => setDeleteDealSimpleConfirmIsOpen(false)}
                onClose={() => setDeleteDealSimpleConfirmIsOpen(false)}
            />
            <CustomDialog isOpen={duplicateConfirmDialogOpen} title={t('deals.label.confirmTitle')}
                contentText={t('deals.label.confirmDuplicate')}
                confirmButtonText={t('deals.label.duplicate')}
                onConfirm={async () => {
                    setDuplicateConfirmDialogOpen(false)
                    if (currentDeal) await handleDuplicateDebouncer.execute(currentDeal)
                }}
                onClose={() => setDuplicateConfirmDialogOpen(false)}
                onCancel={() => setDuplicateConfirmDialogOpen(false)} />
        </div >
    )
}

const sapChipStyle = {
    minWidth: '2.5em',
    height: '2.5em',
    color: defaultColors.grey.main.color,
    marginLeft: '0.2em',
    marginRight: '0.2em',
    padding: '0.2em',
    borderRadius: '7px',
    '& span': {
        textOverflow: 'clip !important',
        padding: '0 !important'
    }
}

let styles = _ =>
    createStyles({
        firstRow: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            alignItems: 'stretch',
            width: '100%',
            gridArea: 'firstRow',
            whiteSpace: 'nowrap'
        },
        secondRow: {
            gridArea: 'secondRow'
        },
        titleSwitchRow: {
            ...defaultStyles.flexRow,
        },
        pageTitle: {
            color: defaultColors.grey.dark.color,
            alignSelf: 'flex-start',
            marginRight: '2em'
        },
        tableHead: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            alignItems: 'stretch',
            padding: '0.2em 1%',
            width: '100%'
        },
        paperTitle: {
            margin: '0',
            marginRight: '6em',
            color: defaultColors.red.main.color
        },
        filterField: {
            width: '8.3em',
            margin: '0em 0.5em'
        },
        filterFieldDate: {
            width: '10.3em',
            margin: '0em 0.5em'
        },
        paperFilter: {
            height: 'auto',
            padding: '0.5em',
        },
        root: {
            flexGrow: 2,
            width: '100%',
            overflow: 'auto'
        },
        headerButton: {
            ...defaultStyles.secondaryButton,
            marginRight: '2em',
        },
        pageContainer: {
            display: 'grid',
            height: '100%',
            paddingBottom: '0.5em',
            'grid-template-areas': "'firstRow' 'secondRow'",
            'grid-template-columns': 'auto',
            'grid-template-rows': '4em minmax(0, 1fr)',
            'grid-gap': '0.8em',
            'overflow-y': 'hidden',
            'box-sizing': 'border-box',
            '&> div': {
                height: '100%',
                width: '100%'
            }
        },
        buttonsRow: {
            display: 'flex',
            alignItems: 'center',
            '& > *:not(:last-child)': {
                marginRight: '1em'
            }
        },
        sapButton: {
            '& button': {
                ...defaultStyles.secondaryButton,
                minWidth: '8em',
                height: '8em',
                width: '8em',
                padding: '0px',
            }
        },
        sapChip: {
            ...sapChipStyle
        },
        sapChipWithValue: {
            ...sapChipStyle,
            backgroundColor: '#95e379',
            color: defaultColors.green.main.color,
        },
        createButton: {
            ...defaultStyles.primaryButton
        },
        iconsActive: {
            color: defaultColors.lightBlue.main.color
        },
        datePickerContainer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
    })

export default withStyles(styles, muiOptions)(DealList)